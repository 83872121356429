/* src/Login.css */


/* Center the login form */
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-size: cover;
    background-image: url("https://images.unsplash.com/photo-1497633762265-9d179a990aa6?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2073&q=80");
  }
  
  /* Style the form elements */
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  }
  
  label {
    font-weight: bold;
  }
  
  input {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
    max-width: 300px;
  }
  
  button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  button {
    display: inline-block;
  }

  .register-button {
    margin-top: 10px;
    background-color: #28a745; /* Green color */
    display: inline-block;
    padding: 1rem 1rem;
    vertical-align: middle;
  }

  .register-button:hover {
    background-color: #1e7e34; /* Darker green color on hover */
  }

  .login-button {
    display: inline-block;
    padding: 1rem 1rem;
    vertical-align: middle;
  }

  h1 {
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 10%;
    font-size: larger;
    color: whitesmoke;

  }
  