.nav-items-3-1 {
  align-items: center;
  cursor: pointer;
  display: flex;
  gap: 60px;
  height: 48px;
  justify-content: center;
  margin-bottom: 6.0px;
  position: relative;
  transition: all 0.2s ease-in;
  width: 851px;
}

.nav-items-3-1:hover {
  transform: translate(0, -5px);
}

.nav-items-3-1.nav-items-3-2 {
  border-radius: 4px;
  margin-bottom: unset;
  margin-right: 3px;
}

.nav-items-3-1.nav-items-3-3,
.nav-items-3-1.nav-items-3-10,
.nav-items-3-1.nav-items-3-13,
.nav-items-3-1.nav-items-3-14,
.nav-items-3-1.nav-items-3-17,
.nav-items-3-1.nav-items-3-19,
.nav-items-3-1.nav-items-3-20,
.nav-items-3-1.nav-items-3-21,
.nav-items-3-1.nav-items-3-22,
.nav-items-3-1.nav-items-3-23,
.nav-items-3-1.nav-items-3-24,
.nav-items-3-1.nav-items-3-28,
.nav-items-3-1.nav-items-3-33,
.nav-items-3-1.nav-items-3-38,
.nav-items-3-1.nav-items-3-39,
.nav-items-3-1.nav-items-3-40,
.nav-items-3-1.nav-items-3-46,
.nav-items-3-1.nav-items-3-48,
.nav-items-3-1.nav-items-3-51,
.nav-items-3-1.nav-items-3-52,
.nav-items-3-1.nav-items-3-53,
.nav-items-3-1.nav-items-3-54,
.nav-items-3-1.nav-items-3-55,
.nav-items-3-1.nav-items-3-56,
.nav-items-3-1.nav-items-3-57,
.nav-items-3-1.nav-items-3-58,
.nav-items-3-1.nav-items-3-59 {
  margin-bottom: unset;
  margin-right: 4px;
}

.nav-items-3-1.nav-items-3-4 {
  left: 538px;
  margin-bottom: unset;
  position: absolute;
  top: 29px;
}

.nav-items-3-1.nav-items-3-5,
.nav-items-3-1.nav-items-3-61 {
  align-self: flex-end;
  margin-bottom: unset;
}

.nav-items-3-1.nav-items-3-6,
.nav-items-3-1.nav-items-3-7,
.nav-items-3-1.nav-items-3-8,
.nav-items-3-1.nav-items-3-9,
.nav-items-3-1.nav-items-3-25,
.nav-items-3-1.nav-items-3-27,
.nav-items-3-1.nav-items-3-29,
.nav-items-3-1.nav-items-3-30,
.nav-items-3-1.nav-items-3-31,
.nav-items-3-1.nav-items-3-35,
.nav-items-3-1.nav-items-3-41,
.nav-items-3-1.nav-items-3-43,
.nav-items-3-1.nav-items-3-44,
.nav-items-3-1.nav-items-3-45,
.nav-items-3-1.nav-items-3-47 {
  left: 616px;
  margin-bottom: unset;
  position: absolute;
  top: 30px;
}

.nav-items-3-1.nav-items-3-11 {
  border-radius: 6px;
  margin-bottom: unset;
}

.nav-items-3-1.nav-items-3-12,
.nav-items-3-1.nav-items-3-16,
.nav-items-3-1.nav-items-3-60 {
  left: 616px;
  margin-bottom: unset;
  position: absolute;
  top: 29px;
}

.nav-items-3-1.nav-items-3-15 {
  left: 617px;
  margin-bottom: unset;
  position: absolute;
  top: 29px;
}

.nav-items-3-1.nav-items-3-18 {
  margin-bottom: unset;
  margin-right: 4px;
  margin-top: 8px;
}

.nav-items-3-1.nav-items-3-26,
.nav-items-3-1.nav-items-3-37,
.nav-items-3-1.nav-items-3-42,
.nav-items-3-1.nav-items-3-50 {
  margin-bottom: unset;
  margin-left: 5.0px;
}

.nav-items-3-1.nav-items-3-32 {
  left: 611px;
  margin-bottom: unset;
  position: absolute;
  top: 27px;
}

.nav-items-3-1.nav-items-3-34 {
  left: 612px;
  margin-bottom: unset;
  position: absolute;
  top: 28px;
}

.nav-items-3-1.nav-items-3-36,
.nav-items-3-1.nav-items-3-49 {
  left: 612px;
  margin-bottom: unset;
  position: absolute;
  top: 30px;
}

