.social-2-1 {
  align-items: flex-end;
  background-color: var(--white);
  border: 1px none;
  display: flex;
  flex-direction: column;
  height: 900px;
  width: 1530px;
}

.overlap-group-36 {
  align-items: flex-start;
  background-color: var(--rum-swizzle);
  border-radius: 41px 0px 100px 118px;
  display: flex;
  gap: 329px;
  height: 180px;
  justify-content: flex-end;
  min-width: 1530px;
  padding: 20px 59px;
}

.x1200px-logo_icam_-_2008-1-35 {
  cursor: pointer;
  height: 74px;
  object-fit: cover;
  width: 144px;
}

.nav-items-container-14 {
  align-items: flex-end;
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-bottom: 2px;
  min-height: 128px;
  position: relative;
  width: 926px;
}

.emploi-relation-employeur-employ-3-2 {
  align-items: center;
  align-self: center;
  display: flex;
  flex-direction: column;
  gap: 17px;
  margin-right: 10.0px;
  margin-top: 155px;
  position: relative;
  width: fit-content;
}

.do-you-comply-with-t {
  background-color: transparent;
  border: 0;
  color: var(--white-lilac);
  font-weight: 400;
  height: 42px;
  line-height: normal;
  min-width: 684px;
  opacity: 0.5;
  padding: 0;
  position: relative;
  text-align: center;
}

.do-you-comply-with-t::placeholder {
  color: #f7f9fb99;
}

.have-you-conducted-e {
  background-color: transparent;
  border: 0;
  color: var(--graye);
  font-weight: 400;
  height: 21px;
  line-height: normal;
  min-width: 488px;
  opacity: 0.5;
  padding: 0;
  position: relative;
  text-align: center;
}

.have-you-conducted-e::placeholder,
.does-the-work-perfor::placeholder {
  color: #ffffff99;
}

.does-the-work-perfor {
  background-color: transparent;
  border: 0;
  color: var(--graye);
  font-weight: 400;
  height: 42px;
  line-height: normal;
  min-width: 605px;
  opacity: 0.5;
  padding: 0;
  position: relative;
  text-align: center;
}

.flex-row-83 {
  align-items: flex-start;
  display: flex;
  gap: 554px;
  height: 72px;
  margin-right: 99px;
  margin-top: 124px;
  min-width: 759px;
  position: relative;
}

