.kanban-2-1 {
  align-items: center;
  background-color: var(--white);
  border: 1px none;
  display: flex;
  flex-direction: column;
  gap: 1px;
  height: 900px;
  width: 1530px;
}

.overlap-group-71 {
  height: 248px;
  position: relative;
  width: 1530px;
}

.rectangle-3-27 {
  background-color: var(--rum-swizzle);
  border-radius: 41px 0px 100px 118px;
  height: 180px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1530px;
}

.x1200px-logo_icam_-_2008-1-70 {
  cursor: pointer;
  height: 74px;
  left: 72px;
  object-fit: cover;
  position: absolute;
  top: 20px;
  width: 144px;
}

.please-answer-the-following-questions-26 {
  height: 81px;
  left: 458px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 167px;
  width: 577px;
}

.overlap-group1-47 {
  height: 632px;
  margin-left: 5.0px;
  position: relative;
  width: 1407px;
}

