.frame-92-2 {
  align-items: flex-start;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 28px;
  height: 609px;
  justify-content: center;
  left: 56px;
  overflow: hidden;
  position: absolute;
  top: 228px;
  width: 651px;
}

.vos-employs-sont-ils-6 {
  background-color: transparent;
  border: 0;
  color: var(--inter);
  font-weight: 400;
  height: 22px;
  line-height: normal;
  min-width: 568px;
  opacity: 0.5;
  padding: 0;
  position: relative;
}

.vos-employs-sont-ils-6::placeholder,
.avez-vous-un-managem-6::placeholder,
.sez-vous-6::placeholder,
.est-ce-que-les-journ-6::placeholder {
  color: #9f9f9f99;
}

.avez-vous-un-managem-6 {
  background-color: transparent;
  border: 0;
  color: var(--inter);
  font-weight: 400;
  height: 21px;
  line-height: normal;
  min-width: 563px;
  opacity: 0.5;
  padding: 0;
  position: relative;
}

.sez-vous-6 {
  background-color: transparent;
  border: 0;
  color: var(--inter);
  font-weight: 400;
  height: 22px;
  line-height: normal;
  min-width: 563px;
  opacity: 0.5;
  padding: 0;
  position: relative;
}

.est-ce-que-les-journ-6 {
  background-color: transparent;
  border: 0;
  color: var(--inter);
  font-weight: 400;
  height: 21px;
  line-height: normal;
  min-width: 568px;
  opacity: 0.5;
  padding: 0;
  position: relative;
}

.frame-92-2.frame-85-1 {
  left: 39px;
  top: 238px;
}

