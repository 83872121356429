.frame-6-10 {
  align-items: center;
  background-color: var(--rum-swizzle);
  border-radius: 6px;
  box-shadow: 0px 4px 11px #1e454945;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 47px;
  justify-content: center;
  left: 0;
  padding: 15px;
  position: absolute;
  top: 0;
  width: 205px;
}

.machine-arretee {
  letter-spacing: 0;
  line-height: 25.9px;
  margin-bottom: -3.50px;
  margin-top: -5.50px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame-6-10.frame-6-11 {
  align-self: flex-start;
  left: unset;
  margin-left: 6px;
  position: relative;
  top: unset;
}

