.quality-4 {
  align-items: center;
  background-color: var(--white);
  border: 1px none;
  display: flex;
  flex-direction: column;
  height: 900px;
  width: 1530px;
}

.overlap-group-62 {
  align-items: flex-start;
  background-color: var(--rum-swizzle);
  border-radius: 41px 0px 100px 118px;
  display: flex;
  gap: 329px;
  height: 167px;
  justify-content: flex-end;
  min-width: 1530px;
  padding: 10px 59px;
}

.x1200px-logo_icam_-_2008-1-61 {
  cursor: pointer;
  height: 74px;
  margin-top: 10px;
  object-fit: cover;
  width: 144px;
}

.nav-items-container-31 {
  align-items: flex-end;
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  gap: 32px;
  min-height: 128px;
  position: relative;
  width: 926px;
}

.emploirelation-employeur-employ-11 {
  align-items: center;
  display: flex;
  gap: 88px;
  justify-content: center;
  margin-left: 60.0px;
  margin-top: 115px;
  position: relative;
  width: fit-content;
}

.emploi-relation-employeur-employ-1-8 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 14px;
  position: relative;
  width: fit-content;
}

.do-you-have-a-quality-manager {
  background-color: transparent;
  border: 0;
  color: var(--white-lilac);
  font-weight: 400;
  height: 35px;
  line-height: normal;
  min-width: 420px;
  opacity: 0.5;
  padding: 0;
  position: relative;
  text-align: center;
}

.do-you-have-a-quality-manager::placeholder {
  color: #f7f9fb99;
}

.are-the-necessary-co {
  background-color: transparent;
  border: 0;
  color: var(--graye);
  font-weight: 400;
  height: 69px;
  line-height: normal;
  min-width: 419px;
  opacity: 0.5;
  padding: 0;
  position: relative;
  text-align: center;
}

.are-the-necessary-co::placeholder,
.do-you-have-job-desc::placeholder,
.is-this-documentatio::placeholder,
.has-an-indicator-bee::placeholder,
.is-the-indicator-vis::placeholder {
  color: #ffffff99;
}

.do-you-have-job-desc {
  background-color: transparent;
  border: 0;
  color: var(--graye);
  font-weight: 400;
  height: 46px;
  line-height: normal;
  min-width: 402px;
  opacity: 0.5;
  padding: 0;
  position: relative;
  text-align: center;
}

.is-this-documentatio {
  background-color: transparent;
  border: 0;
  color: var(--graye);
  font-weight: 400;
  height: 42px;
  line-height: normal;
  min-width: 402px;
  opacity: 0.5;
  padding: 0;
  position: relative;
  text-align: center;
}

.emploi-relation-employeur-employ-2-9 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 19px;
  position: relative;
  width: fit-content;
}

.has-an-indicator-bee {
  background-color: transparent;
  border: 0;
  color: var(--graye);
  font-weight: 400;
  height: 51px;
  line-height: normal;
  min-width: 402px;
  opacity: 0.5;
  padding: 0;
  position: relative;
  text-align: center;
}

.is-the-indicator-vis {
  background-color: transparent;
  border: 0;
  color: var(--graye);
  font-weight: 400;
  height: 26px;
  line-height: normal;
  min-width: 404px;
  opacity: 0.5;
  padding: 0;
  position: relative;
  text-align: center;
}

.flex-row-120 {
  align-items: flex-start;
  display: flex;
  gap: 435px;
  height: 60px;
  margin-left: 432.0px;
  margin-top: 48px;
  min-width: 642px;
  position: relative;
}

