.menu-item-default-13 {
  align-items: center;
  display: flex;
  gap: 12.95px;
  height: 73px;
  justify-content: center;
  position: relative;
  width: 120px;
}

.label-95 {
  background-color: transparent;
  border: 0;
  height: 78px;
  letter-spacing: 0;
  line-height: 20px;
  margin-bottom: -1.21px;
  margin-top: -3.79px;
  min-width: 82px;
  padding: 0;
  position: relative;
}

.label-95::placeholder {
  color: #0c411199;
}

.menu-item-default-13.menu-item-default-14 {
  height: 26px;
  justify-content: unset;
  width: fit-content;
}

.menu-item-default-13.menu-item-default-14 .label-95 {
  height: 26px;
  margin-bottom: unset;
  margin-top: -1.29px;
  min-width: 112px;
}

.menu-item-default-13.menu-item-default-15 {
  height: 26px;
  justify-content: unset;
  width: fit-content;
}

.menu-item-default-13.menu-item-default-15 .label-95 {
  height: 26px;
  margin-bottom: unset;
  margin-top: -1.29px;
  min-width: 75px;
}

.menu-item-default-13.menu-item-default-16 {
  height: unset;
  left: 51px;
  position: absolute;
  top: 46px;
  width: fit-content;
}

.menu-item-default-13.menu-item-default-16 .label-95 {
  height: 26px;
  margin-bottom: unset;
  margin-top: -1.29px;
  min-width: 91px;
}

.menu-item-default-13.menu-item-default-17 {
  height: unset;
  justify-content: unset;
  left: 320px;
  position: absolute;
  top: 49px;
  width: fit-content;
}

.menu-item-default-13.menu-item-default-17 .label-95 {
  height: 26px;
  margin-bottom: unset;
  margin-top: -1.29px;
  min-width: 99px;
}

