.social-5 {
  align-items: flex-start;
  background-color: var(--white);
  border: 1px none;
  display: flex;
  flex-direction: column;
  height: 900px;
  width: 1530px;
}

.overlap-group-83 {
  align-items: flex-start;
  background-color: var(--rum-swizzle);
  border-radius: 41px 0px 100px 118px;
  display: flex;
  gap: 329px;
  height: 180px;
  justify-content: flex-end;
  min-width: 1530px;
  padding: 20px 59px;
}

.x1200px-logo_icam_-_2008-1-27 {
  cursor: pointer;
  height: 82px;
  object-fit: cover;
  width: 152px;
}

.nav-items-container-49 {
  align-items: flex-end;
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-bottom: 2px;
  min-height: 128px;
  position: relative;
  width: 926px;
}

.sant-et-scurit-au-travail {
  align-items: flex-start;
  display: flex;
  gap: 48px;
  margin-left: 6px;
  margin-top: 107px;
  padding: 23px 0px;
  position: relative;
  width: fit-content;
}

.sant-et-scurit-au-travail-1 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  width: fit-content;
}

.group-107-4 {
  height: 401.83px;
  margin-right: -8px;
  min-width: 452px;
  position: relative;
}

.flex-col-104 {
  align-items: center;
  display: flex;
  flex-direction: column;
  min-height: 402px;
  position: relative;
  width: 444px;
}

.do-you-promote-the-e {
  background-color: transparent;
  border: 0;
  color: var(--white-lilac);
  font-weight: 400;
  height: 35px;
  line-height: normal;
  margin-top: 23px;
  opacity: 0.5;
  padding: 0;
  text-align: center;
  width: 444px;
}

.do-you-promote-the-e::placeholder,
.do-you-listen-to-emp::placeholder,
.do-you-ensure-that-e::placeholder {
  color: #f7f9fb99;
}

.do-you-carry-out-a-b {
  background-color: transparent;
  border: 0;
  color: var(--graye);
  font-weight: 400;
  height: 22px;
  line-height: normal;
  margin-top: 14px;
  opacity: 0.5;
  padding: 0;
  text-align: center;
  width: 406px;
}

.do-you-carry-out-a-b::placeholder,
.do-you-make-an-inven::placeholder,
.do-you-provide-training-for-employees::placeholder,
.do-you-offer-employe::placeholder,
.do-you-offer-employe-1::placeholder {
  color: #ffffff99;
}

.do-you-make-an-inven {
  background-color: transparent;
  border: 0;
  color: var(--graye);
  font-weight: 400;
  height: 22px;
  line-height: normal;
  margin-right: 1px;
  margin-top: 25px;
  opacity: 0.5;
  padding: 0;
  text-align: center;
  width: 425px;
}

.do-you-provide-training-for-employees {
  background-color: transparent;
  border: 0;
  color: var(--graye);
  font-weight: 400;
  height: 22px;
  line-height: normal;
  margin-right: 1px;
  margin-top: 17px;
  opacity: 0.5;
  padding: 0;
  text-align: center;
  width: 425px;
}

.sant-et-scurit-au-travail-2 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 14px;
  position: relative;
  width: fit-content;
}

.do-you-listen-to-emp {
  background-color: transparent;
  border: 0;
  color: var(--white-lilac);
  font-weight: 400;
  height: 62px;
  line-height: normal;
  min-width: 460px;
  opacity: 0.5;
  padding: 0;
  position: relative;
  text-align: center;
}

.do-you-offer-employe {
  background-color: transparent;
  border: 0;
  color: var(--graye);
  font-weight: 400;
  height: 41px;
  line-height: normal;
  min-width: 460px;
  opacity: 0.5;
  padding: 0;
  position: relative;
  text-align: center;
}

.do-you-offer-employe-1 {
  background-color: transparent;
  border: 0;
  color: var(--graye);
  font-weight: 400;
  height: 29px;
  line-height: normal;
  min-width: 455px;
  opacity: 0.5;
  padding: 0;
  position: relative;
  text-align: center;
}

.sant-et-scurit-au-travail-3 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 18px;
  position: relative;
  width: fit-content;
}

.do-you-ensure-that-e {
  background-color: transparent;
  border: 0;
  color: var(--white-lilac);
  font-weight: 400;
  height: 45px;
  line-height: normal;
  min-width: 473px;
  opacity: 0.5;
  padding: 0;
  position: relative;
  text-align: center;
}

.flex-row-150 {
  align-items: flex-start;
  align-self: flex-end;
  display: flex;
  gap: 560px;
  height: 75px;
  margin-right: 131px;
  margin-top: 67px;
  min-width: 732px;
  position: relative;
}
