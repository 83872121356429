.frame-7-9 {
  align-items: center;
  background-color: var(--rum-swizzle);
  border-radius: 6px;
  box-shadow: 0px 4px 11px #1e454945;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 39px;
  justify-content: center;
  padding: 15px;
  position: relative;
  width: 78px;
}

.place-268 {
  letter-spacing: 0.20px;
  line-height: 18px;
  margin-bottom: -3.50px;
  margin-top: -5.50px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.frame-7-9.frame-7-10 {
  margin-left: 33px;
  margin-top: 26px;
}

