.frame-115-117,
.frame-115-118,
.frame-115-119,
.frame-115-120 {
  align-items: center;
  background-color: var(--tropical-rain-forest);
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  padding: 15px;
  position: relative;
  transition: all 0.2s ease-in;
  width: fit-content;
}

.frame-115-117:hover,
.frame-115-118:hover,
.frame-115-119:hover,
.frame-115-120:hover {
  transform: scale(1.1);
}

.kaizen-10,
.kaizen-11,
.kaizen-12,
.kaizen-13 {
  letter-spacing: 0.20px;
  line-height: 18px;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

