.frame-9-6,
.frame-9-7 {
  align-items: center;
  background-color: var(--rum-swizzle);
  border-radius: 6px;
  box-shadow: 0px 4px 11px #1e454945;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 131px;
  justify-content: center;
  left: 736px;
  padding: 15px;
  position: absolute;
  top: 0;
  width: 242px;
}

.x3-etude-des-causes,
.x3-study-of-the-causes {
  letter-spacing: 0;
  line-height: 25.9px;
  position: relative;
  text-align: center;
  width: 161px;
}

