.frame-115-88,
.frame-116-92,
.frame-115-89,
.frame-116-93,
.frame-115-90,
.frame-116-94,
.frame-115-91,
.frame-115-92,
.frame-116-95,
.frame-116-96,
.frame-116-97,
.frame-115-93,
.frame-116-98,
.frame-115-94,
.frame-115-95,
.frame-116-99,
.frame-116-100,
.frame-116-101,
.frame-116-102,
.frame-116-103,
.frame-116-104,
.frame-116-105,
.frame-116-106,
.frame-116-107,
.frame-116-108,
.frame-116-109,
.frame-116-110,
.frame-116-111,
.frame-116-112,
.frame-116-113,
.frame-116-114,
.frame-116-115,
.frame-116-116,
.frame-116-117,
.frame-116-118,
.frame-116-119,
.frame-116-120,
.frame-116-121,
.frame-116-122,
.frame-116-123,
.frame-116-124,
.frame-116-125,
.frame-116-126,
.frame-116-127,
.frame-116-128,
.frame-116-129,
.frame-116-130,
.frame-116-131,
.frame-116-132,
.frame-116-133,
.frame-116-134,
.frame-116-135,
.frame-116-136,
.frame-116-137,
.frame-116-138,
.frame-116-139,
.frame-116-140,
.frame-116-141,
.frame-116-142,
.frame-116-143,
.frame-116-144,
.frame-116-145,
.frame-116-146,
.frame-116-147,
.frame-116-148,
.frame-116-149,
.frame-116-150,
.frame-116-151,
.frame-116-152,
.frame-116-153,
.frame-116-154 {
  align-items: center;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  padding: 15px;
  position: relative;
  transition: all 0.2s ease-in;
  width: fit-content;
}

.frame-115-88:hover,
.frame-116-92:hover,
.frame-115-89:hover,
.frame-116-93:hover,
.frame-115-90:hover,
.frame-116-94:hover,
.frame-115-91:hover,
.frame-115-92:hover,
.frame-116-95:hover,
.frame-116-96:hover,
.frame-116-97:hover,
.frame-115-93:hover,
.frame-116-98:hover,
.frame-115-94:hover,
.frame-115-95:hover,
.frame-116-99:hover,
.frame-116-100:hover,
.frame-116-101:hover,
.frame-116-102:hover,
.frame-116-103:hover,
.frame-116-104:hover,
.frame-116-105:hover,
.frame-116-106:hover,
.frame-116-107:hover,
.frame-116-108:hover,
.frame-116-109:hover,
.frame-116-110:hover,
.frame-116-111:hover,
.frame-116-112:hover,
.frame-116-113:hover,
.frame-116-114:hover,
.frame-116-115:hover,
.frame-116-116:hover,
.frame-116-117:hover,
.frame-116-118:hover,
.frame-116-119:hover,
.frame-116-120:hover,
.frame-116-121:hover,
.frame-116-122:hover,
.frame-116-123:hover,
.frame-116-124:hover,
.frame-116-125:hover,
.frame-116-126:hover,
.frame-116-127:hover,
.frame-116-128:hover,
.frame-116-129:hover,
.frame-116-130:hover,
.frame-116-131:hover,
.frame-116-132:hover,
.frame-116-133:hover,
.frame-116-134:hover,
.frame-116-135:hover,
.frame-116-136:hover,
.frame-116-137:hover,
.frame-116-138:hover,
.frame-116-139:hover,
.frame-116-140:hover,
.frame-116-141:hover,
.frame-116-142:hover,
.frame-116-143:hover,
.frame-116-144:hover,
.frame-116-145:hover,
.frame-116-146:hover,
.frame-116-147:hover,
.frame-116-148:hover,
.frame-116-149:hover,
.frame-116-150:hover,
.frame-116-151:hover,
.frame-116-152:hover,
.frame-116-153:hover,
.frame-116-154:hover {
  transform: scale(1.1);
}

.decisional-model,
.reference-cases,
.decisional-model-1,
.reference-cases-1,
.decisional-model-2,
.reference-cases-2,
.decisional-model-3,
.decisional-model-4,
.reference-cases-3,
.reference-cases-4,
.reference-cases-5,
.decisional-model-5,
.reference-cases-6,
.decisional-model-6,
.decisional-model-7,
.reference-cases-7,
.reference-cases-8,
.reference-cases-9,
.reference-cases-10,
.reference-cases-11,
.reference-cases-12,
.reference-cases-13,
.reference-cases-14,
.reference-cases-15,
.reference-cases-16,
.reference-cases-17,
.reference-cases-18,
.reference-cases-19,
.reference-cases-20,
.reference-cases-21,
.reference-cases-22,
.reference-cases-23,
.reference-cases-24,
.reference-cases-25,
.reference-cases-26,
.reference-cases-27,
.reference-cases-28,
.reference-cases-29,
.reference-cases-30,
.reference-cases-31,
.reference-cases-32,
.reference-cases-33,
.reference-cases-34,
.reference-cases-35,
.reference-cases-36,
.reference-cases-37,
.reference-cases-38,
.reference-cases-39,
.reference-cases-40,
.reference-cases-41,
.reference-cases-42,
.reference-cases-43,
.reference-cases-44,
.reference-cases-45,
.reference-cases-46,
.reference-cases-47,
.reference-cases-48,
.reference-cases-49,
.reference-cases-50,
.reference-cases-51,
.reference-cases-52,
.reference-cases-53,
.reference-cases-54,
.reference-cases-55,
.reference-cases-56,
.reference-cases-57,
.reference-cases-58,
.reference-cases-59,
.reference-cases-60,
.reference-cases-61,
.reference-cases-62 {
  letter-spacing: 0.20px;
  line-height: 18px;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

