.existing-systems-1 {
  align-items: center;
  background-color: var(--white);
  border: 1px none;
  display: flex;
  flex-direction: column;
  height: 900px;
  overflow: hidden;
  width: 1530px;
}

.overlap-group-78 {
  align-items: flex-start;
  background-color: var(--rum-swizzle);
  border-radius: 41px 0px 100px 118px;
  display: flex;
  gap: 360px;
  height: 180px;
  justify-content: flex-end;
  margin-right: 5.0px;
  min-width: 1525px;
  padding: 20px 23px;
}

.x1200px-logo_icam_-_2008-1-78 {
  cursor: pointer;
  height: 74px;
  object-fit: cover;
  width: 144px;
}

.nav-items-container-42 {
  align-items: center;
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-bottom: 2px;
  min-height: 128px;
  position: relative;
  width: 926px;
}

.existing-systems-3 {
  height: 97px;
  letter-spacing: 0;
  line-height: normal;
  margin-right: 9.0px;
  margin-top: 19px;
  text-align: center;
  width: 577px;
}

.x2-supplementary-models {
  height: 97px;
  letter-spacing: 0;
  line-height: normal;
  margin-left: 3.0px;
  margin-top: 47px;
  text-align: center;
  width: 577px;
}

.group-110 {
  align-items: flex-start;
  display: flex;
  height: 165px;
  margin-left: 12.0px;
  margin-top: 81px;
  min-width: 716px;
}

.frame-container-19 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 69px;
  min-height: 165px;
  width: 147px;
}

.frame-11-11 {
  align-items: center;
  background-color: var(--rum-swizzle);
  border-radius: 40px;
  box-shadow: 0px 4px 11px #1e454945;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  padding: 15px;
  position: relative;
  transition: all 0.2s ease-in;
  width: fit-content;
}

.frame-11-11:hover,
.frame-113-68:hover {
  transform: scale(1.1);
}

.lean-vsm-16,
.x4-kaizen,
.x2-5-s,
.x5-smed,
.x6-visual-management {
  letter-spacing: 0.20px;
  line-height: 18px;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.frame-container-20 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 69px;
  margin-left: 138px;
  min-height: 165px;
  width: 104px;
}

.frame-113-68 {
  align-items: center;
  background-color: var(--rum-swizzle);
  border-radius: 40px;
  box-shadow: 0px 4px 11px #1e454945;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  margin-right: 1.0px;
  padding: 15px;
  position: relative;
  transition: all 0.2s ease-in;
  width: fit-content;
}

.frame-container-21 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 69px;
  margin-left: 97px;
  min-height: 165px;
  position: relative;
  width: 230px;
}

.ellipse-116-3 {
  align-self: flex-end;
  height: 100px;
  margin-right: -1239px;
  margin-top: 4538px;
  width: 100px;
}

