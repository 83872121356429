.frame-13-1 {
  display: flex;
  height: 36px;
  min-width: 146px;
  position: relative;
}

.frame-13-1.frame-138 {
  display: flex;
  height: 36px;
  margin-bottom: -3.50px;
  min-width: 146px;
  position: relative;
}

.frame-13-1.frame-153,
.frame-13-1.frame-153-1,
.frame-13-1.frame-153-2,
.frame-13-1.frame-153-3,
.frame-13-1.frame-153-4,
.frame-13-1.frame-136,
.frame-13-1.frame-153-5,
.frame-13-1.frame-153-6,
.frame-13-1.frame-153-7,
.frame-13-1.frame-153-8 {
  display: flex;
  height: 36px;
  position: relative;
  width: 146px;
}

.frame-13-1.frame-130 {
  display: flex;
  height: 36px;
  margin-left: 292px;
  margin-top: 21px;
  position: relative;
  width: 146px;
}

.frame-13-1.frame-133 {
  display: flex;
  height: 36px;
  margin-left: 292px;
  margin-top: 12px;
  position: relative;
  width: 146px;
}

.frame-13-1.frame-134 {
  display: flex;
  height: 36px;
  margin-left: 293px;
  margin-top: 17px;
  position: relative;
  width: 146px;
}

.frame-13-1.frame-133-1 {
  display: flex;
  height: 36px;
  margin-left: 292px;
  margin-top: 16px;
  position: relative;
  width: 146px;
}

.frame-13-1.frame-130-1 {
  display: flex;
  height: 36px;
  margin-left: 252px;
  margin-top: 8px;
  position: relative;
  width: 146px;
}

.frame-13-1.frame-130-2 {
  display: flex;
  height: 36px;
  margin-left: 252px;
  margin-top: 21px;
  position: relative;
  width: 146px;
}

.frame-13-1.frame-130-3 {
  display: flex;
  height: 36px;
  margin-left: 254px;
  margin-top: 13px;
  position: relative;
  width: 146px;
}

.frame-13-1.frame-131 {
  display: flex;
  height: 36px;
  margin-left: 249px;
  margin-top: 17px;
  position: relative;
  width: 146px;
}

.frame-13-1.frame-132 {
  display: flex;
  height: 36px;
  margin-left: 248px;
  margin-top: 15px;
  position: relative;
  width: 146px;
}

.frame-13-1.frame-146 {
  display: flex;
  height: 36px;
  margin-bottom: -13.00px;
  min-width: 146px;
  position: relative;
}

