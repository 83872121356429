.frame-8-9,
.frame-8-10 {
  align-items: center;
  background-color: var(--rum-swizzle);
  border-radius: 6px;
  box-shadow: 0px 4px 11px #1e454945;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 131px;
  justify-content: center;
  left: 367px;
  padding: 15px;
  position: absolute;
  top: 232px;
  width: 242px;
}

.x5-mise-en-action-des-contre-mesures,
.x5-implementation-of-countermeasures {
  letter-spacing: 0;
  line-height: 25.9px;
  position: relative;
  text-align: center;
  width: 202px;
}

