.smed-15 {
  align-items: flex-start;
  background-color: var(--white);
  border: 1px none;
  display: flex;
  flex-direction: column;
  gap: 12px;
  height: 924px;
  width: 1440px;
}

.overlap-group2-19 {
  align-items: flex-start;
  background-color: var(--rum-swizzle);
  border-radius: 41px 0px 100px 118px;
  display: flex;
  gap: 195px;
  height: 190px;
  justify-content: flex-end;
  min-width: 1440px;
  padding: 20px 18px;
}

.x1200px-logo_icam_-_2008-1-63 {
  cursor: pointer;
  height: 74px;
  object-fit: cover;
  width: 144px;
}

.nav-items-container-33 {
  align-items: center;
  align-self: center;
  display: flex;
  flex-direction: column;
  gap: 31px;
  margin-top: 5.0px;
  min-height: 127px;
  width: 1011px;
}

.nav-items-2-49 {
  align-items: center;
  cursor: pointer;
  display: flex;
  gap: 60px;
  height: 48px;
  justify-content: center;
  position: relative;
  transition: all 0.2s ease-in;
  width: 1011px;
}

.nav-items-2-49:hover,
.nav-items-3-75:hover {
  transform: translate(0, -5px);
}

.frame-117-100 {
  align-items: center;
  border-radius: 6px;
  box-shadow: 0px 4px 4px #00000040;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  padding: 15px;
  position: relative;
  transition: all 0.2s ease-in;
  width: fit-content;
}

.frame-117-100:hover,
.frame-113-64:hover {
  transform: scale(1.1);
}

.design-81,
.lean-vsm-13 {
  letter-spacing: 0.20px;
  line-height: 18px;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.nav-items-3-75 {
  align-items: center;
  cursor: pointer;
  display: flex;
  gap: 60px;
  height: 48px;
  justify-content: center;
  margin-left: 7.0px;
  position: relative;
  transition: all 0.2s ease-in;
  width: 940px;
}

.frame-113-64 {
  align-items: center;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  margin-left: -2.00px;
  padding: 15px;
  position: relative;
  transition: all 0.2s ease-in;
  width: fit-content;
}

.flex-row-127 {
  align-items: flex-start;
  display: flex;
  gap: 20px;
  height: 702px;
  margin-left: 15.9px;
  min-width: 1187px;
}

.flex-col-87 {
  align-items: flex-start;
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  min-height: 671px;
  width: 136px;
}

.arrow-container-20 {
  align-items: flex-start;
  align-self: flex-end;
  display: flex;
  gap: 6px;
  height: 88px;
  min-width: 121px;
}

.arrow-12-4 {
  height: 87px;
  width: 55px;
}

.arrow-13-5 {
  align-self: flex-end;
  height: 87px;
  width: 60px;
}

.ellipse-86-20 {
  align-self: center;
  background-color: var(--rum-swizzle);
  border-radius: 36px/35.5px;
  height: 71px;
  margin-right: 12.16px;
  margin-top: 40px;
  width: 72px;
}

.rectangle-23-3 {
  background-color: var(--rum-swizzle);
  height: 47px;
  margin-left: 8.1px;
  margin-top: 40px;
  width: 99px;
}

.frame-8-14 {
  background-color: var(--rum-swizzle);
  border-radius: 6px;
  box-shadow: 0px 4px 11px #1e454945;
  height: 47px;
  margin-left: 9.1px;
  margin-top: 44px;
  width: 99px;
}

.rectangle-24-3 {
  background-color: var(--rum-swizzle);
  height: 69px;
  margin-top: 51px;
  transform: rotate(89.67deg);
  width: 120px;
}

.group-86-2 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-left: 0.1px;
  margin-top: 51px;
  min-height: 36px;
  width: 125px;
}

.overlap-group-64 {
  height: 11px;
  margin-right: 2.0px;
  position: relative;
  width: 123px;
}

.line-2-18 {
  height: 1px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 5px;
  width: 123px;
}

.ellipse-86-21 {
  background-color: var(--alto);
  border-radius: 9.99px/5.51px;
  height: 11px;
  left: 23px;
  position: absolute;
  top: 0;
  width: 20px;
}

.label-147 {
  letter-spacing: 0;
  line-height: 25.9px;
  margin-right: 13.56px;
  min-height: 19px;
  white-space: nowrap;
  width: 53px;
}

.group-85-2 {
  align-items: flex-start;
  align-self: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-right: 0.16px;
  margin-top: 16px;
  min-height: 72px;
  width: 70px;
}

.text-format-20 {
  height: 33px;
  object-fit: cover;
  width: 47px;
}

.label-148 {
  letter-spacing: 0;
  line-height: 25.9px;
  margin-left: 0;
  min-height: 29px;
  white-space: nowrap;
}

.flex-row-128 {
  align-items: flex-end;
  display: flex;
  gap: 79px;
  min-width: 1031px;
}

.flex-row-129 {
  align-items: flex-start;
  display: flex;
  gap: 19px;
  min-width: 101px;
}

.line-23-2 {
  height: 693px;
  object-fit: cover;
  width: 4px;
}

.frame-7-6 {
  align-items: center;
  background-color: var(--rum-swizzle);
  border-radius: 6px;
  box-shadow: 0px 4px 11px #1e454945;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 39px;
  justify-content: center;
  margin-top: 13.0px;
  padding: 15px;
  position: relative;
  width: 78px;
}

.place-263 {
  letter-spacing: 0.20px;
  line-height: 18px;
  margin-bottom: -3.50px;
  margin-top: -5.50px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.flex-col-88 {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 4.0px;
  min-height: 665px;
  width: 851px;
}

.click-on-modeling-17 {
  height: 48px;
  letter-spacing: 0;
  line-height: normal;
  margin-left: 25.0px;
  text-align: center;
  width: 570px;
}

.overlap-group-container-14 {
  height: 448px;
  margin-top: 25px;
  position: relative;
  width: 851px;
}

.overlap-group4-16 {
  height: 66px;
  left: 8px;
  position: absolute;
  top: 225px;
  width: 138px;
}

.label-149 {
  left: 0;
  letter-spacing: 0;
  line-height: 25.9px;
  position: absolute;
  top: 0;
  width: 138px;
}

.arrow-5-13 {
  height: 22px;
  left: 1px;
  position: absolute;
  top: 44px;
  width: 137px;
}

.overlap-group3-19 {
  height: 65px;
  left: 662px;
  position: absolute;
  top: 225px;
  width: 188px;
}

.label-150 {
  left: 22px;
  letter-spacing: 0;
  line-height: 25.9px;
  position: absolute;
  top: 0;
  width: 167px;
}

.arrow-15-3 {
  height: 22px;
  left: 0;
  position: absolute;
  top: 43px;
  width: 137px;
}

.overlap-group1-42 {
  height: 448px;
  left: 0;
  position: absolute;
  top: 0;
  width: 799px;
}

.rectangle-8-1 {
  background-color: var(--rum-swizzle);
  height: 82px;
  left: 0;
  position: absolute;
  top: 0;
  width: 220px;
}

.arrow-12-5 {
  height: 97px;
  left: 151px;
  position: absolute;
  top: 82px;
  width: 22px;
}

.arrow-13-6 {
  height: 97px;
  left: 628px;
  position: absolute;
  top: 82px;
  width: 22px;
}

.rectangle-10-3 {
  background-color: var(--rum-swizzle);
  height: 82px;
  left: 581px;
  position: absolute;
  top: 11px;
  width: 218px;
}

.derniere-piece-bonne-serie-a-1 {
  left: 41px;
  letter-spacing: 0;
  line-height: 25.9px;
  position: absolute;
  top: 15px;
  width: 161px;
}

.rectangle-11-2 {
  background-color: var(--rum-swizzle);
  height: 270px;
  left: 160px;
  position: absolute;
  top: 178px;
  width: 481px;
}

.changement-outillage-1 {
  left: 351px;
  letter-spacing: 0;
  line-height: 25.9px;
  position: absolute;
  top: 357px;
  transform: rotate(-0.54deg);
  width: 132px;
}

.reglage-1 {
  left: 507px;
  letter-spacing: 0;
  line-height: 25.9px;
  position: absolute;
  top: 287px;
  transform: rotate(-60.79deg);
  white-space: nowrap;
}

.nettoyage-1 {
  left: 205px;
  letter-spacing: 0;
  line-height: 25.9px;
  position: absolute;
  top: 281px;
  transform: rotate(47.17deg);
  white-space: nowrap;
}

.arrow-18-1 {
  height: 22px;
  left: 316px;
  position: absolute;
  top: 373px;
  width: 212px;
}

.arrow-17-2 {
  height: 129px;
  left: 532px;
  position: absolute;
  top: 244px;
  width: 76px;
}

.arrow-16-3 {
  height: 130px;
  left: 188px;
  position: absolute;
  top: 245px;
  width: 120px;
}

.premiere-piece-bonne-serie-b-1 {
  left: 622px;
  letter-spacing: 0;
  line-height: 25.9px;
  position: absolute;
  top: 24px;
  width: 150px;
}

.flex-row-130 {
  align-items: flex-end;
  display: flex;
  height: 124px;
  margin-left: 24.0px;
  margin-top: 20px;
  min-width: 667px;
  position: relative;
}

.flex-col-89 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 42px;
  min-height: 111px;
  width: 191px;
}

.arrow-14-3 {
  align-self: flex-end;
  height: 22px;
  width: 137px;
}

.frame-5-20 {
  align-items: center;
  background-color: var(--rum-swizzle);
  border-radius: 6px;
  box-shadow: 0px 4px 11px #1e454945;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 47px;
  justify-content: center;
  padding: 15px;
  position: relative;
  width: 156px;
}

.modeling-108 {
  cursor: pointer;
  letter-spacing: 0.20px;
  line-height: 18px;
  margin-top: -1.50px;
  position: relative;
  text-align: center;
  text-decoration: underline;
  white-space: nowrap;
  width: fit-content;
}

.flex-col-90 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 44px;
  margin-left: 7px;
  min-height: 113px;
  width: 258px;
}

.arrow-11-5 {
  height: 22px;
  width: 137px;
}

.frame-2-25 {
  align-items: center;
  align-self: flex-end;
  background-color: var(--rum-swizzle);
  border-radius: 6px;
  box-shadow: 0px 4px 11px #1e454945;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 47px;
  justify-content: center;
  padding: 15px;
  position: relative;
  width: 156px;
}

.continue-19 {
  letter-spacing: 0.20px;
  line-height: 18px;
  margin-top: -1.50px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

