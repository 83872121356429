.table-header-2 {
  align-items: flex-start;
  background-color: var(--tropical-rain-forest);
  border-radius: 5px 5px 0px 0px;
  display: flex;
  gap: 10px;
  height: 17px;
  left: 0;
  padding: 7px 10px;
  position: absolute;
  top: 0;
  width: 122px;
}

.table-name-2 {
  align-self: stretch;
  flex: 1;
  letter-spacing: 0.16px;
  line-height: 20.8px;
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
}

.table-header-2.table-header,
.table-header-2.table-header-4,
.table-header-2.table-header-5,
.table-header-2.table-header-6,
.table-header-2.table-header-7,
.table-header-2.table-header-1,
.table-header-2.table-header-1-1,
.table-header-2.table-header-1-2,
.table-header-2.table-header-1-3,
.table-header-2.table-header-1-4 {
  height: 40px;
  width: 272px;
}

.table-header-2.table-header-4 .table-name-2,
.table-header-2.table-header-1-1 .table-name-2,
.table-header-2.table-header-5 .table-name-2,
.table-header-2.table-header-6 .table-name-2,
.table-header-2.table-header-7 .table-name-2,
.table-header-2.table-header .table-name-2,
.table-header-2.table-header-1-2 .table-name-2,
.table-header-2.table-header-1-3 .table-name-2,
.table-header-2.table-header-1-4 .table-name-2,
.table-header-2.table-header-1 .table-name-2 {
  white-space: unset;
}

