.table-number-base-8-1 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  justify-content: flex-end;
  min-width: 271.9px;
}

.overlap-group1-10 {
  height: 24px;
  margin-top: 0;
  position: relative;
  width: 272px;
}

.field-8 {
  align-items: flex-start;
  background-color: var(--white-2);
  display: flex;
  gap: 15px;
  height: 24px;
  left: 0;
  padding: 7px 10px;
  position: absolute;
  top: 0;
  width: 272px;
}

.type-4 {
  letter-spacing: 0;
  line-height: 18.2px;
  margin-bottom: -8.44px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.null-6 {
  align-self: stretch;
  letter-spacing: 0;
  line-height: 18.2px;
  opacity: 0;
  position: relative;
  white-space: nowrap;
  width: 35px;
}

.line-container-8 {
  height: 24px;
  left: 0;
  position: absolute;
  top: 0;
  width: 272px;
}

.bottom-line-8 {
  height: 1px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 23px;
  width: 272px;
}

.left-line-8 {
  height: 23px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 1px;
}

.right-line-8 {
  height: 23px;
  left: 271px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 1px;
}

.table-number-base-8-1.table-number-base-10 {
  justify-content: unset;
  min-width: 272px;
}

.table-number-base-8-1.table-number-base-10 .overlap-group1-10,
.table-number-base-8-1.table-number-base-11 .overlap-group1-10 {
  height: 23px;
  margin-top: unset;
}

.table-number-base-8-1.table-number-base-10 .field-8,
.table-number-base-8-1.table-number-base-10 .line-container-8,
.table-number-base-8-1.table-number-base-11 .field-8,
.table-number-base-8-1.table-number-base-11 .line-container-8 {
  height: 23px;
}

.table-number-base-8-1.table-number-base-10 .type-4,
.table-number-base-8-1.table-number-base-11 .type-4 {
  margin-bottom: -9.00px;
}

.table-number-base-8-1.table-number-base-10 .bottom-line-8,
.table-number-base-8-1.table-number-base-11 .bottom-line-8 {
  top: 22px;
}

.table-number-base-8-1.table-number-base-10 .left-line-8,
.table-number-base-8-1.table-number-base-10 .right-line-8,
.table-number-base-8-1.table-number-base-11 .left-line-8,
.table-number-base-8-1.table-number-base-11 .right-line-8 {
  height: 22px;
}

.table-number-base-8-1.table-number-base-8-2 .type-4,
.table-number-base-8-1.table-number-base-9-2 .type-4,
.table-number-base-8-1.table-number-base-8 .type-4,
.table-number-base-8-1.table-number-base-9 .type-4 {
  align-self: stretch;
  margin-bottom: unset;
  width: 105px;
}

.table-number-base-8-1.table-number-base-9-1 .overlap-group1-10,
.table-number-base-8-1.table-number-base-9-2 .overlap-group1-10 {
  margin-top: unset;
}

.table-number-base-8-1.table-number-base-11 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  min-width: 272px;
}

