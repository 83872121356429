.visual-management-2-1 {
  align-items: center;
  background-color: var(--white);
  border: 1px none;
  display: flex;
  flex-direction: column;
  gap: 19px;
  height: 900px;
  width: 1530px;
}

.overlap-group-22 {
  height: 228px;
  position: relative;
  width: 1530px;
}

.rectangle-3-9 {
  background-color: var(--rum-swizzle);
  border-radius: 41px 0px 100px 118px;
  height: 180px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1530px;
}

.x1200px-logo_icam_-_2008-1-22 {
  cursor: pointer;
  height: 74px;
  left: 72px;
  object-fit: cover;
  position: absolute;
  top: 20px;
  width: 144px;
}

.please-answer-the-following-questions-3 {
  height: 54px;
  left: 467px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 174px;
  width: 577px;
}

.flex-row-69 {
  align-items: flex-start;
  display: flex;
  gap: 19px;
  height: 647px;
  margin-left: 18.0px;
  min-width: 1430px;
}

.flex-col-48 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 3px;
  min-height: 647px;
  position: relative;
  width: 723px;
}

.frame-container-10 {
  height: 634px;
  margin-top: 1px;
  position: relative;
  width: 688px;
}

