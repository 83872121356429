.frame-116,
.frame-116-1,
.frame-116-2,
.frame-116-3,
.frame-116-4,
.frame-116-5,
.frame-116-6,
.frame-116-7,
.frame-116-8,
.frame-116-9,
.frame-116-10,
.frame-116-11,
.frame-116-12,
.frame-116-13,
.frame-116-14,
.frame-116-15,
.frame-116-16,
.frame-116-17,
.frame-116-18,
.frame-116-19,
.frame-116-20,
.frame-116-21,
.frame-116-22,
.frame-116-23,
.frame-116-24,
.frame-116-25,
.frame-116-26,
.frame-116-27,
.frame-116-28,
.frame-116-29,
.frame-116-30,
.frame-116-31,
.frame-116-32,
.frame-116-33,
.frame-116-34,
.frame-116-35,
.frame-116-36,
.frame-116-37,
.frame-116-38,
.frame-116-39,
.frame-116-40,
.frame-116-41,
.frame-116-42,
.frame-116-43,
.frame-116-44,
.frame-116-45,
.frame-116-46,
.frame-116-47,
.frame-116-48,
.frame-116-49,
.frame-116-50,
.frame-116-51,
.frame-116-52,
.frame-116-53,
.frame-116-54,
.frame-116-55,
.frame-116-56,
.frame-116-57,
.frame-116-58,
.frame-116-59,
.frame-116-60,
.frame-116-61,
.frame-116-62,
.frame-116-63,
.frame-116-64,
.frame-116-65,
.frame-116-66,
.frame-116-67,
.frame-116-68,
.frame-116-69,
.frame-116-70,
.frame-116-71,
.frame-116-72,
.frame-116-73,
.frame-116-74,
.frame-116-75,
.frame-116-76,
.frame-116-77,
.frame-116-78,
.frame-116-79,
.frame-116-80,
.frame-116-81,
.frame-116-82,
.frame-116-83 {
  align-items: center;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  padding: 15px;
  position: relative;
  transition: all 0.2s ease-in;
  width: fit-content;
}

.frame-116:hover,
.frame-116-1:hover,
.frame-116-2:hover,
.frame-116-3:hover,
.frame-116-4:hover,
.frame-116-5:hover,
.frame-116-6:hover,
.frame-116-7:hover,
.frame-116-8:hover,
.frame-116-9:hover,
.frame-116-10:hover,
.frame-116-11:hover,
.frame-116-12:hover,
.frame-116-13:hover,
.frame-116-14:hover,
.frame-116-15:hover,
.frame-116-16:hover,
.frame-116-17:hover,
.frame-116-18:hover,
.frame-116-19:hover,
.frame-116-20:hover,
.frame-116-21:hover,
.frame-116-22:hover,
.frame-116-23:hover,
.frame-116-24:hover,
.frame-116-25:hover,
.frame-116-26:hover,
.frame-116-27:hover,
.frame-116-28:hover,
.frame-116-29:hover,
.frame-116-30:hover,
.frame-116-31:hover,
.frame-116-32:hover,
.frame-116-33:hover,
.frame-116-34:hover,
.frame-116-35:hover,
.frame-116-36:hover,
.frame-116-37:hover,
.frame-116-38:hover,
.frame-116-39:hover,
.frame-116-40:hover,
.frame-116-41:hover,
.frame-116-42:hover,
.frame-116-43:hover,
.frame-116-44:hover,
.frame-116-45:hover,
.frame-116-46:hover,
.frame-116-47:hover,
.frame-116-48:hover,
.frame-116-49:hover,
.frame-116-50:hover,
.frame-116-51:hover,
.frame-116-52:hover,
.frame-116-53:hover,
.frame-116-54:hover,
.frame-116-55:hover,
.frame-116-56:hover,
.frame-116-57:hover,
.frame-116-58:hover,
.frame-116-59:hover,
.frame-116-60:hover,
.frame-116-61:hover,
.frame-116-62:hover,
.frame-116-63:hover,
.frame-116-64:hover,
.frame-116-65:hover,
.frame-116-66:hover,
.frame-116-67:hover,
.frame-116-68:hover,
.frame-116-69:hover,
.frame-116-70:hover,
.frame-116-71:hover,
.frame-116-72:hover,
.frame-116-73:hover,
.frame-116-74:hover,
.frame-116-75:hover,
.frame-116-76:hover,
.frame-116-77:hover,
.frame-116-78:hover,
.frame-116-79:hover,
.frame-116-80:hover,
.frame-116-81:hover,
.frame-116-82:hover,
.frame-116-83:hover {
  transform: scale(1.1);
}

.result-objectives,
.result-objectives-1,
.result-objectives-2,
.result-objectives-3,
.result-objectives-4,
.result-objectives-5,
.result-objectives-6,
.result-objectives-7,
.result-objectives-8,
.result-objectives-9,
.result-objectives-10,
.result-objectives-11,
.result-objectives-12,
.result-objectives-13,
.result-objectives-14,
.result-objectives-15,
.result-objectives-16,
.result-objectives-17,
.result-objectives-18,
.result-objectives-19,
.result-objectives-20,
.result-objectives-21,
.result-objectives-22,
.result-objectives-23,
.result-objectives-24,
.result-objectives-25,
.result-objectives-26,
.result-objectives-27,
.result-objectives-28,
.result-objectives-29,
.result-objectives-30,
.result-objectives-31,
.result-objectives-32,
.result-objectives-33,
.result-objectives-34,
.result-objectives-35,
.result-objectives-36,
.result-objectives-37,
.result-objectives-38,
.result-objectives-39,
.result-objectives-40,
.result-objectives-41,
.result-objectives-42,
.result-objectives-43,
.result-objectives-44,
.result-objectives-45,
.result-objectives-46,
.result-objectives-47,
.result-objectives-48,
.result-objectives-49,
.result-objectives-50,
.result-objectives-51,
.result-objectives-52,
.result-objectives-53,
.result-objectives-54,
.result-objectives-55,
.result-objectives-56,
.result-objectives-57,
.result-objectives-58,
.result-objectives-59,
.result-objectives-60,
.result-objectives-61,
.result-objectives-62,
.result-objectives-63,
.result-objectives-64,
.result-objectives-65,
.result-objectives-66,
.result-objectives-67,
.result-objectives-68,
.result-objectives-69,
.result-objectives-70,
.result-objectives-71,
.result-objectives-72,
.result-objectives-73,
.result-objectives-74,
.result-objectives-75,
.result-objectives-76,
.result-objectives-77,
.result-objectives-78,
.result-objectives-79,
.result-objectives-80,
.result-objectives-81,
.result-objectives-82,
.result-objectives-83 {
  letter-spacing: 0.20px;
  line-height: 18px;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

