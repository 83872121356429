.modernization-3 {
  align-items: flex-end;
  background-color: var(--white);
  border: 1px none;
  display: flex;
  flex-direction: column;
  height: 900px;
  width: 1530px;
}

.overlap-group-81 {
  align-items: flex-start;
  background-color: var(--rum-swizzle);
  border-radius: 41px 0px 100px 118px;
  display: flex;
  gap: 329px;
  height: 180px;
  justify-content: flex-end;
  min-width: 1530px;
  padding: 20px 59px;
}

.x1200px-logo_icam_-_2008-1-80 {
  cursor: pointer;
  height: 74px;
  object-fit: cover;
  width: 144px;
}

.nav-items-container-46 {
  align-items: flex-end;
  align-self: center;
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-top: 6.0px;
  min-height: 128px;
  position: relative;
  width: 926px;
}

.emploirelation-employeur-employ-13 {
  align-items: center;
  align-self: center;
  display: flex;
  gap: 138px;
  justify-content: center;
  margin-top: 119px;
  position: relative;
  width: fit-content;
}

.emploi-relation-employeur-employ-1-10 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 14px;
  position: relative;
  width: fit-content;
}

.do-you-have-sensors {
  background-color: transparent;
  border: 0;
  color: var(--white-lilac);
  font-weight: 400;
  height: 21px;
  line-height: normal;
  min-width: 684px;
  opacity: 0.5;
  padding: 0;
  position: relative;
  text-align: center;
}

.do-you-have-sensors::placeholder,
.do-you-have-an-iot-network::placeholder {
  color: #f7f9fb99;
}

.are-they-connected-to-the-internet {
  background-color: transparent;
  border: 0;
  color: var(--graye);
  font-weight: 400;
  height: 21px;
  line-height: normal;
  min-width: 610px;
  opacity: 0.5;
  padding: 0;
  position: relative;
  text-align: center;
}

.are-they-connected-to-the-internet::placeholder,
.do-you-know-in-real::placeholder {
  color: #ffffff99;
}

.emploi-relation-employeur-employ-2-11 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 19px;
  position: relative;
  width: fit-content;
}

.do-you-have-an-iot-network {
  background-color: transparent;
  border: 0;
  color: var(--white-lilac);
  font-weight: 400;
  height: 21px;
  line-height: normal;
  min-width: 630px;
  opacity: 0.5;
  padding: 0;
  position: relative;
  text-align: center;
}

.do-you-know-in-real {
  background-color: transparent;
  border: 0;
  color: var(--graye);
  font-weight: 400;
  height: 21px;
  line-height: normal;
  min-width: 412px;
  opacity: 0.5;
  padding: 0;
  position: relative;
  text-align: center;
}

.flex-row-147 {
  align-items: flex-start;
  display: flex;
  gap: 500px;
  height: 74px;
  margin-right: 176px;
  margin-top: 292px;
  min-width: 707px;
  position: relative;
}

