.decisional-view-2 {
  align-items: flex-start;
  background-color: var(--white);
  border: 1px none;
  display: flex;
  height: 900px;
  width: 1530px;
}

.overlap-group-46 {
  height: 890px;
  position: relative;
  width: 1530px;
}

.rectangle-3-18 {
  background-color: var(--rum-swizzle);
  border-radius: 41px 0px 100px 118px;
  height: 182px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1530px;
}

.x1200px-logo_icam_-_2008-1-45 {
  cursor: pointer;
  height: 74px;
  left: 72px;
  object-fit: cover;
  position: absolute;
  top: 20px;
  width: 144px;
}

.please-answer-the-following-questions-16 {
  height: 97px;
  left: 470px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 171px;
  width: 577px;
}

