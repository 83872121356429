.crew-member-3,
.crew-member-4 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  left: 751px;
  min-height: 111px;
  position: absolute;
  top: 314px;
  width: 272px;
}

.table-container-3,
.table-container-4 {
  height: 64px;
  position: relative;
  width: 272px;
}

.table-text-base-container-3,
.table-text-base-container-4 {
  height: 47px;
  position: relative;
  width: 272px;
}

