.process-view-3-1 {
  align-items: flex-start;
  background-color: var(--white);
  border: 1px none;
  display: flex;
  flex-direction: column;
  gap: 575px;
  height: 900px;
  width: 1530px;
}

.overlap-group-45 {
  height: 250px;
  position: relative;
  width: 1525px;
}

.rectangle-3-17 {
  background-color: var(--rum-swizzle);
  border-radius: 41px 0px 100px 118px;
  height: 180px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1525px;
}

.x1200px-logo_icam_-_2008-1-44 {
  cursor: pointer;
  height: 74px;
  left: 72px;
  object-fit: cover;
  position: absolute;
  top: 20px;
  width: 144px;
}

.please-answer-the-following-questions-15 {
  height: 97px;
  left: 483px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 153px;
  width: 577px;
}

.flex-row-91 {
  align-items: flex-start;
  align-self: flex-end;
  display: flex;
  gap: 461px;
  height: 66px;
  margin-right: 61px;
  min-width: 806px;
  position: relative;
}

.frame-113-55 {
  align-items: center;
  background-color: var(--rum-swizzle);
  border-radius: 40px;
  box-shadow: 0px 4px 11px #1e454945;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  padding: 15px;
  position: relative;
  transition: all 0.2s ease-in;
  width: fit-content;
}

.frame-113-55:hover {
  transform: scale(1.1);
}

.informational-model-11 {
  letter-spacing: 0.20px;
  line-height: 18px;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

