.visual-management-3-1 {
  align-items: flex-end;
  background-color: var(--white);
  border: 1px none;
  display: flex;
  flex-direction: column;
  gap: 554px;
  height: 900px;
  width: 1530px;
}

.overlap-group-24 {
  height: 252px;
  position: relative;
  width: 1530px;
}

.rectangle-3-11 {
  background-color: var(--rum-swizzle);
  border-radius: 41px 0px 100px 118px;
  height: 180px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1530px;
}

.x1200px-logo_icam_-_2008-1-24 {
  cursor: pointer;
  height: 74px;
  left: 72px;
  object-fit: cover;
  position: absolute;
  top: 20px;
  width: 144px;
}

.please-answer-the-following-questions-5 {
  height: 97px;
  left: 467px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 155px;
  width: 577px;
}

.flex-row-71 {
  align-items: flex-start;
  display: flex;
  gap: 456px;
  height: 88px;
  margin-right: 62px;
  min-width: 821px;
  position: relative;
}

.frame-116-177 {
  align-items: center;
  background-color: var(--rum-swizzle);
  border-radius: 40px;
  box-shadow: 0px 4px 11px #1e454945;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  padding: 15px;
  position: relative;
  transition: all 0.2s ease-in;
  width: fit-content;
}

.frame-116-177:hover {
  transform: scale(1.1);
}

.go-on-to-the-next-step {
  letter-spacing: 0.20px;
  line-height: 18px;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

