.frame-4-1,
.frame-6-3,
.frame-4-2,
.frame-6-4 {
  align-items: center;
  background-color: var(--rum-swizzle);
  border-radius: 6px;
  box-shadow: 0px 4px 11px #1e454945;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 77px;
  justify-content: center;
  left: 201px;
  padding: 15px;
  position: absolute;
  top: 126px;
  width: 143px;
}

.processing,
.processing-1,
.processing-2,
.processing-3 {
  letter-spacing: 0.20px;
  line-height: 18px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

