.frame-113 {
  align-items: center;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  margin-left: -3.50px;
  padding: 15px;
  position: relative;
  transition: all 0.2s ease-in;
  width: fit-content;
}

.frame-113:hover {
  transform: scale(1.1);
}

.aquisition {
  letter-spacing: 0.20px;
  line-height: 18px;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.frame-113.frame-113-3,
.frame-113.frame-113-6,
.frame-113.frame-113-10,
.frame-113.frame-113-25 {
  margin-left: unset;
}

.frame-113.frame-113-16,
.frame-113.frame-113-22 {
  margin-left: -4.50px;
}

.frame-113.frame-113-24 {
  margin-left: -7.00px;
}

