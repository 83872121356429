.visual-management-15 {
  align-items: flex-start;
  background-color: var(--white);
  border: 1px none;
  display: flex;
  flex-direction: column;
  gap: 48px;
  height: 924px;
  width: 1440px;
}

.overlap-group1-43 {
  align-items: flex-start;
  background-color: var(--rum-swizzle);
  border-radius: 41px 0px 100px 118px;
  display: flex;
  gap: 195px;
  height: 190px;
  justify-content: flex-end;
  min-width: 1440px;
  padding: 20px 18px;
}

.x1200px-logo_icam_-_2008-1-64 {
  cursor: pointer;
  height: 74px;
  object-fit: cover;
  width: 144px;
}

.nav-items-container-34 {
  align-items: center;
  align-self: center;
  display: flex;
  flex-direction: column;
  gap: 31px;
  margin-top: 5.0px;
  min-height: 127px;
  width: 1011px;
}

.nav-items-2-50 {
  align-items: center;
  cursor: pointer;
  display: flex;
  gap: 60px;
  height: 48px;
  justify-content: center;
  position: relative;
  transition: all 0.2s ease-in;
  width: 1011px;
}

.nav-items-2-50:hover,
.nav-items-3-76:hover {
  transform: translate(0, -5px);
}

.frame-117-101 {
  align-items: center;
  border-radius: 6px;
  box-shadow: 0px 4px 4px #00000040;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  padding: 15px;
  position: relative;
  transition: all 0.2s ease-in;
  width: fit-content;
}

.frame-117-101:hover,
.frame-113-65:hover {
  transform: scale(1.1);
}

.design-82,
.lean-vsm-14 {
  letter-spacing: 0.20px;
  line-height: 18px;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.nav-items-3-76 {
  align-items: center;
  cursor: pointer;
  display: flex;
  gap: 60px;
  height: 48px;
  justify-content: center;
  margin-left: 7.0px;
  position: relative;
  transition: all 0.2s ease-in;
  width: 940px;
}

.frame-113-65 {
  align-items: center;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  margin-left: -2.00px;
  padding: 15px;
  position: relative;
  transition: all 0.2s ease-in;
  width: fit-content;
}

.flex-row-131 {
  align-items: flex-start;
  display: flex;
  gap: 9px;
  height: 659px;
  margin-left: 11px;
  min-width: 1323px;
}

.flex-row-132 {
  align-items: center;
  display: flex;
  gap: 13px;
  min-width: 253px;
}

.flex-col-91 {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 7.85px;
  min-height: 606px;
  width: 136px;
}

.arrow-container-21 {
  align-items: flex-start;
  align-self: flex-end;
  display: flex;
  gap: 6px;
  height: 88px;
  min-width: 121px;
}

.arrow-9-13 {
  height: 87px;
  width: 55px;
}

.arrow-10-7 {
  align-self: flex-end;
  height: 87px;
  width: 60px;
}

.ellipse-85-13 {
  background-color: var(--rum-swizzle);
  border-radius: 36px/35.5px;
  height: 71px;
  margin-right: 12.27px;
  margin-top: 40px;
  width: 72px;
}

.rectangle-9-4 {
  align-self: flex-start;
  background-color: var(--rum-swizzle);
  height: 47px;
  margin-left: 8px;
  margin-top: 40px;
  width: 99px;
}

.frame-5-21 {
  align-self: flex-start;
  background-color: var(--rum-swizzle);
  border-radius: 6px;
  box-shadow: 0px 4px 11px #1e454945;
  height: 47px;
  margin-left: 9px;
  margin-top: 44px;
  width: 99px;
}

.overlap-group2-20 {
  align-self: flex-start;
  height: 15px;
  margin-top: 44px;
  position: relative;
  width: 117px;
}

.line-2-19 {
  height: 1px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 7px;
  width: 117px;
}

.ellipse-86-22 {
  background-color: var(--alto);
  border-radius: 9.5px/7.5px;
  height: 15px;
  left: 22px;
  position: absolute;
  top: 0;
  width: 19px;
}

.label-151 {
  letter-spacing: 0;
  line-height: 25.9px;
  margin-right: 30.27px;
  margin-top: 7px;
  min-height: 26px;
  min-width: 50px;
  white-space: nowrap;
}

.text-format-21 {
  height: 55px;
  margin-right: 15.27px;
  margin-top: 33px;
  object-fit: cover;
  width: 69px;
}

.label-152 {
  letter-spacing: 0;
  line-height: 25.9px;
  margin-right: 12.27px;
  margin-top: 23px;
  min-height: 26px;
  min-width: 38px;
  white-space: nowrap;
}

.flex-row-133 {
  align-items: flex-start;
  display: flex;
  gap: 15px;
  min-width: 104px;
}

.line-1-17 {
  align-self: flex-end;
  height: 640px;
  width: 11px;
}

.frame-11-10 {
  align-items: center;
  background-color: var(--rum-swizzle);
  border-radius: 6px;
  box-shadow: 0px 4px 11px #1e454945;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 39px;
  justify-content: center;
  padding: 15px;
  position: relative;
  width: 78px;
}

.place-264 {
  letter-spacing: 0.20px;
  line-height: 18px;
  margin-bottom: -3.50px;
  margin-top: -5.50px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.flex-col-92 {
  align-items: center;
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  min-height: 653px;
  width: 1061px;
}

.click-on-modeling-18 {
  height: 48px;
  letter-spacing: 0;
  line-height: normal;
  margin-right: 21.0px;
  text-align: center;
  width: 570px;
}

.overlap-group3-20 {
  height: 468px;
  margin-top: 46px;
  position: relative;
  width: 1061px;
}

.overlap-group2-21 {
  height: 468px;
  left: 0;
  position: absolute;
  top: 0;
  width: 950px;
}

.frame-6-12 {
  align-items: center;
  background-color: var(--rum-swizzle);
  border-radius: 6px;
  box-shadow: 0px 4px 11px #1e454945;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 75px;
  justify-content: center;
  left: 93px;
  padding: 15px;
  position: absolute;
  top: 0;
  width: 264px;
}

.connaissances-competences,
.strategies-projets,
.idees-informations,
.temps-priorites,
.problemes-decision {
  letter-spacing: 0;
  line-height: 25.9px;
  margin-bottom: -2.50px;
  margin-top: -4.50px;
  position: relative;
  width: fit-content;
}

.frame-7-7 {
  align-items: center;
  background-color: var(--rum-swizzle);
  border-radius: 6px;
  box-shadow: 0px 4px 11px #1e454945;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 75px;
  justify-content: center;
  left: 0;
  padding: 28px 15px 15px;
  position: absolute;
  top: 189px;
  width: 264px;
}

.collaborateurs-equipes {
  letter-spacing: 0;
  line-height: 25.9px;
  margin-bottom: -9.00px;
  margin-top: -11.00px;
  position: relative;
  width: fit-content;
}

.frame-10-7 {
  align-items: center;
  background-color: var(--rum-swizzle);
  border-radius: 6px;
  box-shadow: 0px 4px 11px #1e454945;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 75px;
  justify-content: center;
  left: 693px;
  padding: 15px;
  position: absolute;
  top: 393px;
  width: 257px;
}

.frame-8-15 {
  align-items: center;
  background-color: var(--rum-swizzle);
  border-radius: 6px;
  box-shadow: 0px 4px 11px #1e454945;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 75px;
  justify-content: center;
  left: 686px;
  padding: 15px;
  position: absolute;
  top: 2px;
  width: 257px;
}

.overlap-group-65 {
  height: 392px;
  left: 97px;
  position: absolute;
  top: 69px;
  width: 602px;
}

.frame-6-13 {
  align-items: center;
  background-color: var(--rum-swizzle);
  border-radius: 6px;
  box-shadow: 0px 4px 11px #1e454945;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 75px;
  justify-content: center;
  left: 0;
  padding: 15px;
  position: absolute;
  top: 317px;
  width: 257px;
}

.arrow-11-6 {
  height: 84px;
  left: 256px;
  position: absolute;
  top: 234px;
  width: 88px;
}

.arrow-16-4 {
  height: 92px;
  left: 512px;
  position: absolute;
  top: 226px;
  width: 89px;
}

.arrow-13-7 {
  height: 82px;
  left: 261px;
  position: absolute;
  top: 4px;
  width: 82px;
}

.arrow-14-4 {
  height: 77px;
  left: 502px;
  position: absolute;
  top: 0;
  width: 85px;
}

.ellipse-87-5 {
  background-color: var(--rum-swizzle);
  border-radius: 112px/106px;
  height: 212px;
  left: 315px;
  position: absolute;
  top: 52px;
  width: 224px;
}

.mieux-manager {
  left: 364px;
  letter-spacing: 0;
  line-height: 25.9px;
  position: absolute;
  top: 131px;
}

.arrow-12-6 {
  height: 22px;
  left: 272px;
  position: absolute;
  top: 216px;
  width: 132px;
}

.arrow-15-4 {
  height: 22px;
  left: 641px;
  position: absolute;
  top: 216px;
  width: 160px;
}

.frame-9-8 {
  align-items: center;
  background-color: var(--rum-swizzle);
  border-radius: 6px;
  box-shadow: 0px 4px 11px #1e454945;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 75px;
  justify-content: center;
  left: 804px;
  padding: 15px;
  position: absolute;
  top: 189px;
  width: 257px;
}

.frame-container-15 {
  align-items: center;
  display: flex;
  gap: 269px;
  margin-right: 6.0px;
  margin-top: 41px;
  min-width: 723px;
}

.frame-8-16 {
  align-items: center;
  background-color: var(--rum-swizzle);
  border-radius: 6px;
  box-shadow: 0px 4px 11px #1e454945;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 47px;
  justify-content: center;
  margin-top: 1.0px;
  padding: 15px;
  position: relative;
  width: 156px;
}

.modeling-109 {
  cursor: pointer;
  letter-spacing: 0.20px;
  line-height: 18px;
  margin-top: -1.50px;
  position: relative;
  text-align: center;
  text-decoration: underline;
  white-space: nowrap;
  width: fit-content;
}

.frame-2-26 {
  align-items: center;
  background-color: var(--rum-swizzle);
  border-radius: 6px;
  box-shadow: 0px 4px 11px #1e454945;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 50px;
  justify-content: center;
  padding: 15px;
  position: relative;
  width: 298px;
}

.analyze-the-futur-performances {
  letter-spacing: 0.20px;
  line-height: 18px;
  margin-left: -9.50px;
  margin-right: -9.50px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

