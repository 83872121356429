.frame-116-84,
.frame-116-85,
.frame-116-86,
.frame-116-87,
.frame-116-88,
.frame-116-89,
.frame-116-90,
.frame-116-91 {
  align-items: center;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  padding: 15px;
  position: relative;
  transition: all 0.2s ease-in;
  width: fit-content;
}

.frame-116-84:hover,
.frame-116-85:hover,
.frame-116-86:hover,
.frame-116-87:hover,
.frame-116-88:hover,
.frame-116-89:hover,
.frame-116-90:hover,
.frame-116-91:hover {
  transform: scale(1.1);
}

.physical-model,
.physical-model-1,
.physical-model-2,
.physical-model-3,
.physical-model-4,
.physical-model-5,
.physical-model-6,
.physical-model-7 {
  letter-spacing: 0.20px;
  line-height: 18px;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

