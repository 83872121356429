.modernization-4 {
  align-items: flex-end;
  background-color: var(--white);
  border: 1px none;
  display: flex;
  flex-direction: column;
  height: 900px;
  width: 1530px;
}

.overlap-group-84 {
  align-items: flex-start;
  background-color: var(--rum-swizzle);
  border-radius: 41px 0px 100px 118px;
  display: flex;
  gap: 329px;
  height: 180px;
  justify-content: flex-end;
  min-width: 1530px;
  padding: 20px 59px;
}

.x1200px-logo_icam_-_2008-1-84 {
  cursor: pointer;
  height: 74px;
  object-fit: cover;
  width: 144px;
}

.nav-items-container-50 {
  align-items: flex-end;
  align-self: center;
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-top: 6.0px;
  min-height: 128px;
  position: relative;
  width: 926px;
}

.emploirelation-employeur-employ-14 {
  align-items: center;
  align-self: center;
  display: flex;
  gap: 137px;
  margin-left: 31.0px;
  margin-top: 25px;
  position: relative;
  width: fit-content;
}

.emploi-relation-employeur-employ-1-11 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 14px;
  position: relative;
  width: fit-content;
}

.are-your-different-s {
  background-color: transparent;
  border: 0;
  color: var(--white-lilac);
  font-weight: 400;
  height: 21px;
  line-height: normal;
  min-width: 684px;
  opacity: 0.5;
  padding: 0;
  position: relative;
  text-align: center;
}

.are-your-different-s::placeholder,
.do-operators-have-ac::placeholder {
  color: #f7f9fb99;
}

.do-you-have-any-means-of-communication {
  background-color: transparent;
  border: 0;
  color: var(--graye);
  font-weight: 400;
  height: 21px;
  line-height: normal;
  min-width: 610px;
  opacity: 0.5;
  padding: 0;
  position: relative;
  text-align: center;
}

.do-you-have-any-means-of-communication::placeholder,
.emploi-relation-employeur-employ-1-item::placeholder {
  color: #ffffff99;
}

.emploi-relation-employeur-employ-1-item {
  background-color: transparent;
  border: 0;
  color: var(--graye);
  font-weight: 400;
  height: 21px;
  line-height: normal;
  min-width: 605px;
  opacity: 0.5;
  padding: 0;
  position: relative;
  text-align: center;
}

.emploi-relation-employeur-employ-2-12 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 19px;
  position: relative;
  width: fit-content;
}

.do-operators-have-ac {
  background-color: transparent;
  border: 0;
  color: var(--white-lilac);
  font-weight: 400;
  height: 21px;
  line-height: normal;
  min-width: 630px;
  opacity: 0.5;
  padding: 0;
  position: relative;
  text-align: center;
}

.flex-row-151 {
  align-items: flex-start;
  display: flex;
  gap: 535px;
  height: 52px;
  margin-right: 26px;
  margin-top: 3px;
  min-width: 857px;
  position: relative;
}

.frame-112-9 {
  align-items: center;
  background-color: var(--rum-swizzle);
  border-radius: 40px;
  box-shadow: 0px 4px 11px #1e454945;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  margin-top: 1px;
  padding: 15px;
  position: relative;
  transition: all 0.2s ease-in;
  width: fit-content;
}

.frame-112-9:hover {
  transform: scale(1.1);
}

.lead-time-criteria {
  letter-spacing: 0.20px;
  line-height: 18px;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

