.references {
  align-items: center;
  background-color: var(--white);
  border: 1px none;
  display: flex;
  flex-direction: column;
  gap: 206px;
  height: 924px;
  width: 1440px;
}

.overlap-group9-4 {
  height: 609px;
  margin-left: 2.0px;
  position: relative;
  width: 1438px;
}

.overlap-group8-3 {
  height: 609px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1438px;
}

.rectangle-2-2 {
  background-color: var(--rum-swizzle);
  border-radius: 41px 0px 100px 118px;
  height: 190px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1438px;
}

.nav-items-2-55 {
  align-items: center;
  cursor: pointer;
  display: flex;
  gap: 60px;
  height: 48px;
  justify-content: center;
  left: 501px;
  position: absolute;
  top: 115px;
  transition: all 0.2s ease-in;
  width: 877px;
}

.nav-items-2-55:hover,
.nav-items-3-79:hover {
  transform: translate(0, -5px);
}

.frame-116-180 {
  align-items: center;
  background-color: var(--tropical-rain-forest);
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  padding: 15px;
  position: relative;
  transition: all 0.2s ease-in;
  width: fit-content;
}

.frame-116-180:hover,
.frame-2-30:hover {
  transform: scale(1.1);
}

.results-propositions {
  letter-spacing: 0.20px;
  line-height: 18px;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.nav-items-3-79 {
  align-items: center;
  cursor: pointer;
  display: flex;
  gap: 60px;
  height: 48px;
  justify-content: center;
  left: 589px;
  position: absolute;
  top: 29px;
  transition: all 0.2s ease-in;
  width: 795px;
}

.label-167 {
  cursor: pointer;
  letter-spacing: 0;
  line-height: 25.9px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.x1200px-logo_icam_-_2008-1-87 {
  cursor: pointer;
  height: 74px;
  left: 70px;
  object-fit: cover;
  position: absolute;
  top: 20px;
  width: 144px;
}

.frame-3-7 {
  height: 100px;
  left: 283px;
  position: absolute;
  top: 189px;
  width: 100px;
}

.overlap-group4-18 {
  height: 355px;
  left: 36px;
  position: absolute;
  top: 254px;
  width: 296px;
}

.rectangle-4359 {
  background-color: var(--rum-swizzle);
  border-radius: 15px;
  box-shadow: 0px 0px 36px #0000001a;
  height: 355px;
  left: 0;
  position: absolute;
  top: 0;
  width: 286px;
}

.x25-22 {
  background-color: transparent;
  border: 0;
  height: 60px;
  left: 44px;
  letter-spacing: 0;
  line-height: 60px;
  padding: 0;
  position: absolute;
  top: 75px;
  white-space: nowrap;
  width: 171px;
}

.x25-22::placeholder,
.x25-25::placeholder {
  color: #3e5d6099;
}

.tracbility {
  height: 15px;
  left: 61px;
  letter-spacing: 0.08px;
  line-height: 20px;
  position: absolute;
  top: 199px;
  white-space: nowrap;
  width: 227px;
}

.place-271 {
  left: 22px;
  letter-spacing: 0;
  line-height: 32px;
  position: absolute;
  top: 21px;
  white-space: nowrap;
  width: 250px;
}

.pricing-features {
  align-items: flex-end;
  display: flex;
  gap: 7px;
  height: 144px;
  left: 21px;
  min-width: 275px;
  position: absolute;
  top: 175px;
}

.overlap-group-container-16 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
  min-height: 144px;
  width: 16px;
}

.overlap-group1-52 {
  align-items: flex-start;
  background-color: var(--tropical-rain-forest);
  border-radius: 7.45px/7.56px;
  display: flex;
  height: 15px;
  justify-content: flex-end;
  margin-left: 1.34px;
  min-width: 15px;
  padding: 5.0px 4.0px;
}

.vector-3 {
  height: 5px;
  width: 7px;
}

.overlap-group2-26 {
  align-items: flex-end;
  background-color: var(--tropical-rain-forest);
  border-radius: 7.45px/7.56px;
  display: flex;
  height: 15px;
  margin-left: 1px;
  margin-top: 76px;
  min-width: 15px;
  padding: 4.7px 3.4px;
}

.overlap-group3-24 {
  align-items: flex-end;
  background-color: var(--tropical-rain-forest);
  border-radius: 7.45px/7.56px;
  display: flex;
  height: 15px;
  justify-content: flex-end;
  margin-top: 23px;
  min-width: 15px;
  padding: 4.7px 3.7px;
}

.flex-col-105 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
  min-height: 144px;
  width: 244px;
}

.product {
  height: 15px;
  letter-spacing: 0.08px;
  line-height: 20px;
  margin-bottom: -5px;
  margin-left: 1.43px;
  white-space: nowrap;
}

.validation {
  align-self: flex-end;
  height: 15px;
  letter-spacing: 0.08px;
  line-height: 20px;
  margin-bottom: -5px;
  margin-top: 37px;
  white-space: nowrap;
  width: 227px;
}

.iso-9001-isf {
  height: 15px;
  letter-spacing: 0.08px;
  line-height: 20px;
  margin-bottom: -5px;
  margin-left: 1px;
  margin-top: 20px;
  white-space: nowrap;
}

.overlap-group-90 {
  height: 15px;
  margin-top: 27px;
  position: relative;
  width: 228px;
}

.vector-37 {
  height: 5px;
  left: 99px;
  position: absolute;
  top: 3px;
  width: 7px;
}

.process-validation {
  height: 15px;
  left: 0;
  letter-spacing: 0.08px;
  line-height: 20px;
  position: absolute;
  top: 0;
  white-space: nowrap;
  width: 228px;
}

.line-21-9 {
  height: 1px;
  left: 22px;
  object-fit: cover;
  position: absolute;
  top: 142px;
  width: 231px;
}

.frame-6-14 {
  height: 100px;
  left: 284px;
  position: absolute;
  top: 481px;
  width: 100px;
}

.overlap-group5-15 {
  align-items: center;
  background: linear-gradient(180deg, rgb(0, 115, 103) 11.46%, rgb(0, 115, 103) 89.58%);
  border-radius: 15px;
  box-shadow: 0px 0px 36px #0000001a;
  display: flex;
  flex-direction: column;
  left: 390px;
  min-height: 355px;
  padding: 20.8px 5.5px;
  position: absolute;
  top: 254px;
  width: 288px;
}

.cost-3 {
  letter-spacing: 0;
  line-height: 32px;
  margin-bottom: -8px;
  margin-left: 9.0px;
  min-height: 24px;
  white-space: nowrap;
  width: 252px;
}

.x25-23 {
  background-color: transparent;
  border: 0;
  height: 60px;
  letter-spacing: 0;
  line-height: 60px;
  margin-left: 27.0px;
  margin-top: 30px;
  padding: 0;
  white-space: nowrap;
  width: 171px;
}

.x25-23::placeholder,
.x25-24::placeholder {
  color: #f7f6e699;
}

.line-21-10 {
  height: 1px;
  margin-right: 10.5px;
  margin-top: 7px;
  object-fit: cover;
  width: 232px;
}

.pricing-features-1 {
  align-items: flex-end;
  align-self: flex-end;
  display: flex;
  gap: 8px;
  height: 115px;
  margin-top: 33px;
  min-width: 260px;
}

.overlap-group-container-17 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 18px;
  margin-bottom: 0;
  min-height: 115px;
  width: 15px;
}

.overlap-group-87 {
  align-items: flex-end;
  background-color: var(--rum-swizzle);
  border-radius: 7.5px/7.56px;
  display: flex;
  height: 15px;
  min-width: 15px;
  padding: 5.0px 4.1px;
}

.overlap-group-88 {
  align-items: flex-start;
  background-color: var(--rum-swizzle);
  border-radius: 7.5px/7.56px;
  display: flex;
  height: 15px;
  min-width: 15px;
  padding: 5.0px 4.1px;
}

.cost-container {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 13px;
  margin-bottom: 0;
  min-height: 115px;
  width: 230px;
}

.transport-cost {
  height: 15px;
  letter-spacing: 0.08px;
  line-height: 20px;
  margin-left: 0.75px;
  white-space: nowrap;
}

.manufacturing-cost,
.delivery-cost,
.delivery-time {
  height: 15px;
  letter-spacing: 0.08px;
  line-height: 20px;
  white-space: nowrap;
}

.export-cost,
.storage-time {
  height: 15px;
  letter-spacing: 0.08px;
  line-height: 20px;
  margin-bottom: -5px;
  white-space: nowrap;
}

.overlap-group6-7 {
  align-items: flex-end;
  background: linear-gradient(180deg, rgb(0, 115, 103) 1.04%, rgb(0, 115, 103) 98.96%);
  border-radius: 15px;
  box-shadow: 0px 0px 36px #0000001a;
  display: flex;
  flex-direction: column;
  left: 1112px;
  min-height: 355px;
  padding: 20.8px 5.5px;
  position: absolute;
  top: 254px;
  width: 288px;
}

.modernisation {
  letter-spacing: 0;
  line-height: 32px;
  margin-bottom: -8px;
  margin-right: 8.0px;
  min-height: 24px;
  white-space: nowrap;
  width: 252px;
}

.x25-24 {
  align-self: flex-start;
  background-color: transparent;
  border: 0;
  height: 60px;
  letter-spacing: 0;
  line-height: 60px;
  margin-left: 24.5px;
  margin-top: 30px;
  padding: 0;
  white-space: nowrap;
  width: 171px;
}

.line-21-11 {
  align-self: center;
  height: 1px;
  margin-right: 10.5px;
  margin-top: 7px;
  object-fit: cover;
  width: 232px;
}

.pricing-features-2 {
  align-items: flex-start;
  display: flex;
  gap: 8px;
  height: 124px;
  margin-top: 33px;
  min-width: 260px;
}

.overlap-group-container-18 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 18px;
  min-height: 115px;
  width: 15px;
}

.flex-col-106 {
  align-items: flex-start;
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
  min-height: 124px;
  width: 230px;
}

.supply-chain-40 {
  height: 15px;
  letter-spacing: 0.08px;
  line-height: 20px;
  margin-bottom: -5px;
  margin-left: 0.75px;
  white-space: nowrap;
}

.flex-col-item {
  height: 15px;
  letter-spacing: 0.08px;
  line-height: 20px;
  margin-bottom: -5px;
  margin-top: 18px;
  white-space: nowrap;
}

.new-technologies-ai-robotics-etc {
  height: 15px;
  letter-spacing: 0.08px;
  line-height: 20px;
  margin-bottom: -5px;
  margin-top: 27px;
  white-space: nowrap;
}

.overlap-group7-5 {
  align-items: flex-end;
  background-color: var(--rum-swizzle);
  border-radius: 15px;
  box-shadow: 0px 0px 36px #0000001a;
  display: flex;
  flex-direction: column;
  left: 761px;
  min-height: 355px;
  padding: 20.8px 7.3px;
  position: absolute;
  top: 254px;
  width: 284px;
}

.lead-time-4 {
  letter-spacing: 0;
  line-height: 32px;
  margin-bottom: -8px;
  margin-right: 6.0px;
  min-height: 24px;
  white-space: nowrap;
  width: 248px;
}

.x25-25 {
  align-self: flex-start;
  background-color: transparent;
  border: 0;
  height: 60px;
  letter-spacing: 0;
  line-height: 60px;
  margin-left: 22.69px;
  margin-top: 30px;
  padding: 0;
  white-space: nowrap;
  width: 171px;
}

.line-21-12 {
  align-self: center;
  height: 1px;
  margin-right: 10.35px;
  margin-top: 7px;
  object-fit: cover;
  width: 229px;
}

.pricing-features-3 {
  align-items: flex-start;
  display: flex;
  gap: 7px;
  height: 82px;
  margin-top: 33px;
  min-width: 254px;
}

.overlap-group-container-19 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 18px;
  min-height: 82px;
  width: 15px;
}

.overlap-group2-27 {
  align-items: flex-start;
  background-color: var(--white);
  border-radius: 7.4px/7.56px;
  display: flex;
  height: 15px;
  min-width: 15px;
  padding: 5.0px 4.0px;
}

.overlap-group-89 {
  align-items: flex-end;
  background-color: var(--white);
  border-radius: 7.4px/7.56px;
  display: flex;
  height: 15px;
  min-width: 15px;
  padding: 5.0px 4.0px;
}

.time-container {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 13px;
  margin-top: 0;
  min-height: 82px;
  width: 226px;
}

.manufacturing-time {
  height: 15px;
  letter-spacing: 0.08px;
  line-height: 20px;
  margin-left: 0.74px;
  white-space: nowrap;
}

.frame-5-24 {
  align-items: center;
  background-color: var(--rum-swizzle);
  border-radius: 6px;
  box-shadow: 0px 4px 11px #1e454945;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 30px;
  justify-content: center;
  left: 31px;
  padding: 15px;
  position: absolute;
  top: 216px;
  width: 78px;
}

.place-272 {
  letter-spacing: 0.20px;
  line-height: 18px;
  margin-bottom: -8.00px;
  margin-top: -10.00px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.frame-2-30 {
  align-items: center;
  background-color: var(--rum-swizzle);
  border-radius: 6px;
  box-shadow: 0px 4px 11px #1e454945;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 47px;
  justify-content: center;
  padding: 15px;
  position: relative;
  transition: all 0.2s ease;
  width: 156px;
}

.propositions-1 {
  letter-spacing: 0.20px;
  line-height: 18px;
  margin-top: -1.50px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

