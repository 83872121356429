.home {
  align-items: flex-start;
  background-color: var(--white);
  border: 1px none;
  cursor: pointer;
  display: flex;
  width: 1530px;
}

.flex-row-4 {
  align-items: flex-start;
  background-color: var(--rum-swizzle);
  border-radius: 0px 0px 66px 59px;
  display: flex;
  gap: 9px;
  height: 900px;
  min-width: 1530px;
  padding: 20px 49px;
}

.flex-col-2 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  min-height: 611px;
  width: 395px;
}

.x1200px-logo_icam_-_2008-1-1 {
  cursor: pointer;
  height: 74px;
  margin-left: 23px;
  object-fit: cover;
  width: 144px;
}

.overlap-group2-1 {
  height: 267px;
  margin-top: 162px;
  position: relative;
  width: 395px;
}

.take-your-company-to-the-next-level {
  color: var(--eerie-black);
  font-family: var(--font-family-inter);
  font-size: 48px;
  font-weight: 800;
  height: 226px;
  left: 8px;
  letter-spacing: 0;
  line-height: 60.8px;
  position: absolute;
  top: 0;
  width: 387px;
  padding-bottom: 40px;
}

.discover-from-differ {
  padding-top: 40px;
  color: var(--eerie-black);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-xxl);
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 32px;
  position: absolute;
  top: 203px;
  width: 357px;
}

.frame-2-1 {
  align-items: center;
  align-self: center;
  background-color: var(--white);
  border-radius: 6px;
  box-shadow: 0px 4px 11px #1e454945;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  margin-right: 111px;
  margin-top: 52px;
  padding: 15px;
  position: relative;
  transition: all 0.2s ease-in;
  width: 160px;
}

.frame-2-1:hover,
.frame-3:hover,
.new-case:hover,
.group-80:hover,
.existing-case-62:hover,
.frame-3-1:hover,
.reference-case:hover {
  transform: scale(1.1);
}

.flex-col-3 {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  gap: 150px;
  margin-top: 10px;
  min-height: 754px;
  width: 1021px;
}

.nav-items-3 {
  align-items: center;
  cursor: pointer;
  display: flex;
  gap: 60px;
  height: 48px;
  justify-content: center;
  margin-right: 7px;
  position: relative;
  transition: all 0.2s ease-in;
  width: 851px;
}

.nav-items-3:hover {
  transform: translate(0, -5px);
}

.overlap-group-container {
  align-items: center;
  display: flex;
  min-width: 1021px;
}

.overlap-group1-18 {
  height: 556px;
  position: relative;
  width: 334px;
}

.frame-3 {
  cursor: pointer;
  display: flex;
  height: 556px;
  left: 0;
  position: absolute;
  top: 0;
  transition: all 0.2s ease-in;
  width: 334px;
}

.new-case {
  -webkit-text-stroke: 3px var(--rum-swizzle);
  cursor: pointer;
  height: 26px;
  left: 17px;
  letter-spacing: 0;
  line-height: 25.9px;
  position: absolute;
  text-align: center;
  top: 406px;
  transition: all 0.2s ease-in;
  white-space: nowrap;
}

.overlap-group4-1 {
  height: 548px;
  margin-left: 3px;
  margin-top: 4px;
  position: relative;
  width: 350px;
}

.group-79 {
  cursor: pointer;
  display: flex;
  height: 547px;
  left: 0;
  position: absolute;
  top: 1px;
  width: 347px;
}

.group-80 {
  cursor: pointer;
  display: flex;
  height: 543px;
  left: 3px;
  position: absolute;
  top: 0;
  transition: all 0.2s ease-in;
  width: 347px;
}

.existing-case-62 {
  -webkit-text-stroke: 3px var(--rum-swizzle);
  cursor: pointer;
  height: 52px;
  left: 23px;
  letter-spacing: 0;
  line-height: 25.9px;
  position: absolute;
  text-align: center;
  top: 395px;
  transition: all 0.2s ease-in;
}

.overlap-group3-1 {
  height: 541px;
  margin-bottom: 1px;
  position: relative;
  width: 334px;
}

.frame-3-1::-webkit-scrollbar {
  display: none;
  width: 0;
}

.frame-3-1 {
  cursor: pointer;
  display: flex;
  height: 541px;
  left: 0;
  overflow-x: scroll;
  position: absolute;
  top: 0;
  transition: all 0.2s ease-in;
  width: 334px;
}

.reference-case {
  -webkit-text-stroke: 3px var(--rum-swizzle);
  cursor: pointer;
  height: 26px;
  left: 44px;
  letter-spacing: 0;
  line-height: 25.9px;
  position: absolute;
  text-align: center;
  top: 399px;
  transition: all 0.2s ease-in;
  white-space: nowrap;
}
