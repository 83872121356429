.social-6 {
  align-items: flex-end;
  background-color: var(--white);
  border: 1px none;
  display: flex;
  flex-direction: column;
  height: 900px;
  overflow: hidden;
  width: 1530px;
}

.overlap-group-82 {
  align-items: flex-start;
  background-color: var(--rum-swizzle);
  border-radius: 41px 0px 100px 118px;
  display: flex;
  gap: 329px;
  height: 180px;
  justify-content: flex-end;
  margin-right: -1px;
  min-width: 1526px;
  padding: 20px 60px;
}

.x1200px-logo_icam_-_2008-1-82 {
  cursor: pointer;
  height: 74px;
  object-fit: cover;
  width: 144px;
}

.nav-items-container-48 {
  align-items: flex-end;
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-bottom: 2px;
  min-height: 128px;
  position: relative;
  width: 926px;
}

.developpement-et-capital-humain-2 {
  align-items: flex-start;
  align-self: center;
  display: flex;
  gap: 20px;
  justify-content: center;
  margin-left: 13.0px;
  margin-top: 107px;
  padding: 33px 0px;
  position: relative;
  width: fit-content;
}

.developpement-et-capital-humain-1-1 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  width: fit-content;
}

.group-107-3 {
  height: 387px;
  margin-right: -6.00px;
  min-width: 450px;
  position: relative;
}

.flex-col-103 {
  align-items: center;
  display: flex;
  flex-direction: column;
  min-height: 387px;
  position: relative;
  width: 444px;
}

.do-you-maintain-a-hi {
  background-color: transparent;
  border: 0;
  color: var(--white-lilac);
  font-weight: 400;
  height: 121px;
  line-height: normal;
  margin-top: 19px;
  opacity: 0.5;
  padding: 0;
  text-align: center;
  width: 444px;
}

.do-you-maintain-a-hi::placeholder,
.do-you-prevent-and-p::placeholder,
.do-you-have-a-work-e::placeholder {
  color: #f7f9fb99;
}

.are-staff-trained-in {
  background-color: transparent;
  border: 0;
  color: var(--graye);
  font-weight: 400;
  height: 22px;
  line-height: normal;
  margin-top: 19px;
  opacity: 0.5;
  padding: 0;
  text-align: center;
  width: 406px;
}

.are-staff-trained-in::placeholder,
.is-there-a-budget-for-health-and-safety::placeholder,
.developpement-et-capital-humain-2-item::placeholder {
  color: #ffffff99;
}

.is-there-a-budget-for-health-and-safety {
  background-color: transparent;
  border: 0;
  color: var(--graye);
  font-weight: 400;
  height: 25px;
  line-height: normal;
  margin-top: 17px;
  opacity: 0.5;
  padding: 0;
  text-align: center;
  width: 406px;
}

.developpement-et-capital-humain-2-1 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 12px;
  position: relative;
  width: fit-content;
}

.do-you-prevent-and-p {
  background-color: transparent;
  border: 0;
  color: var(--white-lilac);
  font-weight: 400;
  height: 44px;
  line-height: normal;
  min-width: 460px;
  opacity: 0.5;
  padding: 0;
  position: relative;
  text-align: center;
}

.developpement-et-capital-humain-2-item {
  background-color: transparent;
  border: 0;
  color: var(--graye);
  font-weight: 400;
  height: 43.61px;
  line-height: normal;
  min-width: 516px;
  opacity: 0.5;
  padding: 0;
  position: relative;
  text-align: center;
}

.developpement-et-capital-humain-3-2 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 2px;
  position: relative;
  width: fit-content;
}

.do-you-have-a-work-e {
  background-color: transparent;
  border: 0;
  color: var(--white-lilac);
  font-weight: 400;
  height: 107px;
  line-height: normal;
  min-width: 473px;
  opacity: 0.5;
  padding: 0;
  position: relative;
  text-align: center;
}

.flex-row-149 {
  align-items: flex-start;
  display: flex;
  gap: 467px;
  height: 62px;
  margin-right: 66px;
  margin-top: 75px;
  min-width: 797px;
  position: relative;
}

.frame-112-8 {
  align-items: center;
  background-color: var(--rum-swizzle);
  border-radius: 40px;
  box-shadow: 0px 4px 11px #1e454945;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  padding: 15px;
  position: relative;
  transition: all 0.2s ease-in;
  width: fit-content;
}

.frame-112-8:hover {
  transform: scale(1.1);
}

.modernization-criteria {
  letter-spacing: 0.20px;
  line-height: 18px;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

