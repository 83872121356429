.kaizen-1-1 {
  align-items: flex-start;
  background-color: var(--white);
  border: 1px none;
  display: flex;
  flex-direction: column;
  gap: 29px;
  height: 924px;
  width: 1440px;
}

.overlap-group7-2 {
  align-items: flex-start;
  background-color: var(--rum-swizzle);
  border-radius: 41px 0px 100px 118px;
  display: flex;
  gap: 195px;
  height: 190px;
  justify-content: flex-end;
  min-width: 1440px;
  padding: 20px 18px;
}

.x1200px-logo_icam_-_2008-1-54 {
  cursor: pointer;
  height: 74px;
  object-fit: cover;
  width: 144px;
}

.nav-items-container-24 {
  align-items: center;
  align-self: center;
  display: flex;
  flex-direction: column;
  gap: 35px;
  margin-top: 9.0px;
  min-height: 131px;
  width: 1011px;
}

.nav-items-2-36 {
  align-items: center;
  cursor: pointer;
  display: flex;
  gap: 60px;
  height: 48px;
  justify-content: center;
  position: relative;
  transition: all 0.2s ease-in;
  width: 1011px;
}

.nav-items-2-36:hover,
.nav-items-3-68:hover {
  transform: translate(0, -5px);
}

.nav-items-3-68 {
  align-items: center;
  cursor: pointer;
  display: flex;
  gap: 60px;
  height: 48px;
  justify-content: center;
  margin-left: 5.0px;
  position: relative;
  transition: all 0.2s ease-in;
  width: 940px;
}

.flex-row-95 {
  align-items: center;
  display: flex;
  gap: 13px;
  margin-left: 16px;
  min-width: 1308px;
}

.flex-col-53 {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 20.14px;
  min-height: 606px;
  width: 136px;
}

.overlap-group-container-7 {
  align-items: flex-start;
  align-self: flex-end;
  display: flex;
  gap: 6px;
  height: 88px;
  min-width: 121px;
}

.overlap-group11 {
  align-items: flex-start;
  background-size: 100% 100%;
  display: flex;
  min-width: 55px;
}

.arrow-9-8 {
  height: 87px;
  width: 55px;
}

.overlap-group4-10 {
  align-items: flex-start;
  align-self: flex-end;
  background-size: 100% 100%;
  display: flex;
  min-width: 60px;
}

.arrow-10-3 {
  height: 87px;
  width: 60px;
}

.overlap-group2-13 {
  align-items: flex-start;
  background-color: var(--rum-swizzle);
  border-radius: 36px/35.5px;
  display: flex;
  margin-right: 12.27px;
  margin-top: 39px;
  min-width: 72px;
}

.ellipse-85-9 {
  background-color: var(--rum-swizzle);
  border-radius: 36px/35.5px;
  height: 71px;
  width: 72px;
}

.overlap-group13-2 {
  align-items: flex-start;
  align-self: flex-start;
  background-color: var(--rum-swizzle);
  display: flex;
  margin-left: 8px;
  margin-top: 40px;
  min-width: 99px;
}

.rectangle-9-1 {
  background-color: var(--rum-swizzle);
  height: 47px;
  width: 99px;
}

.overlap-group9 {
  align-items: flex-start;
  align-self: flex-start;
  background-color: var(--rum-swizzle);
  border-radius: 6px;
  box-shadow: 0px 4px 11px #1e454945;
  display: flex;
  margin-left: 9px;
  margin-top: 44px;
  min-width: 99px;
}

.frame-5-14 {
  background-color: var(--rum-swizzle);
  border-radius: 6px;
  box-shadow: 0px 4px 11px #1e454945;
  height: 47px;
  width: 99px;
}

.overlap-group5-8 {
  align-self: flex-start;
  height: 15px;
  margin-top: 44px;
  position: relative;
  width: 117px;
}

.line {
  height: 1px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 7px;
  width: 117px;
}

.ellipse-8 {
  background-color: var(--alto);
  border-radius: 9.5px/7.5px;
  height: 15px;
  left: 22px;
  position: absolute;
  top: 0;
  width: 19px;
}

.label-container {
  height: 26px;
  margin-right: 30.27px;
  margin-top: 7px;
  position: relative;
  width: 50px;
}

.label-130 {
  left: 0;
  letter-spacing: 0;
  line-height: 25.9px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.overlap-group8 {
  align-items: flex-start;
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  margin-right: 15.27px;
  margin-top: 33px;
  min-width: 69px;
}

.text-format-13 {
  height: 55px;
  object-fit: cover;
  width: 69px;
}

.label-container-1 {
  height: 26px;
  margin-right: 12.27px;
  margin-top: 23px;
  position: relative;
  width: 38px;
}

.flex-row-96 {
  align-items: flex-start;
  display: flex;
  gap: 12px;
  height: 668px;
  min-width: 1158px;
}

.overlap-group17 {
  align-items: flex-start;
  align-self: flex-end;
  background-size: 100% 100%;
  display: flex;
  min-width: 11px;
}

.line-1-13 {
  height: 640px;
  width: 11px;
}

.flex-col-54 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 38px;
  min-height: 668px;
  width: 1136px;
}

.flex-row-97 {
  align-items: flex-start;
  display: flex;
  gap: 227px;
  height: 58px;
  min-width: 875px;
}

.frame-13-2 {
  align-items: center;
  align-self: flex-end;
  background-color: var(--rum-swizzle);
  border-radius: 6px;
  box-shadow: 0px 4px 11px #1e454945;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 30px;
  justify-content: center;
  padding: 15px;
  position: relative;
  width: 78px;
}

.place-252 {
  letter-spacing: 0.20px;
  line-height: 18px;
  margin-bottom: -8.00px;
  margin-top: -10.00px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.click-on-modeling-11 {
  height: 48px;
  letter-spacing: 0;
  line-height: normal;
  text-align: center;
  width: 570px;
}

.flex-row-98 {
  align-items: center;
  align-self: flex-end;
  display: flex;
  gap: 14px;
  height: 572px;
  min-width: 1065px;
}

.flex-col-55 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 21px;
  margin-bottom: 49.0px;
  min-height: 423px;
  position: relative;
  width: 120px;
}

.overlap-group1-35 {
  align-items: flex-start;
  background-color: var(--rum-swizzle);
  display: flex;
  height: 376px;
  justify-content: flex-end;
  margin-right: 1.0px;
  min-width: 105px;
  padding: 173px 20px;
}

.input {
  background-color: transparent;
  border: 0;
  height: 20px;
  letter-spacing: 0.22px;
  line-height: 20px;
  padding: 0;
  text-align: center;
  white-space: nowrap;
  width: 63px;
}

.input::placeholder,
.plan::placeholder,
.act::placeholder,
.do::placeholder,
.check::placeholder,
.output::placeholder {
  color: #3e5d6099;
}

.flex-col-56 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 2px;
  min-height: 572px;
  width: 811px;
}

.flex-row-99 {
  align-items: center;
  display: flex;
  height: 190px;
  margin-left: 1.0px;
  min-width: 810px;
}

.arrow-5-8 {
  height: 22px;
  margin-top: 10.36px;
  width: 137px;
}

.arrow-1-11 {
  align-self: flex-end;
  height: 152px;
  margin-bottom: 3.5px;
  margin-left: 12px;
  width: 70px;
}

.flex-col-57 {
  align-items: center;
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  gap: 11px;
  margin-left: 4px;
  min-height: 179px;
  position: relative;
  width: 316px;
}

.overlap-group15-1 {
  align-items: flex-start;
  background-color: var(--rum-swizzle);
  display: flex;
  height: 90px;
  min-width: 316px;
  padding: 30px 124px;
}

.plan {
  background-color: transparent;
  border: 0;
  height: 24px;
  letter-spacing: 0.26px;
  line-height: 24px;
  padding: 0;
  text-align: center;
  white-space: nowrap;
  width: 66px;
}

.arrow-3-8 {
  align-self: flex-end;
  height: 146px;
  margin-left: 6px;
  width: 95px;
}

.arrow-7-7 {
  height: 22px;
  margin-left: 32px;
  margin-top: 12.36px;
  width: 137px;
}

.flex-col-58 {
  align-items: center;
  display: flex;
  flex-direction: column;
  min-height: 380px;
  width: 811px;
}

.overlap-group-container-8 {
  align-items: flex-start;
  display: flex;
  gap: 170px;
  margin-right: 15.49px;
  min-width: 753px;
}

.overlap-group6-4 {
  align-items: flex-start;
  background-color: var(--rum-swizzle);
  display: flex;
  height: 97px;
  min-width: 294px;
  padding: 34px 108px;
}

.act {
  background-color: transparent;
  border: 0;
  height: 24px;
  letter-spacing: 0.26px;
  line-height: 24px;
  padding: 0;
  text-align: center;
  white-space: nowrap;
  width: 52px;
}

.overlap-group-55 {
  align-items: flex-start;
  background-color: var(--rum-swizzle);
  display: flex;
  height: 97px;
  justify-content: flex-end;
  min-width: 289px;
  padding: 36px 125px;
}

.do {
  background-color: transparent;
  border: 0;
  height: 24px;
  letter-spacing: 0.26px;
  line-height: 24px;
  padding: 0;
  text-align: center;
  white-space: nowrap;
  width: 37px;
}

.flex-row-100 {
  align-items: flex-end;
  display: flex;
  height: 283px;
  min-width: 811px;
}

.flex-col-59 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 6px;
  min-height: 279px;
  width: 540px;
}

.overlap-group-container-9 {
  height: 226px;
  position: relative;
  width: 540px;
}

.overlap-group16-2 {
  height: 136px;
  left: 224px;
  position: absolute;
  top: 90px;
  width: 316px;
}

.rectangle-6-4 {
  background-color: var(--rum-swizzle);
  height: 89px;
  left: 0;
  position: absolute;
  top: 0;
  width: 316px;
}

.check {
  background-color: transparent;
  border: 0;
  height: 24px;
  left: 115px;
  letter-spacing: 0.26px;
  line-height: 24px;
  padding: 0;
  position: absolute;
  text-align: center;
  top: 32px;
  white-space: nowrap;
  width: 87px;
}

.overlap-group12-2 {
  height: 132px;
  left: 0;
  position: absolute;
  top: 0;
  width: 265px;
}

.arrow-4-8 {
  height: 132px;
  left: 136px;
  position: absolute;
  top: 0;
  width: 83px;
}

.arrow-6-10 {
  height: 22px;
  left: 0;
  position: absolute;
  top: 77px;
  width: 137px;
}

.frame-13-3 {
  align-items: center;
  background-color: var(--rum-swizzle);
  border-radius: 6px;
  box-shadow: 0px 4px 11px #1e454945;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 47px;
  justify-content: center;
  margin-left: 51.01px;
  padding: 15px;
  position: relative;
  transition: all 0.2s ease-in;
  width: 156px;
}

.frame-13-3:hover {
  transform: scale(1.1);
}

.modeling-101 {
  cursor: pointer;
  letter-spacing: 0.20px;
  line-height: 18px;
  margin-top: -1.50px;
  position: relative;
  text-align: center;
  text-decoration: underline;
  white-space: nowrap;
  width: fit-content;
}

.overlap-group3-12 {
  align-self: flex-start;
  height: 158px;
  margin-left: 2px;
  position: relative;
  width: 89px;
}

.arrow-2-10 {
  height: 158px;
  left: 0;
  position: absolute;
  top: 0;
  width: 89px;
}

.flex-col-60 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 132px;
  margin-left: 15px;
  min-height: 201px;
  width: 164px;
}

.arrow-8-9 {
  align-self: flex-end;
  height: 22px;
  width: 137px;
}

.frame-2-19 {
  align-items: center;
  background-color: var(--rum-swizzle);
  border-radius: 6px;
  box-shadow: 0px 4px 11px #1e454945;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 47px;
  justify-content: center;
  padding: 15px;
  position: relative;
  width: 156px;
}

.continue-12 {
  letter-spacing: 0.20px;
  line-height: 18px;
  margin-top: -1.50px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.flex-col-61 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 21px;
  margin-bottom: 49.0px;
  min-height: 423px;
  position: relative;
  width: 106px;
}

.overlap-group18 {
  align-items: flex-start;
  background-color: var(--rum-swizzle);
  display: flex;
  height: 376px;
  min-width: 106px;
  padding: 161px 9px;
}

.output {
  background-color: transparent;
  border: 0;
  height: 20px;
  letter-spacing: 0.22px;
  line-height: 20px;
  padding: 0;
  text-align: center;
  white-space: nowrap;
  width: 86px;
}

