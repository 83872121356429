.frame-5-3,
.frame-5-4,
.frame-5-5,
.frame-5-6 {
  align-items: center;
  background-color: var(--rum-swizzle);
  border-radius: 6px;
  box-shadow: 0px 4px 11px #1e454945;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 77px;
  justify-content: center;
  left: 98px;
  padding: 15px;
  position: absolute;
  top: 0;
  width: 146px;
}

.manufacturing,
.manufacturing-1,
.manufacturing-2,
.manufacturing-3 {
  letter-spacing: 0.20px;
  line-height: 18px;
  margin-left: -8.50px;
  margin-right: -8.50px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

