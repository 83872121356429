.lean-vsm-8 {
  background-color: var(--white);
  border: 1px none;
  height: 924px;
  position: relative;
  width: 1440px;
}

.overlap-group2-15 {
  align-items: flex-start;
  background-color: var(--rum-swizzle);
  border-radius: 41px 0px 100px 118px;
  display: flex;
  gap: 195px;
  height: 190px;
  justify-content: flex-end;
  left: 0;
  min-width: 1440px;
  padding: 20px 18px;
  position: absolute;
  top: 0;
}

.x1200px-logo_icam_-_2008-1-57 {
  cursor: pointer;
  height: 74px;
  object-fit: cover;
  width: 144px;
}

.nav-items-container-27 {
  align-items: center;
  align-self: center;
  display: flex;
  flex-direction: column;
  gap: 31px;
  margin-top: 5.0px;
  min-height: 127px;
  width: 1011px;
}

.nav-items-2-39 {
  align-items: center;
  cursor: pointer;
  display: flex;
  gap: 60px;
  height: 48px;
  justify-content: center;
  position: relative;
  transition: all 0.2s ease-in;
  width: 1011px;
}

.nav-items-2-39:hover,
.nav-items-3-70:hover {
  transform: translate(0, -5px);
}

.frame-117-97 {
  align-items: center;
  border-radius: 6px;
  box-shadow: 0px 4px 4px #00000040;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  padding: 15px;
  position: relative;
  transition: all 0.2s ease-in;
  width: fit-content;
}

.frame-117-97:hover,
.frame-113-60:hover {
  transform: scale(1.1);
}

.design-78,
.lean-vsm-9 {
  letter-spacing: 0.20px;
  line-height: 18px;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.nav-items-3-70 {
  align-items: center;
  cursor: pointer;
  display: flex;
  gap: 60px;
  height: 48px;
  justify-content: center;
  margin-left: 7.0px;
  position: relative;
  transition: all 0.2s ease-in;
  width: 940px;
}

.frame-113-60 {
  align-items: center;
  background-color: var(--tropical-rain-forest);
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  margin-left: -2.00px;
  padding: 15px;
  position: relative;
  transition: all 0.2s ease-in;
  width: fit-content;
}

.flex-row-105 {
  align-items: flex-start;
  display: flex;
  height: 491px;
  left: 644px;
  min-width: 784px;
  position: absolute;
  top: 395px;
}

.arrow-4-9 {
  height: 22px;
  margin-top: 77.95px;
  width: 66px;
}

.overlap-group3-14 {
  align-items: flex-end;
  background-color: var(--rum-swizzle);
  border-radius: 90.5px/86px;
  display: flex;
  height: 172px;
  margin-left: 2px;
  min-width: 181px;
  padding: 71.0px 48px;
  position: relative;
}

.arrow-6-11 {
  height: 22px;
  margin-top: 74.95px;
  width: 66px;
}

.flex-col-66 {
  align-items: flex-start;
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  gap: 298px;
  margin-left: 2px;
  min-height: 464px;
  width: 467px;
}

.overlap-group1-37 {
  height: 119px;
  position: relative;
  width: 467px;
}

.rectangle-5-4 {
  background-color: var(--rum-swizzle);
  height: 115px;
  left: 0;
  position: absolute;
  top: 3px;
  width: 193px;
}

.arrow-5-9 {
  height: 22px;
  left: 193px;
  position: absolute;
  top: 51px;
  width: 91px;
}

.rectangle-7-4 {
  height: 119px;
  left: 282px;
  position: absolute;
  top: 0;
  width: 185px;
}

.frame-2-20 {
  align-items: center;
  background-color: var(--rum-swizzle);
  border-radius: 6px;
  box-shadow: 0px 4px 11px #1e454945;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 47px;
  justify-content: center;
  margin-left: 6px;
  padding: 15px;
  position: relative;
  width: 156px;
}

.continue-14 {
  letter-spacing: 0.20px;
  line-height: 18px;
  margin-top: -1.50px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.flex-row-106 {
  align-items: center;
  display: flex;
  gap: 13px;
  height: 640px;
  left: 16px;
  min-width: 160px;
  position: absolute;
  top: 243px;
}

.flex-col-67 {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 7.87px;
  min-height: 606px;
  width: 136px;
}

.arrow-container-15 {
  align-items: flex-start;
  align-self: flex-end;
  display: flex;
  gap: 6px;
  height: 88px;
  min-width: 121px;
}

.arrow-1-12 {
  height: 87px;
  width: 55px;
}

.arrow-2-11 {
  align-self: flex-end;
  height: 87px;
  width: 60px;
}

.ellipse-88-2 {
  background-color: var(--rum-swizzle);
  border-radius: 36px/35.5px;
  height: 71px;
  margin-right: 16.27px;
  margin-top: 42px;
  width: 72px;
}

.rectangle-3-24 {
  align-self: flex-start;
  background-color: var(--rum-swizzle);
  height: 47px;
  margin-left: 8px;
  margin-top: 37px;
  width: 99px;
}

.rectangle-6-5 {
  align-self: flex-start;
  height: 42px;
  margin-left: 6px;
  margin-top: 44px;
  width: 112px;
}

.overlap-group-58 {
  align-self: flex-start;
  height: 15px;
  margin-top: 49px;
  position: relative;
  width: 117px;
}

.line-2-14 {
  height: 1px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 7px;
  width: 117px;
}

.ellipse-86-14 {
  background-color: var(--alto);
  border-radius: 9.5px/7.5px;
  height: 15px;
  left: 22px;
  position: absolute;
  top: 0;
  width: 19px;
}

.label-133 {
  letter-spacing: 0;
  line-height: 25.9px;
  margin-right: 30.27px;
  margin-top: 7px;
  min-height: 26px;
  min-width: 50px;
  white-space: nowrap;
}

.text-format-15 {
  height: 55px;
  margin-right: 15.27px;
  margin-top: 33px;
  object-fit: cover;
  width: 69px;
}

.label-134 {
  letter-spacing: 0;
  line-height: 25.9px;
  margin-right: 12.27px;
  margin-top: 23px;
  min-height: 26px;
  min-width: 38px;
  white-space: nowrap;
}

.line-1-14 {
  height: 640px;
  width: 11px;
}

.overlap-group4-11 {
  align-items: flex-start;
  background-color: var(--rum-swizzle);
  display: flex;
  height: 118px;
  justify-content: flex-end;
  left: 450px;
  min-width: 194px;
  padding: 45px 36px;
  position: absolute;
  top: 425px;
}

.flex-col-68 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 147px;
  left: 188px;
  min-height: 355px;
  position: absolute;
  top: 218px;
  width: 260px;
}

.frame-6-9 {
  align-items: center;
  background-color: var(--rum-swizzle);
  border-radius: 6px;
  box-shadow: 0px 4px 11px #1e454945;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 30px;
  justify-content: center;
  margin-left: 12px;
  padding: 15px;
  position: relative;
  width: 78px;
}

.place-257 {
  letter-spacing: 0.20px;
  line-height: 18px;
  margin-bottom: -8.00px;
  margin-top: -10.00px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.overlap-group5-9 {
  height: 178px;
  position: relative;
  width: 260px;
}

.arrow-3-9 {
  height: 22px;
  left: 187px;
  position: absolute;
  top: 77px;
  width: 74px;
}

.ellipse-87-2 {
  background-color: var(--rum-swizzle);
  border-radius: 92px/89px;
  height: 178px;
  left: 0;
  position: absolute;
  top: 0;
  width: 184px;
}

.frame-10-4 {
  align-items: center;
  background-color: var(--rum-swizzle);
  border-radius: 6px;
  box-shadow: 0px 4px 11px #1e454945;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 47px;
  justify-content: center;
  left: 418px;
  padding: 15px;
  position: absolute;
  top: 839px;
  width: 156px;
}

.modeling-103 {
  cursor: pointer;
  letter-spacing: 0.20px;
  line-height: 18px;
  margin-top: -1.50px;
  position: relative;
  text-align: center;
  text-decoration: underline;
  white-space: nowrap;
  width: fit-content;
}

.click-on-modeling-13 {
  height: 48px;
  left: 508px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 247px;
  width: 570px;
}

