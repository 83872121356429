.table-number-primary-key-2,
.table-number-primary-key-3 {
  display: flex;
  height: 24px;
  left: 0;
  position: absolute;
  top: 40px;
  width: 272px;
}

