.lead-time-2 {
  align-items: flex-end;
  background-color: var(--white);
  border: 1px none;
  display: flex;
  flex-direction: column;
  height: 900px;
  width: 1530px;
}

.overlap-group-39 {
  align-items: flex-start;
  background-color: var(--rum-swizzle);
  border-radius: 41px 0px 100px 118px;
  display: flex;
  gap: 329px;
  height: 180px;
  justify-content: flex-end;
  min-width: 1530px;
  padding: 20px 59px;
}

.x1200px-logo_icam_-_2008-1-38 {
  cursor: pointer;
  height: 74px;
  object-fit: cover;
  width: 144px;
}

.nav-items-container-17 {
  align-items: flex-end;
  align-self: center;
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-top: 6.0px;
  min-height: 128px;
  position: relative;
  width: 926px;
}

.emploirelation-employeur-employ-8 {
  align-items: center;
  align-self: center;
  display: flex;
  gap: 88px;
  justify-content: center;
  margin-left: 44.0px;
  margin-top: 119px;
  position: relative;
  width: fit-content;
}

.emploi-relation-employeur-employ-1-6 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 14px;
  height: 341px;
  position: relative;
  width: 410px;
}

.are-you-experiencing {
  background-color: transparent;
  border: 0;
  color: var(--white-lilac);
  font-weight: 400;
  height: 35px;
  line-height: normal;
  margin-left: -5.00px;
  margin-right: -5.00px;
  min-width: 420px;
  opacity: 0.5;
  padding: 0;
  position: relative;
  text-align: center;
}

.are-you-experiencing::placeholder,
.do-you-have-unsold-products::placeholder,
.do-you-have-international-customers::placeholder {
  color: #f7f9fb99;
}

.is-it-physical-serv {
  background-color: transparent;
  border: 0;
  color: var(--graye);
  font-weight: 400;
  height: 51px;
  line-height: normal;
  margin-left: -4.50px;
  margin-right: -4.50px;
  min-width: 419px;
  opacity: 0.5;
  padding: 0;
  position: relative;
  text-align: center;
}

.is-it-physical-serv::placeholder,
.have-value-added-and::placeholder,
.how-long-on-average::placeholder,
.in-the-clou-do-you-h::placeholder,
.if-so-do-you-have-bu::placeholder,
.emploi-relation-employeur-employ-2-item::placeholder {
  color: #ffffff99;
}

.have-value-added-and {
  background-color: transparent;
  border: 0;
  color: var(--graye);
  font-weight: 400;
  height: 24px;
  line-height: normal;
  min-width: 402px;
  opacity: 0.5;
  padding: 0;
  position: relative;
  text-align: center;
}

.emploi-relation-employeur-employ-3-3 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 14px;
  height: 331px;
  position: relative;
  width: 329px;
}

.do-you-have-unsold-products {
  background-color: transparent;
  border: 0;
  color: var(--white-lilac);
  font-weight: 400;
  height: 35px;
  line-height: normal;
  min-width: 269px;
  opacity: 0.5;
  padding: 0;
  position: relative;
  text-align: center;
}

.how-long-on-average {
  background-color: transparent;
  border: 0;
  color: var(--graye);
  font-weight: 400;
  height: 21px;
  line-height: normal;
  margin-left: -24.50px;
  margin-right: -24.50px;
  min-width: 378px;
  opacity: 0.5;
  padding: 0;
  position: relative;
  text-align: center;
}

.in-the-clou-do-you-h {
  background-color: transparent;
  border: 0;
  color: var(--graye);
  font-weight: 400;
  height: 36px;
  line-height: normal;
  margin-left: -2.00px;
  margin-right: -2.00px;
  min-width: 333px;
  opacity: 0.5;
  padding: 0;
  position: relative;
  text-align: center;
}

.emploi-relation-employeur-employ-2-7 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 19px;
  height: 406px;
  position: relative;
  width: 517px;
}

.do-you-have-international-customers {
  background-color: transparent;
  border: 0;
  color: var(--white-lilac);
  font-weight: 400;
  height: 21px;
  line-height: normal;
  margin-left: -56.50px;
  margin-right: -56.50px;
  min-width: 630px;
  opacity: 0.5;
  padding: 0;
  position: relative;
  text-align: center;
}

.if-so-do-you-have-bu {
  background-color: transparent;
  border: 0;
  color: var(--graye);
  font-weight: 400;
  height: 21px;
  line-height: normal;
  min-width: 305px;
  opacity: 0.5;
  padding: 0;
  position: relative;
  text-align: center;
}

.emploi-relation-employeur-employ-2-item {
  background-color: transparent;
  border: 0;
  color: var(--graye);
  font-weight: 400;
  height: 21px;
  line-height: normal;
  margin-left: -33.00px;
  margin-right: -33.00px;
  min-width: 583px;
  opacity: 0.5;
  padding: 0;
  position: relative;
  text-align: center;
}

.flex-row-86 {
  align-items: flex-start;
  display: flex;
  gap: 483px;
  height: 81px;
  margin-right: 107px;
  margin-top: 105px;
  min-width: 760px;
  position: relative;
}

.frame-112-5 {
  align-items: center;
  background-color: var(--rum-swizzle);
  border-radius: 40px;
  box-shadow: 0px 4px 11px #1e454945;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  padding: 15px;
  position: relative;
  transition: all 0.2s ease-in;
  width: fit-content;
}

.frame-112-5:hover {
  transform: scale(1.1);
}

.cost-criteria {
  letter-spacing: 0.20px;
  line-height: 18px;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

