.smed-1-1 {
  align-items: flex-start;
  background-color: var(--white);
  border: 1px none;
  display: flex;
  flex-direction: column;
  gap: 29px;
  height: 924px;
  width: 1440px;
}

.overlap-group1-38 {
  align-items: flex-start;
  background-color: var(--rum-swizzle);
  border-radius: 41px 0px 100px 118px;
  display: flex;
  gap: 195px;
  height: 190px;
  justify-content: flex-end;
  min-width: 1440px;
  padding: 20px 18px;
}

.x1200px-logo_icam_-_2008-1-58 {
  cursor: pointer;
  height: 74px;
  object-fit: cover;
  width: 144px;
}

.nav-items-container-28 {
  align-items: center;
  align-self: center;
  display: flex;
  flex-direction: column;
  gap: 35px;
  margin-top: 9.0px;
  min-height: 131px;
  width: 1011px;
}

.nav-items-2-43 {
  align-items: center;
  cursor: pointer;
  display: flex;
  gap: 60px;
  height: 48px;
  justify-content: center;
  position: relative;
  transition: all 0.2s ease-in;
  width: 1011px;
}

.nav-items-2-43:hover,
.nav-items-3-71:hover {
  transform: translate(0, -5px);
}

.nav-items-3-71 {
  align-items: center;
  cursor: pointer;
  display: flex;
  gap: 60px;
  height: 48px;
  justify-content: center;
  margin-left: 5.0px;
  position: relative;
  transition: all 0.2s ease-in;
  width: 940px;
}

.flex-row-107 {
  align-items: center;
  display: flex;
  gap: 13px;
  margin-left: 19px;
  min-width: 1153px;
}

.flex-col-69 {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 7.5px;
  min-height: 606px;
  width: 136px;
}

.arrow-container-16 {
  align-items: flex-start;
  align-self: flex-end;
  display: flex;
  gap: 6px;
  height: 88px;
  min-width: 121px;
}

.arrow-9-10 {
  height: 87px;
  width: 55px;
}

.arrow-10-5 {
  align-self: flex-end;
  height: 87px;
  width: 60px;
}

.ellipse-85-11 {
  background-color: var(--rum-swizzle);
  border-radius: 36px/35.5px;
  height: 71px;
  margin-right: 12.27px;
  margin-top: 39px;
  width: 72px;
}

.rectangle-9-3 {
  align-self: flex-start;
  background-color: var(--rum-swizzle);
  height: 47px;
  margin-left: 8px;
  margin-top: 40px;
  width: 99px;
}

.frame-5-16 {
  align-self: flex-start;
  background-color: var(--rum-swizzle);
  border-radius: 6px;
  box-shadow: 0px 4px 11px #1e454945;
  height: 47px;
  margin-left: 9px;
  margin-top: 44px;
  width: 99px;
}

.overlap-group2-16 {
  align-self: flex-start;
  height: 15px;
  margin-top: 44px;
  position: relative;
  width: 117px;
}

.line-2-15 {
  height: 1px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 7px;
  width: 117px;
}

.ellipse-86-15 {
  background-color: var(--alto);
  border-radius: 9.5px/7.5px;
  height: 15px;
  left: 22px;
  position: absolute;
  top: 0;
  width: 19px;
}

.label-136 {
  letter-spacing: 0;
  line-height: 25.9px;
  margin-right: 30.27px;
  margin-top: 7px;
  min-height: 26px;
  min-width: 50px;
  white-space: nowrap;
}

.text-format-16 {
  height: 55px;
  margin-right: 15.27px;
  margin-top: 33px;
  object-fit: cover;
  width: 69px;
}

.label-137 {
  letter-spacing: 0;
  line-height: 25.9px;
  margin-right: 12.27px;
  margin-top: 23px;
  min-height: 26px;
  min-width: 38px;
  white-space: nowrap;
}

.flex-row-108 {
  align-items: center;
  display: flex;
  gap: 15px;
  height: 673px;
  min-width: 1004px;
}

.line-1-15 {
  height: 640px;
  margin-top: 15.37px;
  width: 11px;
}

.flex-col-70 {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  min-height: 673px;
  width: 978px;
}

.flex-row-109 {
  align-items: flex-start;
  align-self: flex-start;
  display: flex;
  gap: 189px;
  height: 54px;
  min-width: 837px;
}

.frame-11-9 {
  align-items: center;
  align-self: flex-end;
  background-color: var(--rum-swizzle);
  border-radius: 6px;
  box-shadow: 0px 4px 11px #1e454945;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 30px;
  justify-content: center;
  padding: 15px;
  position: relative;
  width: 78px;
}

.place-258 {
  letter-spacing: 0.20px;
  line-height: 18px;
  margin-bottom: -8.00px;
  margin-top: -10.00px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.click-on-modeling-14 {
  height: 48px;
  letter-spacing: 0;
  line-height: normal;
  text-align: center;
  width: 570px;
}

.overlap-group-container-10 {
  height: 448px;
  margin-top: 36px;
  position: relative;
  width: 820px;
}

.overlap-group3-15 {
  height: 66px;
  left: 8px;
  position: absolute;
  top: 225px;
  width: 138px;
}

.label-138 {
  left: 0;
  letter-spacing: 0;
  line-height: 25.9px;
  position: absolute;
  text-align: center;
  top: 0;
  width: 138px;
}

.arrow-5-10 {
  height: 22px;
  left: 1px;
  position: absolute;
  top: 44px;
  width: 137px;
}

.overlap-group4-12 {
  height: 65px;
  left: 653px;
  position: absolute;
  top: 225px;
  width: 167px;
}

.label-139 {
  left: 0;
  letter-spacing: 0;
  line-height: 25.9px;
  position: absolute;
  text-align: center;
  top: 0;
  width: 167px;
}

.arrow-15-1 {
  height: 22px;
  left: 10px;
  position: absolute;
  top: 43px;
  width: 137px;
}

.overlap-group-59 {
  height: 448px;
  left: 0;
  position: absolute;
  top: 0;
  width: 799px;
}

.rectangle-8 {
  background-color: var(--rum-swizzle);
  height: 82px;
  left: 0;
  position: absolute;
  top: 0;
  width: 220px;
}

.arrow-12-1 {
  height: 97px;
  left: 151px;
  position: absolute;
  top: 82px;
  width: 22px;
}

.arrow-13-1 {
  height: 97px;
  left: 628px;
  position: absolute;
  top: 82px;
  width: 22px;
}

.rectangle-10-1 {
  background-color: var(--rum-swizzle);
  height: 82px;
  left: 581px;
  position: absolute;
  top: 11px;
  width: 218px;
}

.derniere-piece-bonne-serie-a {
  left: 41px;
  letter-spacing: 0;
  line-height: 25.9px;
  position: absolute;
  top: 15px;
  width: 161px;
}

.rectangle-11-1 {
  background-color: var(--rum-swizzle);
  height: 270px;
  left: 160px;
  position: absolute;
  top: 178px;
  width: 481px;
}

.changement-outillage {
  left: 351px;
  letter-spacing: 0;
  line-height: 25.9px;
  position: absolute;
  top: 357px;
  transform: rotate(-0.54deg);
  width: 132px;
}

.reglage {
  left: 507px;
  letter-spacing: 0;
  line-height: 25.9px;
  position: absolute;
  top: 287px;
  transform: rotate(-60.79deg);
  white-space: nowrap;
}

.nettoyage {
  background-color: transparent;
  border: 0;
  height: 26px;
  left: 205px;
  letter-spacing: 0;
  line-height: 25.9px;
  padding: 0;
  position: absolute;
  top: 281px;
  transform: rotate(47.17deg);
  white-space: nowrap;
  width: 109px;
}

.nettoyage::placeholder,
.premiere-piece-bonne-serie-b::placeholder {
  color: #0c411199;
}

.arrow-18 {
  height: 22px;
  left: 316px;
  position: absolute;
  top: 373px;
  width: 212px;
}

.arrow-17 {
  height: 129px;
  left: 532px;
  position: absolute;
  top: 244px;
  width: 76px;
}

.arrow-16-1 {
  height: 130px;
  left: 188px;
  position: absolute;
  top: 245px;
  width: 120px;
}

.premiere-piece-bonne-serie-b {
  background-color: transparent;
  border: 0;
  height: 52px;
  left: 622px;
  letter-spacing: 0;
  line-height: 25.9px;
  padding: 0;
  position: absolute;
  top: 24px;
  width: 150px;
}

.flex-row-110 {
  align-items: center;
  align-self: center;
  display: flex;
  gap: 2px;
  margin-left: 142.0px;
  margin-top: 20px;
  min-width: 479px;
}

.arrow-14-1 {
  height: 22px;
  margin-top: 0.57px;
  width: 137px;
}

.overlap-group5-10 {
  height: 47px;
  position: relative;
  width: 341px;
}

.arrow-11-4 {
  height: 22px;
  left: 204px;
  position: absolute;
  top: 11px;
  width: 137px;
}

.frame-container-13 {
  align-items: flex-start;
  display: flex;
  gap: 456px;
  margin-right: 13px;
  margin-top: 21px;
  min-width: 768px;
}

.frame-12-6 {
  align-items: center;
  background-color: var(--rum-swizzle);
  border-radius: 6px;
  box-shadow: 0px 4px 11px #1e454945;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 47px;
  justify-content: center;
  padding: 15px;
  position: relative;
  transition: all 0.2s ease-in;
  width: 156px;
}

.frame-12-6:hover {
  transform: scale(1.1);
}

.modeling-104 {
  cursor: pointer;
  letter-spacing: 0.20px;
  line-height: 18px;
  margin-top: -1.50px;
  position: relative;
  text-align: center;
  text-decoration: underline;
  white-space: nowrap;
  width: fit-content;
}

.frame-2-21 {
  align-items: center;
  background-color: var(--rum-swizzle);
  border-radius: 6px;
  box-shadow: 0px 4px 11px #1e454945;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 47px;
  justify-content: center;
  padding: 15px;
  position: relative;
  width: 156px;
}

.continue-15 {
  letter-spacing: 0.20px;
  line-height: 18px;
  margin-top: -1.50px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

