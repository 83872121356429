.nav-items-2-2 {
  align-items: center;
  cursor: pointer;
  display: flex;
  gap: 60px;
  height: 48px;
  justify-content: center;
  position: relative;
  transition: all 0.2s ease-in;
  width: 926px;
}

.nav-items-2-2:hover {
  transform: translate(0, -5px);
}

.nav-items-2-2.nav-items-2-4,
.nav-items-2-2.nav-items-2-5,
.nav-items-2-2.nav-items-2-6,
.nav-items-2-2.nav-items-2-7,
.nav-items-2-2.nav-items-2-22,
.nav-items-2-2.nav-items-2-24,
.nav-items-2-2.nav-items-2-26,
.nav-items-2-2.nav-items-2-27,
.nav-items-2-2.nav-items-2-28,
.nav-items-2-2.nav-items-2-32,
.nav-items-2-2.nav-items-2-38,
.nav-items-2-2.nav-items-2-40,
.nav-items-2-2.nav-items-2-41,
.nav-items-2-2.nav-items-2-42 {
  left: 576px;
  position: absolute;
  top: 110px;
}

.nav-items-2-2.nav-items-2-9,
.nav-items-2-2.nav-items-2-13,
.nav-items-2-2.nav-items-2-57 {
  left: 545px;
  position: absolute;
  top: 109px;
}

.nav-items-2-2.nav-items-2-12 {
  left: 546px;
  position: absolute;
  top: 109px;
}

.nav-items-2-2.nav-items-2-29 {
  left: 571px;
  position: absolute;
  top: 107px;
}

.nav-items-2-2.nav-items-2-31 {
  left: 572px;
  position: absolute;
  top: 108px;
}

.nav-items-2-2.nav-items-2-33 {
  left: 572px;
  position: absolute;
  top: 110px;
}

.nav-items-2-2.nav-items-2-44 {
  left: 545px;
  position: absolute;
  top: 110px;
}

.nav-items-2-2.nav-items-2-46 {
  left: 541px;
  position: absolute;
  top: 110px;
}

