.frame-114,
.frame-114-1,
.frame-114-2,
.frame-114-3,
.frame-114-4,
.frame-114-5,
.frame-114-6,
.frame-114-7,
.frame-114-8,
.frame-114-9,
.frame-114-10,
.frame-114-11,
.frame-114-12,
.frame-114-13,
.frame-114-14,
.frame-114-15,
.frame-114-16,
.frame-114-17,
.frame-114-18,
.frame-114-19,
.frame-114-20,
.frame-114-21,
.frame-114-22,
.frame-114-23,
.frame-114-24,
.frame-114-25,
.frame-114-26,
.frame-114-27,
.frame-114-28,
.frame-114-29,
.frame-114-30,
.frame-114-31,
.frame-114-32,
.frame-114-33,
.frame-114-34,
.frame-114-35,
.frame-114-36,
.frame-114-37,
.frame-114-38,
.frame-114-39,
.frame-114-40,
.frame-114-41,
.frame-114-42,
.frame-114-43,
.frame-114-44,
.frame-114-45,
.frame-114-46,
.frame-114-47,
.frame-114-48,
.frame-114-49,
.frame-114-50,
.frame-114-51,
.frame-114-52,
.frame-114-53,
.frame-114-54,
.frame-114-55,
.frame-114-56,
.frame-114-57,
.frame-114-58,
.frame-114-59,
.frame-114-60,
.frame-114-61,
.frame-114-62,
.frame-114-63,
.frame-114-64,
.frame-114-65,
.frame-114-66,
.frame-114-67,
.frame-114-68,
.frame-114-69,
.frame-114-70,
.frame-114-71,
.frame-114-72,
.frame-114-73,
.frame-114-74,
.frame-114-75,
.frame-114-76,
.frame-114-77,
.frame-114-78,
.frame-114-79,
.frame-114-80,
.frame-114-81 {
  align-items: center;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  padding: 15px;
  position: relative;
  transition: all 0.2s ease-in;
  width: fit-content;
}

.frame-114:hover,
.frame-114-1:hover,
.frame-114-2:hover,
.frame-114-3:hover,
.frame-114-4:hover,
.frame-114-5:hover,
.frame-114-6:hover,
.frame-114-7:hover,
.frame-114-8:hover,
.frame-114-9:hover,
.frame-114-10:hover,
.frame-114-11:hover,
.frame-114-12:hover,
.frame-114-13:hover,
.frame-114-14:hover,
.frame-114-15:hover,
.frame-114-16:hover,
.frame-114-17:hover,
.frame-114-18:hover,
.frame-114-19:hover,
.frame-114-20:hover,
.frame-114-21:hover,
.frame-114-22:hover,
.frame-114-23:hover,
.frame-114-24:hover,
.frame-114-25:hover,
.frame-114-26:hover,
.frame-114-27:hover,
.frame-114-28:hover,
.frame-114-29:hover,
.frame-114-30:hover,
.frame-114-31:hover,
.frame-114-32:hover,
.frame-114-33:hover,
.frame-114-34:hover,
.frame-114-35:hover,
.frame-114-36:hover,
.frame-114-37:hover,
.frame-114-38:hover,
.frame-114-39:hover,
.frame-114-40:hover,
.frame-114-41:hover,
.frame-114-42:hover,
.frame-114-43:hover,
.frame-114-44:hover,
.frame-114-45:hover,
.frame-114-46:hover,
.frame-114-47:hover,
.frame-114-48:hover,
.frame-114-49:hover,
.frame-114-50:hover,
.frame-114-51:hover,
.frame-114-52:hover,
.frame-114-53:hover,
.frame-114-54:hover,
.frame-114-55:hover,
.frame-114-56:hover,
.frame-114-57:hover,
.frame-114-58:hover,
.frame-114-59:hover,
.frame-114-60:hover,
.frame-114-61:hover,
.frame-114-62:hover,
.frame-114-63:hover,
.frame-114-64:hover,
.frame-114-65:hover,
.frame-114-66:hover,
.frame-114-67:hover,
.frame-114-68:hover,
.frame-114-69:hover,
.frame-114-70:hover,
.frame-114-71:hover,
.frame-114-72:hover,
.frame-114-73:hover,
.frame-114-74:hover,
.frame-114-75:hover,
.frame-114-76:hover,
.frame-114-77:hover,
.frame-114-78:hover,
.frame-114-79:hover,
.frame-114-80:hover,
.frame-114-81:hover {
  transform: scale(1.1);
}

.modeling-1,
.modeling-2,
.modeling-3,
.modeling-4,
.modeling-5,
.modeling-6,
.modeling-7,
.modeling-8,
.modeling-9,
.modeling-10,
.modeling-11,
.modeling-12,
.modeling-13,
.modeling-14,
.modeling-15,
.modeling-16,
.modeling-17,
.modeling-18,
.modeling-19,
.modeling-20,
.modeling-21,
.modeling-22,
.modeling-23,
.modeling-24,
.modeling-25,
.modeling-26,
.modeling-27,
.modeling-28,
.modeling-29,
.modeling-30,
.modeling-31,
.modeling-32,
.modeling-33,
.modeling-34,
.modeling-35,
.modeling-36,
.modeling-37,
.modeling-38,
.modeling-39,
.modeling-40,
.modeling-41,
.modeling-42,
.modeling-43,
.modeling-44,
.modeling-45,
.modeling-46,
.modeling-47,
.modeling-48,
.modeling-49,
.modeling-50,
.modeling-51,
.modeling-52,
.modeling-53,
.modeling-54,
.modeling-55,
.modeling-56,
.modeling-57,
.modeling-58,
.modeling-59,
.modeling-60,
.modeling-61,
.modeling-62,
.modeling-63,
.modeling-64,
.modeling-65,
.modeling-66,
.modeling-67,
.modeling-68,
.modeling-69,
.modeling-70,
.modeling-71,
.modeling-72,
.modeling-73,
.modeling-74,
.modeling-75,
.modeling-76,
.modeling-77,
.modeling-78,
.modeling-79,
.modeling-80,
.modeling-81,
.modeling-82 {
  letter-spacing: 0.20px;
  line-height: 18px;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

