.crew-member-9,
.crew-member-10 {
  height: 111px;
  left: 0;
  position: absolute;
  top: 316px;
  width: 272px;
}

.table-container-9,
.table-container-10 {
  height: 64px;
  left: 0;
  position: absolute;
  top: 0;
  width: 272px;
}

