.mesure-de-performances-futures-1 {
  align-items: flex-start;
  background-color: var(--white);
  border: 1px none;
  display: flex;
  flex-direction: column;
  height: 924px;
  position: relative;
  width: 1440px;
}

.overlap-group-77 {
  align-items: flex-start;
  background-color: var(--rum-swizzle);
  border-radius: 41px 0px 100px 118px;
  display: flex;
  gap: 82px;
  height: 190px;
  justify-content: flex-end;
  min-width: 1440px;
  padding: 20px 18px;
}

.x1200px-logo_icam_-_2008-1-77 {
  cursor: pointer;
  height: 74px;
  object-fit: cover;
  width: 144px;
}

.nav-items-container-41 {
  align-items: flex-start;
  align-self: center;
  display: flex;
  flex-direction: column;
  gap: 35px;
  margin-top: 9.0px;
  min-height: 131px;
  width: 1124px;
}

.nav-items-2-54 {
  align-items: center;
  align-self: flex-end;
  cursor: pointer;
  display: flex;
  gap: 60px;
  height: 48px;
  justify-content: center;
  position: relative;
  transition: all 0.2s ease-in;
  width: 1011px;
}

.nav-items-2-54:hover,
.nav-items-1:hover {
  transform: translate(0, -5px);
}

.frame-117-107 {
  align-items: center;
  border-radius: 6px;
  box-shadow: 0px 4px 4px #00000040;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  padding: 15px;
  position: relative;
  transition: all 0.2s ease-in;
  width: fit-content;
}

.frame-117-107:hover {
  transform: scale(1.1);
}

.design-85 {
  letter-spacing: 0.20px;
  line-height: 18px;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.nav-items-1 {
  align-items: center;
  cursor: pointer;
  display: flex;
  gap: 60px;
  height: 48px;
  position: relative;
  transition: all 0.2s ease-in;
  width: 1061px;
}

.label-162 {
  cursor: pointer;
  letter-spacing: 0;
  line-height: 25.9px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.label-166 {
  letter-spacing: 0;
  line-height: 25.9px;
  position: relative;
  text-shadow: 0px 4px 4px #00000040;
  white-space: nowrap;
  width: fit-content;
}

.frame-3-6 {
  align-items: center;
  align-self: flex-end;
  background-color: var(--rum-swizzle);
  border-radius: 6px;
  box-shadow: 0px 4px 11px #1e454945;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 47px;
  justify-content: center;
  margin-right: 265px;
  margin-top: 575px;
  padding: 15px;
  position: relative;
  width: 156px;
}

.finish-1 {
  letter-spacing: 0.20px;
  line-height: 18px;
  margin-top: -1.50px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

