.table-number-primary-key,
.table-number-primary-key-1 {
  display: flex;
  height: 10px;
  left: 0;
  position: absolute;
  top: 17px;
  width: 122px;
}

