.frame-152 {
  display: flex;
  height: 36px;
  position: relative;
  width: 146px;
}

.frame-152.frame-133-2 {
  margin-left: 292px;
  margin-top: 19px;
}

