.frame-116-163,
.frame-116-164,
.frame-116-165,
.frame-116-166,
.frame-116-167,
.frame-116-168,
.frame-116-169,
.frame-116-170,
.frame-116-171,
.frame-116-172,
.frame-116-173,
.frame-116-174 {
  align-items: center;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  padding: 15px;
  position: relative;
  transition: all 0.2s ease-in;
  width: fit-content;
}

.frame-116-163:hover,
.frame-116-164:hover,
.frame-116-165:hover,
.frame-116-166:hover,
.frame-116-167:hover,
.frame-116-168:hover,
.frame-116-169:hover,
.frame-116-170:hover,
.frame-116-171:hover,
.frame-116-172:hover,
.frame-116-173:hover,
.frame-116-174:hover {
  transform: scale(1.1);
}

.smed,
.smed-1,
.smed-2,
.smed-3,
.smed-4,
.smed-5,
.smed-6,
.smed-7,
.smed-8,
.smed-9,
.smed-10,
.smed-11 {
  letter-spacing: 0.20px;
  line-height: 18px;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

