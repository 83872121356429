.process-view-10 {
  align-items: flex-start;
  background-color: var(--white);
  border: 1px none;
  display: flex;
  flex-direction: column;
  gap: 64px;
  height: 924px;
  width: 1440px;
}

.overlap-group-19 {
  align-items: flex-end;
  background-color: var(--rum-swizzle);
  border-radius: 41px 0px 100px 118px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-left: 2px;
  min-height: 190px;
  padding: 20px 18px;
  position: relative;
  width: 1438px;
}

.flex-row-61 {
  align-items: center;
  display: flex;
  gap: 195px;
  min-width: 1350px;
}

.x1200px-logo_icam_-_2008-1-19 {
  cursor: pointer;
  height: 74px;
  object-fit: cover;
  width: 144px;
}

.nav-items-2-30 {
  align-items: center;
  cursor: pointer;
  display: flex;
  gap: 60px;
  height: 48px;
  justify-content: center;
  margin-top: 2.0px;
  position: relative;
  transition: all 0.2s ease-in;
  width: 1011px;
}

.nav-items-2-30:hover {
  transform: translate(0, -5px);
}

.frame-117-92 {
  align-items: center;
  border-radius: 6px;
  box-shadow: 0px 4px 4px #00000040;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  padding: 15px;
  position: relative;
  transition: all 0.2s ease-in;
  width: fit-content;
}

.frame-117-92:hover {
  transform: scale(1.1);
}

.design-77 {
  letter-spacing: 0.20px;
  line-height: 18px;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.flex-row-62 {
  align-items: center;
  display: flex;
  gap: 162px;
  margin-left: 21px;
  min-width: 1156px;
}

.flex-row-63 {
  align-items: flex-start;
  display: flex;
  min-width: 260px;
}

.flex-col-42 {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 1.48px;
  min-height: 632px;
  width: 143px;
}

.arrow-container-13 {
  height: 77px;
  margin-right: 2.98px;
  position: relative;
  width: 77px;
}

.arrow-1-10 {
  height: 76px;
  left: 0;
  position: absolute;
  top: 0;
  width: 50px;
}

.arrow-2-9 {
  height: 76px;
  left: 28px;
  position: absolute;
  top: 1px;
  width: 48px;
}

.rectangle-7-3 {
  align-self: flex-start;
  height: 42px;
  margin-top: 66px;
  width: 112px;
}

.flex-row-64 {
  align-items: center;
  display: flex;
  gap: 9px;
  margin-left: 1.0px;
  margin-top: 52px;
  min-width: 142px;
}

.ellipse-85-8 {
  background-color: var(--rum-swizzle);
  border-radius: 36px/35.5px;
  height: 71px;
  margin-bottom: 1.0px;
  width: 72px;
}

.frame-3-4 {
  display: flex;
  gap: 14px;
  height: 76px;
  width: 61px;
}

.line-3-5 {
  height: 74.03px;
  margin-left: 22px;
  margin-top: 2.0px;
  width: 4px;
}

.line-4-3 {
  height: 74px;
  margin-top: 2px;
  width: 3px;
}

.frame-4-51 {
  background-color: var(--rum-swizzle);
  border-radius: 6px;
  box-shadow: 0px 4px 11px #1e454945;
  height: 47px;
  margin-right: 14.0px;
  margin-top: 46px;
  width: 99px;
}

.overlap-group3-11 {
  align-self: flex-start;
  height: 15px;
  margin-left: 6px;
  margin-top: 42px;
  position: relative;
  width: 117px;
}

.line-2-12 {
  height: 1px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 7px;
  width: 117px;
}

.ellipse-86-12 {
  background-color: var(--alto);
  border-radius: 9.5px/7.5px;
  height: 15px;
  left: 18px;
  position: absolute;
  top: 0;
  width: 19px;
}

.label-123 {
  letter-spacing: 0;
  line-height: 25.9px;
  margin-right: 27.0px;
  margin-top: 11px;
  min-height: 26px;
  min-width: 50px;
  white-space: nowrap;
}

.text-format-12 {
  height: 55px;
  margin-right: 18.0px;
  margin-top: 35px;
  object-fit: cover;
  width: 69px;
}

.label-124 {
  letter-spacing: 0;
  line-height: 25.9px;
  margin-right: 15.0px;
  margin-top: 15px;
  min-height: 26px;
  min-width: 38px;
  white-space: nowrap;
}

.line-1-12 {
  height: 640px;
  margin-left: 8px;
  width: 11px;
}

.frame-8-4 {
  align-items: center;
  background-color: var(--rum-swizzle);
  border-radius: 6px;
  box-shadow: 0px 4px 11px #1e454945;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 30px;
  justify-content: center;
  margin-left: 20px;
  margin-top: 2.98px;
  padding: 15px;
  position: relative;
  width: 78px;
}

.place-248 {
  letter-spacing: 0.20px;
  line-height: 18px;
  margin-bottom: -8.00px;
  margin-top: -10.00px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.flex-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 78px;
  margin-bottom: 20.37px;
  min-height: 596px;
  width: 734px;
}

.click-on-flexsim-b-1 {
  height: 48px;
  letter-spacing: 0;
  line-height: normal;
  margin-right: 37.5px;
  text-align: center;
  width: 570px;
}

.flex-row-65 {
  align-items: flex-end;
  display: flex;
  height: 470px;
  min-width: 734px;
}

.flex-col-43 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 157px;
  min-height: 470px;
  width: 446px;
}

.flex-row-66 {
  align-items: center;
  display: flex;
  gap: 4px;
  height: 266px;
  min-width: 446px;
}

.overlap-group2-11 {
  align-items: flex-start;
  background-size: 100% 100%;
  display: flex;
  height: 68px;
  margin-bottom: 2.03px;
  min-width: 168px;
  padding: 24px 67.5px;
}

.cut-1 {
  height: 18px;
  letter-spacing: 0.20px;
  line-height: 18px;
  min-width: 33px;
  text-align: center;
  white-space: nowrap;
}

.flex-col-44 {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  gap: 4px;
  min-height: 266px;
  width: 274px;
}

.overlap-group1-31 {
  height: 166px;
  position: relative;
  width: 274px;
}

.label-125 {
  left: 141px;
  letter-spacing: 0;
  line-height: 25.9px;
  position: absolute;
  top: 29px;
  transform: rotate(-89.52deg);
  white-space: nowrap;
}

.rectangle-5-3 {
  height: 68px;
  left: 115px;
  position: absolute;
  top: 98px;
  width: 160px;
}

.manufacturing-5 {
  height: 18px;
  left: 128px;
  letter-spacing: 0.20px;
  line-height: 18px;
  position: absolute;
  text-align: center;
  top: 122px;
  white-space: nowrap;
}

.arrow-6-9 {
  height: 22px;
  left: 0;
  position: absolute;
  top: 121px;
  width: 116px;
}

.arrow-11-2 {
  height: 100px;
  left: 180px;
  position: absolute;
  top: 0;
  width: 22px;
}

.overlap-group-container-6 {
  align-items: flex-start;
  display: flex;
  gap: 19px;
  margin-right: 15.82px;
  min-width: 98px;
}

.overlap-group4-9 {
  height: 96px;
  position: relative;
  width: 33px;
}

.label-126 {
  left: -24px;
  letter-spacing: 0;
  line-height: 25.9px;
  position: absolute;
  top: 44px;
  transform: rotate(90.20deg);
  white-space: nowrap;
}

.arrow-8-8 {
  height: 96px;
  left: 11px;
  position: absolute;
  top: 0;
  width: 22px;
}

.overlap-group7-1 {
  height: 92px;
  position: relative;
  width: 46px;
}

.label-127 {
  left: 2px;
  letter-spacing: 0;
  line-height: 25.9px;
  position: absolute;
  top: 43px;
  transform: rotate(89.83deg);
  white-space: nowrap;
}

.arrow-9-7 {
  height: 92px;
  left: 0;
  position: absolute;
  top: 0;
  width: 22px;
}

.frame-9-4 {
  align-items: center;
  background-color: var(--rum-swizzle);
  border-radius: 6px;
  box-shadow: 0px 4px 11px #1e454945;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 47px;
  justify-content: center;
  margin-left: 65px;
  padding: 15px;
  position: relative;
  width: 156px;
}

.flexsim-1,
.continue-11 {
  letter-spacing: 0.20px;
  line-height: 18px;
  margin-top: -1.50px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.span0 {
  text-decoration: underline;
}

.flex-col-45 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 257px;
  min-height: 372px;
  width: 287px;
}

.overlap-group5-7 {
  height: 68px;
  position: relative;
  width: 287px;
}

.rectangle-6-3 {
  height: 68px;
  left: 119px;
  position: absolute;
  top: 0;
  width: 168px;
}

.assembly-1 {
  height: 18px;
  left: 158px;
  letter-spacing: 0.20px;
  line-height: 18px;
  position: absolute;
  text-align: center;
  top: 20px;
  white-space: nowrap;
}

.arrow-10-2 {
  height: 22px;
  left: 0;
  position: absolute;
  top: 23px;
  width: 120px;
}

.frame-2-16 {
  align-items: center;
  background-color: var(--rum-swizzle);
  border-radius: 6px;
  box-shadow: 0px 4px 11px #1e454945;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 47px;
  justify-content: center;
  margin-right: 6.0px;
  padding: 15px;
  position: relative;
  width: 156px;
}

