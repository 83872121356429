.frame-114-90 {
  align-items: center;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  margin-left: -3.50px;
  padding: 15px;
  position: relative;
  transition: all 0.2s ease-in;
  width: fit-content;
}

.frame-114-90:hover {
  transform: scale(1.1);
}

.place-4 {
  letter-spacing: 0.20px;
  line-height: 18px;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.frame-114-90.frame-114-100,
.frame-114-90.frame-114-152 {
  margin-left: unset;
}

