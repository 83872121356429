.frame-95 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 12px;
  left: 1101px;
  min-height: 77px;
  position: absolute;
  top: 508px;
  width: 396px;
}

.password {
  color: var(--black);
  font-weight: 400;
  line-height: normal;
  margin-left: 7px;
  margin-top: -1px;
  min-height: 21px;
}

.overlap-group3 {
  height: 45px;
  position: relative;
  width: 396px;
}

.rectangle-4362 {
  border: 1px solid;
  border-color: var(--tall-poppy);
  border-radius: 6px;
  height: 44px;
  left: 0;
  position: absolute;
  top: 1px;
  width: 396px;
}

.tap-here-2 {
  background-color: transparent;
  border: 0;
  color: var(--graye);
  font-weight: 400;
  height: 44px;
  left: 0;
  line-height: normal;
  padding: 0;
  position: absolute;
  top: 0;
  width: 396px;
}

.tap-here-2::placeholder {
  color: #ffffff99;
}

.frame-95.frame-95-1 {
  top: 506px;
}

