.physical-model-10 {
  align-items: flex-start;
  background-color: var(--white);
  border: 1px none;
  display: flex;
  flex-direction: column;
  gap: 49px;
  height: 924px;
  width: 1440px;
}

.overlap-group4-8 {
  align-items: flex-end;
  background-color: var(--rum-swizzle);
  border-radius: 41px 0px 100px 118px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  min-height: 190px;
  padding: 20px 16px;
  position: relative;
  width: 1438px;
}

.flex-row-52 {
  align-items: center;
  display: flex;
  gap: 195px;
  min-width: 1350px;
}

.x1200px-logo_icam_-_2008-1-17 {
  cursor: pointer;
  height: 74px;
  object-fit: cover;
  width: 144px;
}

.nav-items-2-23 {
  align-items: center;
  cursor: pointer;
  display: flex;
  gap: 60px;
  height: 48px;
  justify-content: center;
  margin-top: 2.0px;
  position: relative;
  transition: all 0.2s ease-in;
  width: 1011px;
}

.nav-items-2-23:hover {
  transform: translate(0, -5px);
}

.frame-117-90 {
  align-items: center;
  border-radius: 6px;
  box-shadow: 0px 4px 4px #00000040;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  padding: 15px;
  position: relative;
  transition: all 0.2s ease-in;
  width: fit-content;
}

.frame-117-90:hover {
  transform: scale(1.1);
}

.design-75 {
  letter-spacing: 0.20px;
  line-height: 18px;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.flex-row-53 {
  align-items: center;
  display: flex;
  gap: 19px;
  height: 660px;
  margin-left: 24px;
  min-width: 1083px;
}

.flex-row-54 {
  align-items: center;
  display: flex;
  gap: 13px;
  margin-bottom: 7.63px;
  min-width: 160px;
}

.flex-col-36 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-bottom: 7.87px;
  min-height: 606px;
  width: 136px;
}

.arrow-container-11 {
  align-items: flex-start;
  align-self: flex-end;
  display: flex;
  gap: 6px;
  height: 88px;
  min-width: 121px;
}

.arrow-1-9 {
  height: 87px;
  width: 55px;
}

.arrow-2-8 {
  align-self: flex-end;
  height: 87px;
  width: 60px;
}

.ellipse-85-6 {
  align-self: center;
  background-color: var(--rum-swizzle);
  border-radius: 36px/35.5px;
  height: 71px;
  margin-right: 12.27px;
  margin-top: 39px;
  width: 72px;
}

.rectangle-3-6 {
  background-color: var(--rum-swizzle);
  height: 47px;
  margin-left: 8px;
  margin-top: 40px;
  width: 99px;
}

.frame-4-50 {
  background-color: var(--rum-swizzle);
  border-radius: 6px;
  box-shadow: 0px 4px 11px #1e454945;
  height: 47px;
  margin-left: 9px;
  margin-top: 44px;
  width: 99px;
}

.overlap-group2-10 {
  height: 15px;
  margin-top: 44px;
  position: relative;
  width: 117px;
}

.line-2-10 {
  height: 1px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 7px;
  width: 117px;
}

.ellipse-86-10 {
  background-color: var(--alto);
  border-radius: 9.5px/7.5px;
  height: 15px;
  left: 22px;
  position: absolute;
  top: 0;
  width: 19px;
}

.label-113 {
  align-self: center;
  letter-spacing: 0;
  line-height: 25.9px;
  margin-right: 30.27px;
  margin-top: 7px;
  min-height: 26px;
  min-width: 50px;
  white-space: nowrap;
}

.text-format-10 {
  height: 55px;
  margin-left: 12px;
  margin-top: 35px;
  object-fit: cover;
  width: 69px;
}

.label-114 {
  align-self: center;
  letter-spacing: 0;
  line-height: 25.9px;
  margin-right: 36.27px;
  margin-top: 21px;
  min-height: 26px;
  min-width: 38px;
  white-space: nowrap;
}

.line-1-10 {
  height: 640px;
  width: 11px;
}

.flex-col-37 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 68px;
  min-height: 660px;
  width: 904px;
}

.flex-row-55 {
  align-items: center;
  display: flex;
  gap: 224px;
  min-width: 872px;
}

.frame-7-3 {
  align-items: center;
  background-color: var(--rum-swizzle);
  border-radius: 6px;
  box-shadow: 0px 4px 11px #1e454945;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 30px;
  justify-content: center;
  margin-bottom: 6.0px;
  padding: 15px;
  position: relative;
  width: 78px;
}

.place-229 {
  letter-spacing: 0.20px;
  line-height: 18px;
  margin-bottom: -8.00px;
  margin-top: -10.00px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.click-on-modeling-9 {
  height: 48px;
  letter-spacing: 0;
  line-height: normal;
  text-align: center;
  width: 570px;
}

.flex-row-56 {
  align-items: flex-end;
  align-self: flex-end;
  display: flex;
  height: 544px;
  min-width: 688px;
}

.flex-col-38 {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  min-height: 526px;
  width: 244px;
}

.overlap-group6-3 {
  height: 100px;
  margin-right: 47.36px;
  position: relative;
  width: 37px;
}

.label-115 {
  left: 8px;
  letter-spacing: 0;
  line-height: 25.9px;
  position: absolute;
  top: 27px;
  transform: rotate(88.80deg);
  white-space: nowrap;
}

.arrow-5-7 {
  height: 100px;
  left: 0;
  position: absolute;
  top: 0;
  width: 22px;
}

.overlap-group-17 {
  height: 77px;
  margin-top: 6px;
  position: relative;
  width: 244px;
}

.label-116 {
  left: 24px;
  letter-spacing: 0;
  line-height: 25.9px;
  position: absolute;
  top: 10px;
  white-space: nowrap;
}

.arrow-6-8 {
  height: 22px;
  left: 0;
  position: absolute;
  top: 26px;
  width: 100px;
}

.overlap-group3-10 {
  align-self: center;
  height: 101px;
  margin-left: 83.5px;
  margin-top: 6px;
  position: relative;
  width: 39px;
}

.label-117 {
  left: -29px;
  letter-spacing: 0;
  line-height: 25.9px;
  position: absolute;
  top: 45px;
  transform: rotate(-89.30deg);
  white-space: nowrap;
}

.arrow-8-7 {
  height: 95px;
  left: 17px;
  position: absolute;
  top: 0;
  width: 22px;
}

.frame-9-2 {
  align-items: center;
  background-color: var(--rum-swizzle);
  border-radius: 6px;
  box-shadow: 0px 4px 11px #1e454945;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 47px;
  justify-content: center;
  margin-right: 21.0px;
  margin-top: 190px;
  padding: 15px;
  position: relative;
  width: 156px;
}

.modeling-99 {
  cursor: pointer;
  letter-spacing: 0.20px;
  line-height: 18px;
  margin-top: -1.50px;
  position: relative;
  text-align: center;
  text-decoration: underline;
  white-space: nowrap;
  width: fit-content;
}

.flex-col-39 {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  min-height: 544px;
  width: 444px;
}

.overlap-group1-29 {
  height: 203px;
  position: relative;
  width: 444px;
}

.label-118 {
  left: 239px;
  letter-spacing: 0;
  line-height: 25.9px;
  position: absolute;
  top: 38px;
  transform: rotate(90.34deg);
  white-space: nowrap;
}

.label-119 {
  left: 363px;
  letter-spacing: 0;
  line-height: 25.9px;
  position: absolute;
  top: 137px;
  white-space: nowrap;
}

.arrow-3-6 {
  height: 22px;
  left: 0;
  position: absolute;
  top: 156px;
  width: 203px;
}

.arrow-4-6 {
  height: 100px;
  left: 262px;
  position: absolute;
  top: 29px;
  width: 22px;
}

.arrow-7-6 {
  height: 22px;
  left: 344px;
  position: absolute;
  top: 155px;
  width: 100px;
}

.overlap-group5-6 {
  align-self: center;
  height: 100px;
  margin-left: 91.04px;
  margin-top: 2px;
  position: relative;
  width: 43px;
}

.label-120 {
  left: -23px;
  letter-spacing: 0;
  line-height: 25.9px;
  position: absolute;
  top: 47px;
  transform: rotate(-89.18deg);
  white-space: nowrap;
}

.arrow-9-6 {
  height: 100px;
  left: 21px;
  position: absolute;
  top: 0;
  width: 22px;
}

.frame-2-14 {
  align-items: center;
  background-color: var(--rum-swizzle);
  border-radius: 6px;
  box-shadow: 0px 4px 11px #1e454945;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 47px;
  justify-content: center;
  margin-right: 44.56px;
  margin-top: 193px;
  padding: 15px;
  position: relative;
  width: 156px;
}

.continue-9 {
  letter-spacing: 0.20px;
  line-height: 18px;
  margin-top: -1.50px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

