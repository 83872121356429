.objectives {
  align-items: flex-start;
  background-color: var(--white);
  border: 1px none;
  display: flex;
  flex-direction: column;
  gap: 73px;
  height: 924px;
  overflow: hidden;
  width: 1440px;
}

.flex-col-34 {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  margin-left: -252px;
  min-height: 495px;
  width: 1691px;
}

.overlap-group-16 {
  align-items: flex-start;
  background-color: var(--rum-swizzle);
  border-radius: 41px 0px 100px 118px;
  display: flex;
  gap: 242px;
  height: 183px;
  justify-content: flex-end;
  min-width: 1440px;
  padding: 20px 53px;
}

.x1200px-logo_icam_-_2008-1-16 {
  cursor: pointer;
  height: 74px;
  object-fit: cover;
  width: 144px;
}

.nav-items-container-5 {
  align-items: flex-start;
  align-self: center;
  display: flex;
  flex-direction: column;
  gap: 33px;
  margin-top: 4.0px;
  min-height: 129px;
  position: relative;
  width: 929px;
}

.flex-row-50 {
  align-items: flex-start;
  align-self: flex-start;
  display: flex;
  height: 52px;
  margin-top: 20px;
  min-width: 1293px;
  position: relative;
}

.frame-6-8 {
  align-items: center;
  align-self: center;
  background-color: var(--rum-swizzle);
  border-radius: 6px;
  box-shadow: 0px 4px 11px #1e454945;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 30px;
  justify-content: center;
  margin-bottom: 8.0px;
  margin-left: 209px;
  padding: 15px;
  position: relative;
  width: 78px;
}

.place-77 {
  letter-spacing: 0.20px;
  line-height: 18px;
  margin-bottom: -8.00px;
  margin-top: -10.00px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.notes-atteindre {
  align-self: flex-end;
  height: 48px;
  letter-spacing: 0;
  line-height: normal;
  margin-left: 358px;
  text-align: center;
  width: 570px;
}

.pricing-container {
  align-items: flex-start;
  display: flex;
  height: 165px;
  margin-right: 44px;
  margin-top: 75px;
  min-width: 1357px;
  position: relative;
}

.pricing-1 {
  align-items: center;
  background-color: var(--rum-swizzle);
  border-radius: 15px;
  box-shadow: 0px 0px 36px #0000001a;
  display: flex;
  flex-direction: column;
  gap: 18px;
  margin-top: 1px;
  padding: 20px 13px;
  position: relative;
  width: fit-content;
}

.place-78 {
  height: 22.7px;
  letter-spacing: 0;
  line-height: 32px;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: 250.25px;
}

.x25-1 {
  background-color: transparent;
  border: 0;
  height: 56.28px;
  letter-spacing: 0;
  line-height: 60px;
  min-width: 171px;
  padding: 0;
  position: relative;
  white-space: nowrap;
}

.x25-1::placeholder,
.x25-2::placeholder,
.x25-3::placeholder {
  color: #3e5d6099;
}

.line-21-1 {
  height: 1px;
  min-width: 230.89px;
  object-fit: cover;
  position: relative;
}

.pricing-2 {
  align-items: flex-start;
  align-self: flex-end;
  background-color: var(--rum-swizzle);
  border-radius: 15px;
  box-shadow: 0px 0px 36px #0000001a;
  display: flex;
  flex-direction: column;
  gap: 19px;
  margin-left: 91px;
  padding: 21px 13px;
  position: relative;
  width: fit-content;
}

.lead-time,
.environment {
  height: 24.2px;
  letter-spacing: 0;
  line-height: 32px;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: 248.5px;
}

.x25-2,
.x25-3 {
  background-color: transparent;
  border: 0;
  height: 60px;
  letter-spacing: 0;
  line-height: 60px;
  min-width: 171px;
  padding: 0;
  position: relative;
  white-space: nowrap;
}

.line-21 {
  height: 1px;
  min-width: 229.27px;
  object-fit: cover;
  position: relative;
}

.flex-row-51 {
  align-items: flex-start;
  align-self: center;
  display: flex;
  height: 317px;
  margin-left: 10.0px;
  min-width: 1058px;
  position: relative;
}

.flex-col-35 {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  gap: 106px;
  margin-left: 111px;
  min-height: 317px;
  width: 274px;
}

.pricing-6 {
  align-items: flex-start;
  background-color: var(--rum-swizzle);
  border-radius: 15px;
  box-shadow: 0px 0px 36px #0000001a;
  display: flex;
  flex-direction: column;
  gap: 19px;
  padding: 21px 13px;
  position: relative;
  width: fit-content;
}

.frame-2-13 {
  align-items: center;
  background-color: var(--rum-swizzle);
  border-radius: 6px;
  box-shadow: 0px 4px 11px #1e454945;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 47px;
  justify-content: center;
  margin-right: 20.5px;
  padding: 15px;
  position: relative;
  transition: all 0.2s ease-in;
  width: 156px;
}

.frame-2-13:hover {
  transform: scale(1.1);
}

.continue-8 {
  letter-spacing: 0.20px;
  line-height: 18px;
  margin-top: -1.50px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

