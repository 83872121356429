.frame-2-11 {
  align-items: center;
  background-color: var(--rum-swizzle);
  border-radius: 6px;
  box-shadow: 0px 4px 11px #1e454945;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 47px;
  justify-content: center;
  left: 637px;
  padding: 15px;
  position: absolute;
  top: 845px;
  width: 156px;
}

.continue-6 {
  letter-spacing: 0.20px;
  line-height: 18px;
  margin-top: -1.50px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

