.ouinon-48 {
  align-items: flex-start;
  display: flex;
  gap: 20px;
  height: 36px;
  margin-left: 1.5px;
  margin-top: 6px;
  min-width: 106px;
  position: relative;
}

.ouinon-48.ouinon-49 {
  margin-left: 1.72px;
  margin-top: 32px;
}

.ouinon-48.ouinon-46,
.ouinon-48.ouinon-47 {
  align-items: unset;
  display: unset;
  gap: unset;
  margin-left: unset;
  margin-top: unset;
  min-width: 104.49px;
}

.ouinon-48.ouinon-50 {
  height: 36.15px;
  margin-left: 208.1px;
  margin-top: 18.1px;
  min-width: 105.5px;
}

.ouinon-48.ouinon-51 {
  height: 36.15px;
  margin-left: 208.1px;
  margin-top: 10.0px;
  min-width: 105.5px;
}

.ouinon-48.ouinon-52 {
  margin-left: 205.4px;
  margin-top: 18px;
  min-width: 104.49px;
}

.ouinon-48.ouinon-53 {
  margin-left: 205.4px;
  margin-top: 13px;
  min-width: 104.49px;
}

.ouinon-48.ouinon-55 {
  margin-left: 1.72px;
  margin-top: 8px;
}

.ouinon-48.ouinon-56 {
  margin-top: 8px;
}

.ouinon-48.ouinon-57 {
  height: 37px;
  margin-left: unset;
  margin-right: 0.5px;
}

.ouinon-48.ouinon-58 {
  height: 37px;
  margin-left: unset;
  margin-right: 0.5px;
  margin-top: 8px;
}

.ouinon-48.ouinon-59 {
  height: 37px;
  margin-left: unset;
  margin-right: 0.5px;
  margin-top: unset;
}

.ouinon-48.ouinon-60 {
  margin-left: unset;
  margin-right: 0.5px;
  margin-top: 20px;
}

.ouinon-48.ouinon-61 {
  margin-left: unset;
  margin-right: 0.5px;
  margin-top: 8px;
}

.ouinon-48.ouinon-62 {
  margin-left: unset;
  margin-right: 0.5px;
  margin-top: 3px;
}

.ouinon-48.ouinon-63 {
  margin-left: unset;
  margin-right: 0.5px;
  margin-top: 9px;
}

