.decisional-model-1-1 {
  align-items: flex-start;
  background-color: var(--white);
  border: 1px none;
  display: flex;
  flex-direction: column;
  gap: 53px;
  height: 924px;
  width: 1440px;
}

.overlap-group1-21 {
  align-items: flex-end;
  background-color: var(--rum-swizzle);
  border-radius: 41px 0px 100px 118px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-left: 2px;
  min-height: 193px;
  padding: 20px 18px;
  width: 1438px;
}

.flex-row-16 {
  align-items: center;
  display: flex;
  gap: 195px;
  min-width: 1350px;
}

.x1200px-logo_icam_-_2008-1-6 {
  cursor: pointer;
  height: 74px;
  object-fit: cover;
  width: 144px;
}

.nav-items-2-8 {
  align-items: center;
  cursor: pointer;
  display: flex;
  gap: 60px;
  height: 48px;
  justify-content: center;
  margin-top: 2.0px;
  position: relative;
  transition: all 0.2s ease-in;
  width: 1011px;
}

.nav-items-2-8:hover,
.nav-items-4-4:hover {
  transform: translate(0, -5px);
}

.frame-113-41 {
  align-items: center;
  border-radius: 6px;
  box-shadow: 0px 4px 4px #00000040;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  margin-left: -3.50px;
  padding: 15px;
  position: relative;
  transition: all 0.2s ease-in;
  width: fit-content;
}

.frame-113-41:hover,
.frame-114-92:hover,
.frame-12:hover {
  transform: scale(1.1);
}

.aquisition-87 {
  letter-spacing: 0.20px;
  line-height: 18px;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.nav-items-4-4 {
  align-items: center;
  align-self: center;
  cursor: pointer;
  display: flex;
  gap: 60px;
  height: 48px;
  justify-content: center;
  margin-left: 21.0px;
  position: relative;
  transition: all 0.2s ease-in;
  width: 1355px;
}

.frame-114-92 {
  align-items: center;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 33px;
  justify-content: center;
  padding: 15px;
  position: relative;
  transition: all 0.2s ease-in;
  width: fit-content;
}

.functional-view-7 {
  letter-spacing: 0.20px;
  line-height: 18px;
  margin-bottom: -6.50px;
  margin-top: -8.50px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.flex-row-17 {
  align-items: center;
  display: flex;
  gap: 13px;
  margin-left: 10px;
  min-width: 1150px;
}

.flex-col-9 {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 3.86px;
  min-height: 610px;
  width: 136px;
}

.arrow-container-3 {
  align-items: flex-start;
  align-self: flex-end;
  display: flex;
  gap: 6px;
  height: 88px;
  min-width: 121px;
}

.arrow-3-2 {
  height: 87px;
  width: 55px;
}

.arrow-4-2 {
  align-self: flex-end;
  height: 87px;
  width: 60px;
}

.ellipse-85-2 {
  background-color: var(--rum-swizzle);
  border-radius: 36px/35.5px;
  height: 71px;
  margin-right: 12.27px;
  margin-top: 39px;
  width: 72px;
}

.rectangle-3-2 {
  align-self: flex-start;
  background-color: var(--rum-swizzle);
  height: 47px;
  margin-left: 8px;
  margin-top: 40px;
  width: 99px;
}

.frame-5-7 {
  align-self: flex-start;
  background-color: var(--rum-swizzle);
  border-radius: 6px;
  box-shadow: 0px 4px 11px #1e454945;
  height: 47px;
  margin-left: 9px;
  margin-top: 44px;
  width: 99px;
}

.overlap-group-8 {
  align-self: flex-start;
  height: 15px;
  margin-top: 44px;
  position: relative;
  width: 117px;
}

.line-2-3 {
  height: 1px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 7px;
  width: 117px;
}

.ellipse-86-3 {
  background-color: var(--alto);
  border-radius: 9.5px/7.5px;
  height: 15px;
  left: 22px;
  position: absolute;
  top: 0;
  width: 19px;
}

.label-24 {
  letter-spacing: 0;
  line-height: 25.9px;
  margin-right: 30.27px;
  margin-top: 7px;
  min-height: 26px;
  min-width: 50px;
  white-space: nowrap;
}

.text-format-3 {
  height: 55px;
  margin-right: 21.27px;
  margin-top: 45px;
  object-fit: cover;
  width: 69px;
}

.label-25 {
  letter-spacing: 0;
  line-height: 25.9px;
  margin-right: 18.27px;
  margin-top: 15px;
  min-height: 26px;
  min-width: 38px;
  white-space: nowrap;
}

.flex-row-18 {
  align-items: flex-end;
  display: flex;
  gap: 176px;
  min-width: 1000px;
}

.flex-row-19 {
  align-items: flex-start;
  display: flex;
  gap: 15px;
  min-width: 104px;
}

.line-1-3 {
  align-self: flex-end;
  height: 640px;
  width: 11px;
}

.frame-11-3 {
  align-items: center;
  background-color: var(--rum-swizzle);
  border-radius: 6px;
  box-shadow: 0px 4px 11px #1e454945;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 30px;
  justify-content: center;
  padding: 15px;
  position: relative;
  width: 78px;
}

.place-69 {
  letter-spacing: 0.20px;
  line-height: 18px;
  margin-bottom: -8.00px;
  margin-top: -10.00px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.flex-col-10 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 313px;
  margin-bottom: 6.36px;
  min-height: 408px;
  width: 721px;
}

.click-on-modeling-3 {
  height: 48px;
  letter-spacing: 0;
  line-height: normal;
  margin-left: 37px;
  text-align: center;
  width: 570px;
}

.frame-container-3 {
  align-items: flex-start;
  display: flex;
  gap: 409px;
  min-width: 721px;
}

.frame-12 {
  align-items: center;
  background-color: var(--rum-swizzle);
  border-radius: 6px;
  box-shadow: 0px 4px 11px #1e454945;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 47px;
  justify-content: center;
  padding: 15px;
  position: relative;
  transition: all 0.2s ease-in;
  width: 156px;
}

.modeling-85 {
  cursor: pointer;
  letter-spacing: 0.20px;
  line-height: 18px;
  margin-top: -1.50px;
  position: relative;
  text-align: center;
  text-decoration: underline;
  white-space: nowrap;
  width: fit-content;
}

.frame-2-3 {
  align-items: center;
  background-color: var(--rum-swizzle);
  border-radius: 6px;
  box-shadow: 0px 4px 11px #1e454945;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 47px;
  justify-content: center;
  padding: 15px;
  position: relative;
  transition: all 0.2s ease;
  width: 156px;
}

.continue-2 {
  letter-spacing: 0.20px;
  line-height: 18px;
  margin-top: -1.50px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

