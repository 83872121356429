.social-4 {
  align-items: flex-start;
  background-color: var(--white);
  border: 1px none;
  display: flex;
  flex-direction: column;
  height: 900px;
  width: 1530px;
}

.overlap-group1-51 {
  align-items: flex-start;
  background-color: var(--rum-swizzle);
  border-radius: 41px 0px 100px 118px;
  display: flex;
  gap: 329px;
  height: 180px;
  justify-content: flex-end;
  min-width: 1530px;
  padding: 20px 59px;
}

.x1200px-logo_icam_-_2008-1-81 {
  cursor: pointer;
  height: 74px;
  object-fit: cover;
  width: 144px;
}

.nav-items-container-47 {
  align-items: flex-end;
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-bottom: 2px;
  min-height: 128px;
  position: relative;
  width: 926px;
}

.dialogue-social-1-2 {
  align-items: center;
  display: flex;
  gap: 8px;
  height: 458px;
  justify-content: flex-end;
  margin-top: 100px;
  min-width: 1524px;
  padding: 13.3px 0;
}

.dialogue-social-1-3 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 23.85px;
  position: relative;
  width: fit-content;
}

.group-107-2 {
  height: 368.15px;
  margin-right: -6.00px;
  min-width: 450px;
  position: relative;
}

.flex-col-102 {
  align-items: center;
  display: flex;
  flex-direction: column;
  min-height: 368px;
  position: relative;
  width: 444px;
}

.do-you-have-organisa {
  background-color: transparent;
  border: 0;
  color: var(--white-lilac);
  font-weight: 400;
  height: 106px;
  line-height: normal;
  margin-top: 14px;
  opacity: 0.5;
  padding: 0;
  text-align: center;
  width: 444px;
}

.do-you-have-organisa::placeholder,
.are-employees-involv::placeholder,
.do-you-promote-excha::placeholder {
  color: #f7f9fb99;
}

.do-you-have-employees-representatives {
  background-color: transparent;
  border: 0;
  color: var(--graye);
  font-weight: 400;
  height: 22px;
  line-height: normal;
  margin-left: 2.0px;
  margin-top: 19px;
  opacity: 0.5;
  padding: 0;
  text-align: center;
  width: 406px;
}

.do-you-have-employees-representatives::placeholder,
.does-your-company-have-any-trade-unions::placeholder,
.are-employees-integr::placeholder,
.do-you-organise-a-me::placeholder,
.do-you-have-partners::placeholder {
  color: #ffffff99;
}

.does-your-company-have-any-trade-unions {
  background-color: transparent;
  border: 0;
  color: var(--graye);
  font-weight: 400;
  height: 17px;
  line-height: normal;
  margin-top: 21px;
  opacity: 0.5;
  padding: 0;
  text-align: center;
  width: 406px;
}

.dialogue-social-container {
  align-self: flex-end;
  height: 412px;
  position: relative;
  width: 1020px;
}

.dialogue-social-2-1 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 12px;
  left: 0;
  position: absolute;
  top: 0;
  width: fit-content;
}

.are-employees-involv {
  background-color: transparent;
  border: 0;
  color: var(--white-lilac);
  font-weight: 400;
  height: 42px;
  line-height: normal;
  min-width: 516px;
  opacity: 0.5;
  padding: 0;
  position: relative;
  text-align: center;
}

.are-employees-integr {
  background-color: transparent;
  border: 0;
  color: var(--graye);
  font-weight: 400;
  height: 21px;
  line-height: normal;
  min-width: 516px;
  opacity: 0.5;
  padding: 0;
  position: relative;
  text-align: center;
}

.dialogue-social-3-1 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 17px;
  left: 497px;
  position: absolute;
  top: 0;
  width: fit-content;
}

.do-you-promote-excha {
  background-color: transparent;
  border: 0;
  color: var(--white-lilac);
  font-weight: 400;
  height: 107px;
  line-height: normal;
  min-width: 517px;
  opacity: 0.5;
  padding: 0;
  position: relative;
  text-align: center;
}

.do-you-organise-a-me {
  background-color: transparent;
  border: 0;
  color: var(--graye);
  font-weight: 400;
  height: 32px;
  line-height: normal;
  min-width: 491px;
  opacity: 0.5;
  padding: 0;
  position: relative;
  text-align: center;
}

.do-you-have-partners {
  background-color: transparent;
  border: 0;
  color: var(--graye);
  font-weight: 400;
  height: 21px;
  line-height: normal;
  min-width: 523.95px;
  opacity: 0.5;
  padding: 0;
  position: relative;
  text-align: center;
}

.flex-row-148 {
  align-items: flex-start;
  align-self: flex-end;
  display: flex;
  gap: 632px;
  height: 75px;
  margin-right: 59px;
  margin-top: 64px;
  min-width: 804px;
  position: relative;
}

