.frame-2-7,
.frame-2-8,
.frame-2-9,
.frame-2-10 {
  align-items: center;
  border-radius: 6px;
  display: flex;
  gap: 24px;
  justify-content: center;
  padding: 9px;
  position: relative;
  width: fit-content;
}

