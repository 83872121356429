.environmental-3 {
  align-items: flex-end;
  background-color: var(--white);
  border: 1px none;
  display: flex;
  flex-direction: column;
  height: 900px;
  width: 1530px;
}

.overlap-group-35 {
  align-items: flex-start;
  background-color: var(--rum-swizzle);
  border-radius: 41px 0px 100px 118px;
  display: flex;
  gap: 329px;
  height: 180px;
  justify-content: flex-end;
  min-width: 1530px;
  padding: 20px 59px;
}

.x1200px-logo_icam_-_2008-1-34 {
  cursor: pointer;
  height: 74px;
  object-fit: cover;
  width: 144px;
}

.nav-items-container-13 {
  align-items: flex-end;
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-bottom: 2px;
  min-height: 128px;
  position: relative;
  width: 926px;
}

.energie-biodiversit {
  align-self: center;
  display: flex;
  gap: 36px;
  margin-top: 152px;
  width: 1444px;
}

.energie {
  display: flex;
  flex-direction: column;
  height: 368px;
  position: relative;
  width: 753px;
}

.maitrisez-et-limitez-2 {
  background-color: transparent;
  border: 0;
  color: var(--white-lilac);
  font-weight: 400;
  height: 21px;
  line-height: normal;
  margin-left: 24px;
  margin-top: 26px;
  opacity: 0.5;
  padding: 0;
  text-align: center;
  width: 684px;
}

.maitrisez-et-limitez-2::placeholder,
.avez-vous-des-sites::placeholder {
  color: #f7f9fb99;
}

.mesurez-vous-votre-c {
  background-color: transparent;
  border: 0;
  color: var(--graye);
  font-weight: 400;
  height: 21px;
  line-height: normal;
  margin-left: 164px;
  margin-top: 21px;
  opacity: 0.5;
  padding: 0;
  width: 403px;
}

.mesurez-vous-votre-c::placeholder,
.avez-vous-pris-des-m::placeholder,
.avez-vous-des-sites-1::placeholder,
.favorisez-vous-les-a::placeholder {
  color: #ffffff99;
}

.avez-vous-pris-des-m {
  background-color: transparent;
  border: 0;
  color: var(--graye);
  font-weight: 400;
  height: 21px;
  line-height: normal;
  margin-left: 63px;
  margin-top: 21px;
  opacity: 0.5;
  padding: 0;
  width: 605px;
}

.biodiversit {
  display: flex;
  flex-direction: column;
  height: 368px;
  position: relative;
  width: 655px;
}

.avez-vous-des-sites {
  background-color: transparent;
  border: 0;
  color: var(--white-lilac);
  font-weight: 400;
  height: 21px;
  line-height: normal;
  margin-left: 86px;
  margin-top: 40px;
  opacity: 0.5;
  padding: 0;
  width: 460px;
}

.avez-vous-des-sites-1 {
  background-color: transparent;
  border: 0;
  color: var(--graye);
  font-weight: 400;
  height: 21px;
  line-height: normal;
  margin-left: 112px;
  margin-top: 22px;
  opacity: 0.5;
  padding: 0;
  width: 409px;
}

.favorisez-vous-les-a {
  background-color: transparent;
  border: 0;
  color: var(--graye);
  font-weight: 400;
  height: 21px;
  line-height: normal;
  margin-left: 25px;
  margin-top: 22px;
  opacity: 0.5;
  padding: 0;
  text-align: center;
  width: 583px;
}

.flex-row-82 {
  align-items: flex-start;
  display: flex;
  gap: 497px;
  height: 73px;
  margin-right: 118px;
  margin-top: 117px;
  min-width: 752px;
  position: relative;
}

.yes {
  float: left;
}
