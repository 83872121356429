.frame-5-11 {
  align-items: center;
  background-color: var(--rum-swizzle);
  border-radius: 6px;
  box-shadow: 0px 4px 11px #1e454945;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 30px;
  justify-content: center;
  padding: 15px;
  position: relative;
  width: 78px;
}

.place-79 {
  letter-spacing: 0.20px;
  line-height: 18px;
  margin-bottom: -8.00px;
  margin-top: -10.00px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

