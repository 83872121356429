.lean-vsm-1 {
  align-items: center;
  background-color: var(--white);
  border: 1px none;
  display: flex;
  flex-direction: column;
  gap: 53px;
  height: 924px;
  width: 1440px;
}

.overlap-group-11 {
  align-items: flex-start;
  background-color: var(--rum-swizzle);
  border-radius: 41px 0px 100px 118px;
  display: flex;
  gap: 195px;
  height: 190px;
  justify-content: flex-end;
  margin-right: 2.0px;
  min-width: 1438px;
  padding: 20px 16px;
}

.x1200px-logo_icam_-_2008-1-11 {
  cursor: pointer;
  height: 74px;
  object-fit: cover;
  width: 144px;
}

.nav-items-container-2 {
  align-items: center;
  align-self: center;
  display: flex;
  flex-direction: column;
  gap: 35px;
  margin-top: 9.0px;
  min-height: 131px;
  width: 1011px;
}

.nav-items-2-15 {
  align-items: center;
  cursor: pointer;
  display: flex;
  gap: 60px;
  height: 48px;
  justify-content: center;
  position: relative;
  transition: all 0.2s ease-in;
  width: 1011px;
}

.nav-items-2-15:hover,
.nav-items-3-63:hover {
  transform: translate(0, -5px);
}

.nav-items-3-63 {
  align-items: center;
  cursor: pointer;
  display: flex;
  gap: 60px;
  height: 48px;
  justify-content: center;
  margin-left: 5.0px;
  position: relative;
  transition: all 0.2s ease-in;
  width: 940px;
}

.frame-113-45 {
  align-items: center;
  background-color: var(--tropical-rain-forest);
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  margin-left: -2.00px;
  padding: 15px;
  position: relative;
  transition: all 0.2s ease-in;
  width: fit-content;
}

.frame-113-45:hover,
.frame-12-2:hover {
  transform: scale(1.1);
}

.lean-vsm {
  letter-spacing: 0.20px;
  line-height: 18px;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.flex-row-33 {
  align-items: center;
  display: flex;
  gap: 13px;
  margin-left: 4.0px;
  min-width: 1412px;
}

.flex-col-18 {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 7.86px;
  min-height: 606px;
  width: 136px;
}

.arrow-container-6 {
  align-items: flex-start;
  align-self: flex-end;
  display: flex;
  gap: 6px;
  height: 88px;
  min-width: 121px;
}

.arrow-1-5 {
  height: 87px;
  width: 55px;
}

.arrow-2-5 {
  align-self: flex-end;
  height: 87px;
  width: 60px;
}

.ellipse-88 {
  background-color: var(--rum-swizzle);
  border-radius: 36px/35.5px;
  height: 71px;
  margin-right: 16.27px;
  margin-top: 42px;
  width: 72px;
}

.rectangle-3-3 {
  align-self: flex-start;
  background-color: var(--rum-swizzle);
  height: 47px;
  margin-left: 8px;
  margin-top: 37px;
  width: 99px;
}

.rectangle-6-1 {
  align-self: flex-start;
  height: 42px;
  margin-left: 6px;
  margin-top: 44px;
  width: 112px;
}

.overlap-group3-6 {
  align-self: flex-start;
  height: 15px;
  margin-top: 49px;
  position: relative;
  width: 117px;
}

.line-2-6 {
  height: 1px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 7px;
  width: 117px;
}

.ellipse-86-6 {
  background-color: var(--alto);
  border-radius: 9.5px/7.5px;
  height: 15px;
  left: 22px;
  position: absolute;
  top: 0;
  width: 19px;
}

.label-37 {
  letter-spacing: 0;
  line-height: 25.9px;
  margin-right: 30.27px;
  margin-top: 7px;
  min-height: 26px;
  min-width: 50px;
  white-space: nowrap;
}

.text-format-6 {
  height: 55px;
  margin-right: 15.27px;
  margin-top: 33px;
  object-fit: cover;
  width: 69px;
}

.label-94 {
  letter-spacing: 0;
  line-height: 25.9px;
  margin-right: 12.27px;
  margin-top: 23px;
  min-height: 26px;
  min-width: 38px;
  white-space: nowrap;
}

.flex-row-34 {
  align-items: flex-start;
  display: flex;
  gap: 12px;
  min-width: 1262px;
}

.line-1-6 {
  align-self: flex-end;
  height: 640px;
  width: 11px;
}

.flex-col-19 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 99px;
  min-height: 631px;
  width: 1240px;
}

.flex-row-35 {
  align-items: flex-start;
  display: flex;
  gap: 252px;
  height: 53px;
  margin-left: 3px;
  min-width: 900px;
}

.frame-11-6 {
  align-items: center;
  background-color: var(--rum-swizzle);
  border-radius: 6px;
  box-shadow: 0px 4px 11px #1e454945;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 30px;
  justify-content: center;
  padding: 15px;
  position: relative;
  width: 78px;
}

.place-72 {
  letter-spacing: 0.20px;
  line-height: 18px;
  margin-bottom: -8.00px;
  margin-top: -10.00px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.click-on-modeling-5 {
  align-self: flex-end;
  height: 48px;
  letter-spacing: 0;
  line-height: normal;
  text-align: center;
  width: 570px;
}

.flex-row-36 {
  align-items: flex-start;
  display: flex;
  height: 479px;
  min-width: 1240px;
}

.overlap-group5-3 {
  align-items: flex-start;
  background-color: var(--rum-swizzle);
  border-radius: 92px/89px;
  display: flex;
  height: 178px;
  justify-content: flex-end;
  min-width: 184px;
  padding: 50px 30px;
  position: relative;
}

.arrow-3-3 {
  height: 22px;
  margin-left: 3px;
  margin-top: 77.1px;
  width: 74px;
}

.flex-col-20 {
  align-items: flex-end;
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  gap: 284px;
  margin-left: 1px;
  min-height: 449px;
  width: 194px;
}

.overlap-group4-5 {
  align-items: flex-start;
  background-color: var(--rum-swizzle);
  display: flex;
  height: 118px;
  justify-content: flex-end;
  min-width: 194px;
  padding: 45px 36px;
  position: relative;
}

.frame-12-2 {
  align-items: center;
  background-color: var(--rum-swizzle);
  border-radius: 6px;
  box-shadow: 0px 4px 11px #1e454945;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 47px;
  justify-content: center;
  padding: 15px;
  position: relative;
  transition: all 0.2s ease-in;
  width: 156px;
}

.modeling-87 {
  cursor: pointer;
  letter-spacing: 0.20px;
  line-height: 18px;
  margin-top: -1.50px;
  position: relative;
  text-align: center;
  text-decoration: underline;
  white-space: nowrap;
  width: fit-content;
}

.arrow-4-3 {
  height: 22px;
  margin-top: 77.95px;
  width: 66px;
}

.overlap-group2-6 {
  align-items: flex-end;
  background-color: var(--rum-swizzle);
  border-radius: 90.5px/86px;
  display: flex;
  height: 172px;
  margin-left: 2px;
  min-width: 181px;
  padding: 71.0px 48px;
  position: relative;
}

.arrow-6-5 {
  height: 22px;
  margin-top: 74.95px;
  width: 66px;
}

.flex-col-21 {
  align-items: flex-start;
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  gap: 286px;
  margin-left: 2px;
  min-height: 452px;
  width: 467px;
}

.overlap-group1-24 {
  height: 119px;
  position: relative;
  width: 467px;
}

.rectangle-5-1 {
  background-color: var(--rum-swizzle);
  height: 115px;
  left: 0;
  position: absolute;
  top: 3px;
  width: 193px;
}

.arrow-5-4 {
  height: 22px;
  left: 193px;
  position: absolute;
  top: 51px;
  width: 91px;
}

.rectangle-7-1 {
  height: 119px;
  left: 282px;
  position: absolute;
  top: 0;
  width: 185px;
}

.frame-2-5 {
  align-items: center;
  background-color: var(--rum-swizzle);
  border-radius: 6px;
  box-shadow: 0px 4px 11px #1e454945;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 47px;
  justify-content: center;
  margin-left: 31px;
  padding: 15px;
  position: relative;
  width: 156px;
}

.continue-4 {
  letter-spacing: 0.20px;
  line-height: 18px;
  margin-top: -1.50px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

