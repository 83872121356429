.frame-117-1,
.frame-117-2,
.frame-117-3,
.frame-117-4,
.frame-117-5,
.frame-117-6,
.frame-117-7,
.frame-117-8,
.frame-117-9,
.frame-117-10,
.frame-117-11,
.frame-117-12,
.frame-117-13,
.frame-117-14,
.frame-117-15,
.frame-117-16,
.frame-117-17,
.frame-117-18,
.frame-117-19,
.frame-117-20,
.frame-117-21,
.frame-117-22,
.frame-117-23,
.frame-117-24,
.frame-117-25,
.frame-117-26,
.frame-117-27,
.frame-117-28,
.frame-117-29,
.frame-117-30,
.frame-117-31,
.frame-117-32,
.frame-117-33,
.frame-117-34,
.frame-117-35,
.frame-117-36,
.frame-117-37,
.frame-117-38,
.frame-117-39,
.frame-117-40,
.frame-117-41,
.frame-117-42,
.frame-117-43,
.frame-117-44,
.frame-117-45,
.frame-117-46,
.frame-117-47,
.frame-117-48,
.frame-117-49,
.frame-117-50,
.frame-117-51,
.frame-117-52,
.frame-117-53,
.frame-117-54,
.frame-117-55,
.frame-117-56,
.frame-117-57,
.frame-117-58,
.frame-117-59,
.frame-117-60,
.frame-117-61,
.frame-117-62,
.frame-117-63,
.frame-117-64,
.frame-117-65,
.frame-117-66,
.frame-117-67,
.frame-117-68,
.frame-117-69,
.frame-117-70 {
  align-items: center;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  padding: 15px;
  position: relative;
  transition: all 0.2s ease-in;
  width: fit-content;
}

.frame-117-1:hover,
.frame-117-2:hover,
.frame-117-3:hover,
.frame-117-4:hover,
.frame-117-5:hover,
.frame-117-6:hover,
.frame-117-7:hover,
.frame-117-8:hover,
.frame-117-9:hover,
.frame-117-10:hover,
.frame-117-11:hover,
.frame-117-12:hover,
.frame-117-13:hover,
.frame-117-14:hover,
.frame-117-15:hover,
.frame-117-16:hover,
.frame-117-17:hover,
.frame-117-18:hover,
.frame-117-19:hover,
.frame-117-20:hover,
.frame-117-21:hover,
.frame-117-22:hover,
.frame-117-23:hover,
.frame-117-24:hover,
.frame-117-25:hover,
.frame-117-26:hover,
.frame-117-27:hover,
.frame-117-28:hover,
.frame-117-29:hover,
.frame-117-30:hover,
.frame-117-31:hover,
.frame-117-32:hover,
.frame-117-33:hover,
.frame-117-34:hover,
.frame-117-35:hover,
.frame-117-36:hover,
.frame-117-37:hover,
.frame-117-38:hover,
.frame-117-39:hover,
.frame-117-40:hover,
.frame-117-41:hover,
.frame-117-42:hover,
.frame-117-43:hover,
.frame-117-44:hover,
.frame-117-45:hover,
.frame-117-46:hover,
.frame-117-47:hover,
.frame-117-48:hover,
.frame-117-49:hover,
.frame-117-50:hover,
.frame-117-51:hover,
.frame-117-52:hover,
.frame-117-53:hover,
.frame-117-54:hover,
.frame-117-55:hover,
.frame-117-56:hover,
.frame-117-57:hover,
.frame-117-58:hover,
.frame-117-59:hover,
.frame-117-60:hover,
.frame-117-61:hover,
.frame-117-62:hover,
.frame-117-63:hover,
.frame-117-64:hover,
.frame-117-65:hover,
.frame-117-66:hover,
.frame-117-67:hover,
.frame-117-68:hover,
.frame-117-69:hover,
.frame-117-70:hover {
  transform: scale(1.1);
}

.process-view,
.existing-case,
.process-view-1,
.existing-case-1,
.process-view-2,
.process-view-3,
.process-view-4,
.existing-case-2,
.existing-case-3,
.existing-case-4,
.process-view-5,
.existing-case-5,
.process-view-6,
.process-view-7,
.existing-case-6,
.existing-case-7,
.existing-case-8,
.existing-case-9,
.existing-case-10,
.existing-case-11,
.existing-case-12,
.existing-case-13,
.existing-case-14,
.existing-case-15,
.existing-case-16,
.existing-case-17,
.existing-case-18,
.existing-case-19,
.existing-case-20,
.existing-case-21,
.existing-case-22,
.existing-case-23,
.existing-case-24,
.existing-case-25,
.existing-case-26,
.existing-case-27,
.existing-case-28,
.existing-case-29,
.existing-case-30,
.existing-case-31,
.existing-case-32,
.existing-case-33,
.existing-case-34,
.existing-case-35,
.existing-case-36,
.existing-case-37,
.existing-case-38,
.existing-case-39,
.existing-case-40,
.existing-case-41,
.existing-case-42,
.existing-case-43,
.existing-case-44,
.existing-case-45,
.existing-case-46,
.existing-case-47,
.existing-case-48,
.existing-case-49,
.existing-case-50,
.existing-case-51,
.existing-case-52,
.existing-case-53,
.existing-case-54,
.existing-case-55,
.existing-case-56,
.existing-case-57,
.existing-case-58,
.existing-case-59,
.existing-case-60,
.existing-case-61 {
  letter-spacing: 0.20px;
  line-height: 18px;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

