.visual-management-1-1 {
  align-items: flex-start;
  background-color: var(--white);
  border: 1px none;
  display: flex;
  flex-direction: column;
  gap: 38px;
  height: 924px;
  width: 1440px;
}

.overlap-group2-8 {
  align-items: flex-start;
  background-color: var(--rum-swizzle);
  border-radius: 41px 0px 100px 118px;
  display: flex;
  gap: 195px;
  height: 190px;
  justify-content: flex-end;
  min-width: 1440px;
  padding: 20px 18px;
}

.x1200px-logo_icam_-_2008-1-14 {
  cursor: pointer;
  height: 74px;
  object-fit: cover;
  width: 144px;
}

.nav-items-container-4 {
  align-items: center;
  align-self: center;
  display: flex;
  flex-direction: column;
  gap: 35px;
  margin-top: 9.0px;
  min-height: 131px;
  width: 1011px;
}

.nav-items-2-18 {
  align-items: center;
  cursor: pointer;
  display: flex;
  gap: 60px;
  height: 48px;
  justify-content: center;
  position: relative;
  transition: all 0.2s ease-in;
  width: 1011px;
}

.nav-items-2-18:hover,
.nav-items-3-65:hover {
  transform: translate(0, -5px);
}

.nav-items-3-65 {
  align-items: center;
  cursor: pointer;
  display: flex;
  gap: 60px;
  height: 48px;
  justify-content: center;
  margin-left: 5.0px;
  position: relative;
  transition: all 0.2s ease-in;
  width: 940px;
}

.flex-row-42 {
  align-items: center;
  display: flex;
  height: 664px;
  margin-left: 11px;
  min-width: 1347px;
}

.flex-col-28 {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 11.5px;
  min-height: 606px;
  width: 136px;
}

.arrow-container-9 {
  align-items: flex-start;
  align-self: flex-end;
  display: flex;
  gap: 6px;
  height: 88px;
  min-width: 121px;
}

.arrow-9-4 {
  height: 87px;
  width: 55px;
}

.arrow-10-1 {
  align-self: flex-end;
  height: 87px;
  width: 60px;
}

.ellipse-85-4 {
  background-color: var(--rum-swizzle);
  border-radius: 36px/35.5px;
  height: 71px;
  margin-right: 12.27px;
  margin-top: 39px;
  width: 72px;
}

.rectangle-9 {
  align-self: flex-start;
  background-color: var(--rum-swizzle);
  height: 47px;
  margin-left: 8px;
  margin-top: 40px;
  width: 99px;
}

.frame-5-10 {
  align-self: flex-start;
  background-color: var(--rum-swizzle);
  border-radius: 6px;
  box-shadow: 0px 4px 11px #1e454945;
  height: 47px;
  margin-left: 9px;
  margin-top: 44px;
  width: 99px;
}

.overlap-group1-26 {
  align-self: flex-start;
  height: 15px;
  margin-top: 44px;
  position: relative;
  width: 117px;
}

.line-2-8 {
  height: 1px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 7px;
  width: 117px;
}

.ellipse-86-8 {
  background-color: var(--alto);
  border-radius: 9.5px/7.5px;
  height: 15px;
  left: 22px;
  position: absolute;
  top: 0;
  width: 19px;
}

.label-101 {
  letter-spacing: 0;
  line-height: 25.9px;
  margin-right: 30.27px;
  margin-top: 7px;
  min-height: 26px;
  min-width: 50px;
  white-space: nowrap;
}

.text-format-8 {
  height: 55px;
  margin-right: 15.27px;
  margin-top: 33px;
  object-fit: cover;
  width: 69px;
}

.label-102 {
  letter-spacing: 0;
  line-height: 25.9px;
  margin-right: 12.27px;
  margin-top: 23px;
  min-height: 26px;
  min-width: 38px;
  white-space: nowrap;
}

.line-1-8 {
  height: 640px;
  margin-bottom: 3.63px;
  margin-left: 13px;
  width: 11px;
}

.flex-col-29 {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  margin-left: 15px;
  min-height: 664px;
  width: 1172px;
}

.flex-row-43 {
  align-items: center;
  align-self: flex-start;
  display: flex;
  gap: 243px;
  min-width: 891px;
}

.frame-11-8 {
  align-items: center;
  background-color: var(--rum-swizzle);
  border-radius: 6px;
  box-shadow: 0px 4px 11px #1e454945;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 30px;
  justify-content: center;
  margin-top: 2.0px;
  padding: 15px;
  position: relative;
  width: 78px;
}

.place-75 {
  letter-spacing: 0.20px;
  line-height: 18px;
  margin-bottom: -8.00px;
  margin-top: -10.00px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.click-on-modeling-7 {
  height: 48px;
  letter-spacing: 0;
  line-height: normal;
  text-align: center;
  width: 570px;
}

.overlap-group3-8 {
  height: 468px;
  margin-top: 51px;
  position: relative;
  width: 1061px;
}

.overlap-group1-27 {
  height: 468px;
  left: 0;
  position: absolute;
  top: 0;
  width: 950px;
}

.frame-6-5 {
  align-items: center;
  background-color: var(--rum-swizzle);
  border-radius: 6px;
  box-shadow: 0px 4px 11px #1e454945;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 75px;
  justify-content: center;
  left: 93px;
  padding: 15px;
  position: absolute;
  top: 0;
  width: 264px;
}

.knowledge-skills {
  background-color: transparent;
  border: 0;
  height: 52px;
  letter-spacing: 0;
  line-height: 25.9px;
  margin-bottom: -2.50px;
  margin-top: -4.50px;
  min-width: 97px;
  padding: 0;
  position: relative;
}

.knowledge-skills::placeholder,
.collaborators-teams::placeholder,
.strategies-projects::placeholder,
.ideas-information::placeholder,
.time-priorities::placeholder,
.better-management::placeholder,
.problems-decision::placeholder {
  color: #0c411199;
}

.frame-7-2 {
  align-items: center;
  background-color: var(--rum-swizzle);
  border-radius: 6px;
  box-shadow: 0px 4px 11px #1e454945;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 75px;
  justify-content: center;
  left: 0;
  padding: 28px 15px 15px;
  position: absolute;
  top: 189px;
  width: 264px;
}

.collaborators-teams {
  background-color: transparent;
  border: 0;
  height: 52px;
  letter-spacing: 0;
  line-height: 25.9px;
  margin-bottom: -9.00px;
  margin-top: -11.00px;
  min-width: 118px;
  padding: 0;
  position: relative;
}

.frame-10-2 {
  align-items: center;
  background-color: var(--rum-swizzle);
  border-radius: 6px;
  box-shadow: 0px 4px 11px #1e454945;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 75px;
  justify-content: center;
  left: 693px;
  padding: 15px;
  position: absolute;
  top: 393px;
  width: 257px;
}

.strategies-projects {
  background-color: transparent;
  border: 0;
  height: 52px;
  letter-spacing: 0;
  line-height: 25.9px;
  margin-bottom: -2.50px;
  margin-top: -4.50px;
  min-width: 89px;
  padding: 0;
  position: relative;
}

.frame-8-2 {
  align-items: center;
  background-color: var(--rum-swizzle);
  border-radius: 6px;
  box-shadow: 0px 4px 11px #1e454945;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 75px;
  justify-content: center;
  left: 686px;
  padding: 15px;
  position: absolute;
  top: 2px;
  width: 257px;
}

.ideas-information {
  background-color: transparent;
  border: 0;
  height: 52px;
  letter-spacing: 0;
  line-height: 25.9px;
  margin-bottom: -2.50px;
  margin-top: -4.50px;
  min-width: 100px;
  padding: 0;
  position: relative;
}

.overlap-group-14 {
  height: 392px;
  left: 97px;
  position: absolute;
  top: 69px;
  width: 602px;
}

.frame-6-6 {
  align-items: center;
  background-color: var(--rum-swizzle);
  border-radius: 6px;
  box-shadow: 0px 4px 11px #1e454945;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 75px;
  justify-content: center;
  left: 0;
  padding: 15px;
  position: absolute;
  top: 317px;
  width: 257px;
}

.time-priorities {
  background-color: transparent;
  border: 0;
  height: 52px;
  letter-spacing: 0;
  line-height: 25.9px;
  margin-bottom: -2.50px;
  margin-top: -4.50px;
  min-width: 78px;
  padding: 0;
  position: relative;
}

.arrow-11-1 {
  height: 84px;
  left: 255px;
  position: absolute;
  top: 234px;
  width: 88px;
}

.arrow-16 {
  height: 92px;
  left: 512px;
  position: absolute;
  top: 226px;
  width: 89px;
}

.arrow-13 {
  height: 82px;
  left: 261px;
  position: absolute;
  top: 4px;
  width: 82px;
}

.arrow-14 {
  height: 77px;
  left: 502px;
  position: absolute;
  top: 0;
  width: 85px;
}

.ellipse-87-1 {
  background-color: var(--rum-swizzle);
  border-radius: 112px/106px;
  height: 212px;
  left: 315px;
  position: absolute;
  top: 52px;
  width: 224px;
}

.better-management {
  background-color: transparent;
  border: 0;
  height: 52px;
  left: 364px;
  letter-spacing: 0;
  line-height: 25.9px;
  padding: 0;
  position: absolute;
  top: 131px;
  width: 143px;
}

.arrow-12 {
  height: 22px;
  left: 272px;
  position: absolute;
  top: 216px;
  width: 132px;
}

.arrow-15 {
  height: 22px;
  left: 641px;
  position: absolute;
  top: 216px;
  width: 160px;
}

.frame-9-1 {
  align-items: center;
  background-color: var(--rum-swizzle);
  border-radius: 6px;
  box-shadow: 0px 4px 11px #1e454945;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 75px;
  justify-content: center;
  left: 804px;
  padding: 15px;
  position: absolute;
  top: 189px;
  width: 257px;
}

.problems-decision {
  background-color: transparent;
  border: 0;
  height: 52px;
  letter-spacing: 0;
  line-height: 25.9px;
  margin-bottom: -2.50px;
  margin-top: -4.50px;
  min-width: 82px;
  padding: 0;
  position: relative;
}

.flex-row-44 {
  align-items: center;
  display: flex;
  gap: 305px;
  margin-right: 67px;
  margin-top: 50px;
  min-width: 628px;
  position: relative;
}

.frame-12-4 {
  align-items: center;
  background-color: var(--rum-swizzle);
  border-radius: 6px;
  box-shadow: 0px 4px 11px #1e454945;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 47px;
  justify-content: center;
  padding: 15px;
  position: relative;
  width: 156px;
}

.modeling-97 {
  cursor: pointer;
  letter-spacing: 0.20px;
  line-height: 18px;
  margin-top: -1.50px;
  position: relative;
  text-align: center;
  text-decoration: underline;
  white-space: nowrap;
  width: fit-content;
}

