.nav-items-2-20 {
  align-items: center;
  cursor: pointer;
  display: flex;
  gap: 60px;
  height: 48px;
  justify-content: center;
  position: relative;
  transition: all 0.2s ease-in;
  width: 923px;
}

.nav-items-2-20:hover {
  transform: translate(0, -5px);
}

.frame-116-175 {
  align-items: center;
  background-color: var(--tropical-rain-forest);
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  padding: 15px;
  position: relative;
  transition: all 0.2s ease-in;
  width: fit-content;
}

.frame-116-175:hover {
  transform: scale(1.1);
}

.result-objectives-84 {
  letter-spacing: 0.20px;
  line-height: 18px;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.nav-items-2-20.nav-items-2-21 {
  left: 461px;
  position: absolute;
  top: 110px;
}

