.nav-items-4-1 {
  align-items: center;
  align-self: center;
  cursor: pointer;
  display: flex;
  gap: 60px;
  height: 48px;
  justify-content: center;
  margin-left: 23.0px;
  position: relative;
  transition: all 0.2s ease-in;
  width: 1355px;
}

.nav-items-4-1:hover {
  transform: translate(0, -5px);
}

.frame-114-88 {
  align-items: center;
  background-color: var(--tropical-rain-forest);
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 33px;
  justify-content: center;
  padding: 15px;
  position: relative;
  transition: all 0.2s ease-in;
  width: fit-content;
}

.frame-114-88:hover {
  transform: scale(1.1);
}

.functional-view-4 {
  letter-spacing: 0.20px;
  line-height: 18px;
  margin-bottom: -6.50px;
  margin-top: -8.50px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.nav-items-4-1.nav-items-4-2 {
  align-self: unset;
  margin-left: unset;
  margin-right: 13px;
}

