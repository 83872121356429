.frame-118-2 {
  align-items: center;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  padding: 15px;
  position: relative;
  transition: all 0.2s ease-in;
  width: fit-content;
}

.frame-118-2:hover {
  transform: scale(1.1);
}

.informational-model-3 {
  letter-spacing: 0.20px;
  line-height: 18px;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.frame-118-2.frame-113-23,
.frame-118-2.frame-113-26,
.frame-118-2.frame-113-27,
.frame-118-2.frame-113-30,
.frame-118-2.frame-113-31,
.frame-118-2.frame-113-32,
.frame-118-2.frame-113-33,
.frame-118-2.frame-113-35,
.frame-118-2.frame-113-37,
.frame-118-2.frame-113-38,
.frame-118-2.frame-113-39 {
  margin-right: -2.00px;
}

