.frame-115,
.frame-115-1,
.frame-115-2,
.frame-115-3,
.frame-115-4,
.frame-115-5,
.frame-115-6,
.frame-115-7,
.frame-115-8,
.frame-115-9,
.frame-115-10,
.frame-115-11,
.frame-115-12,
.frame-115-13,
.frame-115-14,
.frame-115-15,
.frame-115-16,
.frame-115-17,
.frame-115-18,
.frame-115-19,
.frame-115-20,
.frame-115-21,
.frame-115-22,
.frame-115-23,
.frame-115-24,
.frame-115-25,
.frame-115-26,
.frame-115-27,
.frame-115-28,
.frame-115-29,
.frame-115-30,
.frame-115-31,
.frame-115-32,
.frame-115-33,
.frame-115-34,
.frame-115-35,
.frame-115-36,
.frame-115-37,
.frame-115-38,
.frame-115-39,
.frame-115-40,
.frame-115-41,
.frame-115-42,
.frame-115-43,
.frame-115-44,
.frame-115-45,
.frame-115-46,
.frame-115-47,
.frame-115-48,
.frame-115-49,
.frame-115-50,
.frame-115-51,
.frame-115-52,
.frame-115-53,
.frame-115-54,
.frame-115-55,
.frame-115-56,
.frame-115-57,
.frame-115-58,
.frame-115-59,
.frame-115-60,
.frame-115-61,
.frame-115-62,
.frame-115-63,
.frame-115-64,
.frame-115-65,
.frame-115-66,
.frame-115-67,
.frame-115-68,
.frame-115-69,
.frame-115-70,
.frame-115-71,
.frame-115-72,
.frame-115-73,
.frame-115-74,
.frame-115-75,
.frame-115-76,
.frame-115-77,
.frame-115-78,
.frame-115-79,
.frame-115-80,
.frame-115-81,
.frame-115-82,
.frame-115-83,
.frame-115-84,
.frame-115-85,
.frame-115-86,
.frame-115-87 {
  align-items: center;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  padding: 15px;
  position: relative;
  transition: all 0.2s ease-in;
  width: fit-content;
}

.frame-115:hover,
.frame-115-1:hover,
.frame-115-2:hover,
.frame-115-3:hover,
.frame-115-4:hover,
.frame-115-5:hover,
.frame-115-6:hover,
.frame-115-7:hover,
.frame-115-8:hover,
.frame-115-9:hover,
.frame-115-10:hover,
.frame-115-11:hover,
.frame-115-12:hover,
.frame-115-13:hover,
.frame-115-14:hover,
.frame-115-15:hover,
.frame-115-16:hover,
.frame-115-17:hover,
.frame-115-18:hover,
.frame-115-19:hover,
.frame-115-20:hover,
.frame-115-21:hover,
.frame-115-22:hover,
.frame-115-23:hover,
.frame-115-24:hover,
.frame-115-25:hover,
.frame-115-26:hover,
.frame-115-27:hover,
.frame-115-28:hover,
.frame-115-29:hover,
.frame-115-30:hover,
.frame-115-31:hover,
.frame-115-32:hover,
.frame-115-33:hover,
.frame-115-34:hover,
.frame-115-35:hover,
.frame-115-36:hover,
.frame-115-37:hover,
.frame-115-38:hover,
.frame-115-39:hover,
.frame-115-40:hover,
.frame-115-41:hover,
.frame-115-42:hover,
.frame-115-43:hover,
.frame-115-44:hover,
.frame-115-45:hover,
.frame-115-46:hover,
.frame-115-47:hover,
.frame-115-48:hover,
.frame-115-49:hover,
.frame-115-50:hover,
.frame-115-51:hover,
.frame-115-52:hover,
.frame-115-53:hover,
.frame-115-54:hover,
.frame-115-55:hover,
.frame-115-56:hover,
.frame-115-57:hover,
.frame-115-58:hover,
.frame-115-59:hover,
.frame-115-60:hover,
.frame-115-61:hover,
.frame-115-62:hover,
.frame-115-63:hover,
.frame-115-64:hover,
.frame-115-65:hover,
.frame-115-66:hover,
.frame-115-67:hover,
.frame-115-68:hover,
.frame-115-69:hover,
.frame-115-70:hover,
.frame-115-71:hover,
.frame-115-72:hover,
.frame-115-73:hover,
.frame-115-74:hover,
.frame-115-75:hover,
.frame-115-76:hover,
.frame-115-77:hover,
.frame-115-78:hover,
.frame-115-79:hover,
.frame-115-80:hover,
.frame-115-81:hover,
.frame-115-82:hover,
.frame-115-83:hover,
.frame-115-84:hover,
.frame-115-85:hover,
.frame-115-86:hover,
.frame-115-87:hover {
  transform: scale(1.1);
}

.analysis,
.analysis-1,
.analysis-2,
.analysis-3,
.analysis-4,
.analysis-5,
.analysis-6,
.analysis-7,
.analysis-8,
.analysis-9,
.analysis-10,
.analysis-11,
.analysis-12,
.analysis-13,
.analysis-14,
.analysis-15,
.analysis-16,
.analysis-17,
.analysis-18,
.analysis-19,
.analysis-20,
.analysis-21,
.analysis-22,
.analysis-23,
.analysis-24,
.analysis-25,
.analysis-26,
.analysis-27,
.analysis-28,
.analysis-29,
.analysis-30,
.analysis-31,
.analysis-32,
.analysis-33,
.analysis-34,
.analysis-35,
.analysis-36,
.analysis-37,
.analysis-38,
.analysis-39,
.analysis-40,
.analysis-41,
.analysis-42,
.analysis-43,
.analysis-44,
.analysis-45,
.analysis-46,
.analysis-47,
.analysis-48,
.analysis-49,
.analysis-50,
.analysis-51,
.analysis-52,
.analysis-53,
.analysis-54,
.analysis-55,
.analysis-56,
.analysis-57,
.analysis-58,
.analysis-59,
.analysis-60,
.analysis-61,
.analysis-62,
.analysis-63,
.analysis-64,
.analysis-65,
.analysis-66,
.analysis-67,
.analysis-68,
.analysis-69,
.analysis-70,
.analysis-71,
.analysis-72,
.analysis-73,
.analysis-74,
.analysis-75,
.analysis-76,
.analysis-77,
.analysis-78,
.analysis-79,
.analysis-80,
.analysis-81,
.analysis-82,
.analysis-83,
.analysis-84,
.analysis-85,
.analysis-86,
.analysis-87 {
  letter-spacing: 0.20px;
  line-height: 18px;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

