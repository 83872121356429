.frame-144,
.frame-144-1,
.frame-144-2,
.frame-144-3,
.frame-144-4,
.frame-144-5,
.frame-144-6,
.frame-144-7,
.frame-144-8 {
  display: flex;
  height: 36px;
  min-width: 146px;
  position: relative;
}

