.client-consommateur-2 {
  align-items: flex-end;
  background-color: var(--white);
  border: 1px none;
  display: flex;
  flex-direction: column;
  height: 900px;
  width: 1530px;
}

.overlap-group-26 {
  align-items: flex-start;
  background-color: var(--rum-swizzle);
  border-radius: 41px 0px 100px 118px;
  display: flex;
  gap: 329px;
  height: 180px;
  justify-content: flex-end;
  min-width: 1530px;
  padding: 20px 59px;
}

.x1200px-logo_icam_-_2008-1-26 {
  cursor: pointer;
  height: 74px;
  object-fit: cover;
  width: 144px;
}

.nav-items-container-7 {
  align-items: flex-end;
  align-self: center;
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-top: 6.0px;
  min-height: 128px;
  position: relative;
  width: 926px;
}

.emploirelation-employeur-employ {
  align-items: center;
  align-self: center;
  display: flex;
  gap: 88px;
  margin-left: 26.0px;
  margin-top: 126px;
  padding: 0px 0px 0px 11px;
  position: relative;
  width: fit-content;
}

.emploi-relation-employeur-employ {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 14px;
  position: relative;
  width: fit-content;
}

.is-your-complaint-rate-above-75 {
  background-color: transparent;
  border: 0;
  color: var(--white-lilac);
  font-weight: 400;
  height: 35px;
  line-height: normal;
  min-width: 420px;
  opacity: 0.5;
  padding: 0;
  position: relative;
  text-align: center;
}

.is-your-complaint-rate-above-75::placeholder,
.is-your-complaint-re::placeholder {
  color: #f7f9fb99;
}

.is-your-complaint-rate-between-50-and-75 {
  background-color: transparent;
  border: 0;
  color: var(--graye);
  font-weight: 400;
  height: 32px;
  line-height: normal;
  min-width: 419px;
  opacity: 0.5;
  padding: 0;
  position: relative;
  text-align: center;
}

.is-your-complaint-rate-between-50-and-75::placeholder,
.is-your-complaint-rate-be::placeholder,
.is-your-claim-respon::placeholder,
.is-your-c::placeholder {
  color: #ffffff99;
}

.is-your-complaint-rate-be {
  background-color: transparent;
  border: 0;
  color: var(--graye);
  font-weight: 400;
  height: 24px;
  line-height: normal;
  min-width: 402px;
  opacity: 0.5;
  padding: 0;
  position: relative;
  text-align: center;
}

.is-your-complaint-re {
  background-color: transparent;
  border: 0;
  color: var(--white-lilac);
  font-weight: 400;
  height: 38px;
  line-height: normal;
  min-width: 323px;
  opacity: 0.5;
  padding: 0;
  position: relative;
  text-align: center;
}

.is-your-claim-respon {
  background-color: transparent;
  border: 0;
  color: var(--graye);
  font-weight: 400;
  height: 36px;
  line-height: normal;
  min-width: 351px;
  opacity: 0.5;
  padding: 0;
  position: relative;
  text-align: center;
}

.is-your-c {
  background-color: transparent;
  border: 0;
  color: var(--graye);
  font-weight: 400;
  height: 41px;
  line-height: normal;
  min-width: 331px;
  opacity: 0.5;
  padding: 0;
  position: relative;
  text-align: center;
}

.flex-row-73 {
  align-items: flex-start;
  display: flex;
  gap: 559px;
  height: 89px;
  margin-right: 107px;
  margin-top: 41px;
  min-width: 766px;
  position: relative;
}

.frame-112-2 {
  align-items: center;
  background-color: var(--rum-swizzle);
  border-radius: 40px;
  box-shadow: 0px 4px 11px #1e454945;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  padding: 15px;
  position: relative;
  transition: all 0.2s ease-in;
  width: fit-content;
}

.frame-112-2:hover {
  transform: scale(1.1);
}

.next-34 {
  letter-spacing: 0.20px;
  line-height: 18px;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

